import { Link } from "react-router-dom";
import { useTitle } from "../../../components";

export const FameCelebrityVocabulary = ({ title }) => {
  useTitle(title);

  return (
    <>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link to="/Ielts" className="duration-300 hover:text-indigo-950">
          <span>IELTS</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link
          to="/ielts/topicalVocabulary"
          className="duration-300 hover:text-indigo-950"
        >
          <span>Topical Vocab</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">Fame & Celebrity</span>
      </nav>

      {/* ---------- Body ---------- */}
      <div
        className="my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <h1 className="flex flex-col drop-shadow text-center mt-10 mb-14">
          <span className="text-pink-700 mb-5 text-3xl sm:text-4xl">
            IELTS Topical Vocabulary
          </span>

          <span className="font-bold text-4xl sm:text-5xl">Fame & Celebrity</span>
        </h1>

        <p className="mb-10 leading-normal">
          The expressions printed in <span className="font-bold">bold</span>{" "}
          within the paragraphs below can enrich your vocabulary range when
          you're writing or speaking about topics related to fame and celebrity.
        </p>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            Being a celebrity is often considered{" "}
            <span className="font-bold">a double-edged sword</span>. While
            <span className="font-bold"> the VIP treatment</span>,
            opportunities, and{" "}
            <span className="font-bold">public adoration</span> can be
            intoxicating, the constant{" "}
            <span className="font-bold">paparazzi intrusion</span> and{" "}
            <span className="font-bold">invasion of privacy</span> make it feel
            like <span className="font-bold">living in a fishbowl</span>.
            Celebrities often sacrifice their personal lives and autonomy in
            exchange for fame, and the price of that fame is often too high for
            many. It's a difficult balance to strike, and many celebrities
            struggle to maintain their sanity and happiness in the face of the{" "}
            <span className="font-bold">constant public scrutiny</span>.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">a double-edged sword</span>:
          </div>
          <div>something that has both positive and negative consequences</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">the VIP treatment</span>:
          </div>
          <div>special treatment given to important or famous people</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">public adoration</span>:
          </div>
          <div>
            a strong, positive feeling of love and respect that the public has
            for someone
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">paparazzi intrusion</span>:
          </div>
          <div>
            the act of the paparazzi invading celebrities' private lives in
            order to take photos or gather information
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">invasion of privacy</span>:
          </div>
          <div>unwanted intrusion into someone's private life or space</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">living in a fishbowl</span>:
          </div>
          <div>
            feeling like you're constantly being watched and that you have no
            privacy
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">constant public scrutiny</span>:
          </div>
          <div>
            the constant, intense attention and judgment that celebrities face
            from the public and media
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            Living as a celebrity often means living{" "}
            <span className="font-bold">under the microscope</span>, with
            constant <span className="font-bold">media attention</span> and a{" "}
            <span className="font-bold">lack of privacy</span>. The{" "}
            <span className="font-bold">price of fame</span> is high, with
            <span className="font-bold"> celebrity status</span> often leading
            to a <span className="font-bold">loss of anonymity</span> and the
            inability to live a private life.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">under the microscope</span>:
          </div>
          <div>constantly being observed and examined</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">price of fame</span>:
          </div>
          <div>
            the negative effects or sacrifices that come with being famous
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">celebrity status</span>:
          </div>
          <div>being known and admired by many people</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">loss of anonymity</span>:
          </div>
          <div>
            no longer being able to move around in public without being
            recognized
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            Fame can be fleeting and unpredictable. While some celebrities enjoy
            a long and steady career{" "}
            <span className="font-bold">in the public eye</span>, others
            experience{" "}
            <span className="font-bold">fifteen minutes of fame</span> before
            fading into <span className="font-bold">obscurity</span>. For those
            who become a <span className="font-bold">household name</span>, the
            <span className="font-bold"> pressure cooker of celebrity</span> can
            be difficult to handle, with the constant scrutiny and pressure to
            maintain their fame. Unfortunately, some celebrities experience{" "}
            <span className="font-bold">a rapid rise to fame</span>, only to
            crash and burn like a
            <span className="font-bold"> shooting star</span>, their fame
            disappearing as quickly as it came.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">in the public eye</span>:
          </div>
          <div>being known or famous in society</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">fifteen minutes of fame</span>:
          </div>
          <div>a brief period of fame</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">obscurity</span>:
          </div>
          <div>being unknown or forgotten</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">household name</span>:
          </div>
          <div>a person or thing that is very famous and widely known</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">pressure cooker of celebrity</span>:
          </div>
          <div>
            the intense stress and pressure that comes with being famous
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">shooting star</span>:
          </div>
          <div>
            a celebrity who experiences a quick rise to fame but then disappears
            just as quickly
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            For some people, <span className="font-bold">fame and fortune</span>{" "}
            is the ultimate goal, and they will do anything to be{" "}
            <span className="font-bold">in the limelight</span>,{" "}
            <span className="font-bold">seeking public approval</span> and
            recognition. But{" "}
            <span className="font-bold">fame is a fickle mistress</span>, and
            not all who chase it are able to{" "}
            <span className="font-bold">ride the wave of popularity</span> for
            long. Some may become{" "}
            <span className="font-bold">a flash in the pan</span>, quickly
            fading from the public consciousness once their{" "}
            <span className="font-bold">fifteen minutes of fame</span> have
            passed.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">in the limelight</span>:
          </div>
          <div>being the center of attention or having a lot of publicity</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">seeking public approval</span>:
          </div>
          <div>trying to gain favor or admiration from the public</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">fame is a fickle mistress</span>:
          </div>
          <div>fame is unpredictable and can be fleeting or elusive</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">ride the wave of popularity</span>:
          </div>
          <div>enjoying a period of popularity or fame</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">a flash in the pan</span>:
          </div>
          <div>a brief period of success or fame that is quickly forgotten</div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            For those who find themselves{" "}
            <span className="font-bold">in the spotlight</span>,{" "}
            <span className="font-bold">life in the fast lane</span> can be
            exhilarating. From the{" "}
            <span className="font-bold">glitz and glamor of fame</span> to the
            <span className="font-bold"> trappings of wealth and success</span>,
            it's easy to get swept up in the
            <span className="font-bold"> allure of celebrity</span>. But as
            quickly as the spotlight shines, it can just as quickly disappear,
            leaving some celebrities with a swift
            <span className="font-bold"> fall from grace</span>. Suddenly, the
            once-famed face is yesterday's news, leaving them to navigate a new
            reality that may not be as glamorous as it once was.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">life in the fast lane</span>:
          </div>
          <div>
            a lifestyle characterized by high speed and intense activity.
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">glitz and glamor of fame</span>:
          </div>
          <div>the flashy, luxurious aspects of fame</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">trappings of wealth and success</span>:
          </div>
          <div>
            the material possessions and luxuries associated with being wealthy
            and successful
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">allure of celebrity</span>:
          </div>
          <div>the appeal or attractiveness of being famous</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">fall from grace</span>:
          </div>
          <div>
            a downward turn in fortune or reputation, often as a result of bad
            behavior or scandals
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            At the peak of their success, celebrities live{" "}
            <span className="font-bold">the high life</span>. With the{" "}
            <span className="font-bold">red carpet treatment</span> and all{" "}
            <span className="font-bold">the trappings of fame</span>, they are
            treated like <span className="font-bold">Hollywood royalty</span>.
            Doors open for them wherever they go, and it seems like{" "}
            <span className="font-bold">the world is their oyster</span>. They
            become <span className="font-bold">larger than life</span>, their
            every move watched and scrutinized by the public. But beneath the
            surface, <span className="font-bold">the price of stardom</span> can
            be high, with long hours, intense pressure, and constant scrutiny
            taking a toll on their personal lives and mental health.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">the high life</span>:
          </div>
          <div>a luxurious lifestyle</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">red carpet treatment</span>:
          </div>
          <div>Being treated like a VIP</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">Hollywood royalty</span>:
          </div>
          <div>celebrities who are very successful and well-known</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">the world is their oyster</span>:
          </div>
          <div>everything is within reach or achievable</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">larger than life</span>:
          </div>
          <div>
            looking more extraordinary and impressive and therefore more
            attractive
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">the price of stardom</span>:
          </div>
          <div>
            the personal costs, such as loss of privacy, intense pressure, and
            constant scrutiny, that come with fame and success
          </div>
        </div>
      </div>
    </>
  );
};
