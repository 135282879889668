const opinionTopics = [
  {
    id: 1,
    topic:
      "Is it important for universities to accept the same number of male and female students in every field of study.",
    question: "Do you agree or disagree?",
  },
  {
    id: 2,
    topic:
      "Some people think that working for a big company is much better than working for a small one.",
    question: "To what extent do you agree or disagree?",
  },
  {
    id: 3,
    topic:
      "There is a viewpoint that high school students should be required to participate in unpaid community service. This might involve activities like teaching sports to younger students, volunteering for charities, or improving the local neighborhood.",
    question: "What is your opinion about this?",
  },
  {
    id: 4,
    topic:
      "Some people say that kids today get too much free time, while it is better to spend this time doing more school work.",
    question: "To what extend do you agree or disagree with this?",
  },
  {
    id: 5,
    topic: "Making people's lives better should be the main aim of science.",
    question: "To what extend do you agree or disagree?",
  },
  {
    id: 6,
    topic:
      "Teenagers must be at home before a specific time at night unless they are with an adult.",
    question: "What is your opinion about this?",
  },
  {
    id: 7,
    topic:
      "The reason why some products sell well today is mainly because of advertising, not because people really need these products.",
    question: "To what extent do you think this is true?",
  },
  {
    id: 8,
    topic:
      "It is said that music can be an effective way to unite people of various cultures and different ages.",
    question: "Do you agree or disagree?",
  },
  {
    id: 9,
    topic:
      "Nowadays, especially in developed countries, people can buy food from differnt parts of the world in their local supermarkets.",
    question: "Do you think this is a good development? Why // Why not?",
  },
  {
    id: 10,
    topic:
      "Every year, some languages disappear. Some people argue that actually this is a good thing since life and communication will be easier with fewer languages in the world.",
    question: "What is your opinion about this?",
  },
  {
    id: 11,
    topic:
      "It is argued that a lot of food and drink products which are produced in factories today have very high levels of sugar which is dangerous to our health. Therefore, goverments should increase the price of such products so that people use less sugar.",
    question: "To what extent do you agree or disagree with this statement?",
  },
  {
    id: 12,
    topic:
      "Online shopping is becoming more and more popular. Some people say that this will eventually cause all the shops and stores in cities to close.",
    question: "To what extent do you agree or disagree with this?",
  },
  {
    id: 13,
    topic:
      "A typical working week in most countries consists of five days. Some say that it should be shorter and employees should be given a longer weekend.",
    question: "To what extent do you agree or disagree with this?",
  },
  {
    id: 14,
    topic:
      "Some people believe that we can learn a lot about a person's culture and personality based on the clothes they usually wear.",
    question: "To what extent do you agree or disagree with this?",
  },
    {
    id: 15,
    topic: "Some say there are too many choices available today.",
    question: "Do you agree or disagree with this?",
  },
  {
    id: 16,
    topic:
      "Every year, a large number of people move from small villages to big cities, so the population in rural areas is decreasing.",
    question: "Do you think this is a good trend? Why? / Why not?",
  },
];

const problemSolutionTopics = [
  {
    id: 1,
    topic:
      "In many countries we are witnessing a rise in people's average weight, accompanied by a decline in overall health and fitness.",
    question:
      "What factors do you think contribute to these issues? How can we address them?",
  },
  {
    id: 2,
    topic: "Crime rates are rising in many countries.",
    question:
      "What do you see as the main causes of this trend? How do you think we can tackle them effectively?",
  },
  {
    id: 3,
    topic: "Many people nowadays find it difficult to get enough sleep.",
    question:
      "What are the potential consequences of lack of sleep? What can be done to fix it?",
  },
  {
    id: 4,
    topic:
      "Despite considerable progress in agriculture, a significant number of people worldwide continue to experience hunger.",
    question:
      "Why is this happening? What can be done to improve the situation?",
  },
  {
    id: 5,
    topic:
      "The use of plastic bags, bottles, and packaging negatively affects the environment.",
    question:
      "How does plastic harm our surroundings, and what steps can be taken by both governments and individuals to mitigate this problem?",
  },
  {
    id: 6,
    topic:
      "People nowadays prefer working for themselves instead of joining a company or organization.",
    question:
      "Why do you think this might be the case? What downsides could come with being self-employed?",
  },
  {
    id: 7,
    topic:
      "A significant number of working individuals do not get adequate exercise these days, and suffer from some health problems as a result.",
    question:
      "Why do you think this is the case? What can be done to address this issue?",
  },
];

const doubleQuestionTopics = [
  {
    id: 1,
    topic:
      "In the near future, paying in cash in stores might completely disappear, and people will use their debit cards and cell phones instead.",
    question:
      "Do you think this might happen one day? Why do you think some people might not like this change?",
  },
  {
    id: 2,
    topic:
      "Nowadays, many people prefer working for themselves rather than being employed by a company or an organization.",
    question:
      "What could be the reasons behind this choice? Are there any drawbacks to being your own boss?",
  },
  {
    id: 3,
    topic: "It is often said that being rich is the key to a happy life.",
    question:
      "In what ways can wealth make people happy? What other factors can contribute to people's happiness in life?",
  },
  {
    id: 4,
    topic:
      "Some people prefer to buy goods that are produced in their own city or country, rather than the prodcuts imported from other countries.",
    question:
      "What are the reasons behind this preference? Do you think this is a positive or a negative attitude?",
  },
  {
    id: 5,
    topic:
      "Most teenagers today spend several hours a day on their smartphones.",
    question:
      "What is the reason for this? Do you think this is a good or a bad behavior?",
  },
  {
    id: 6,
    topic:
      "For some nations, it is very important to own the house where they live, rather than renting it.",
    question:
      "Why do you think this might be the case in some countries? Do you think it is a positive or a negative trend?",
  },
  {
    id: 7,
    topic:
      "Some families sometimes pay others to do some tasks around the house for them, such as doing housework or taking care of their children.",
    question:
      "Do you think it is a good way of providing others with work opportunities? Do you think it is better if people do these things themselves?",
  },
  {
    id: 8,
    topic:
      "Crime movies and series are becoming increasingly popular these days.",
    question:
      "What do you think is the reason behind their widespread appeal? What is your opinion of such movies and series?",
  },
  {
    id: 9,
    topic:
      "More and more people every day make short visits to famous places and monuments in order to have their photograph taken rather than looking carefully at the place itself trying to know more about it.",
    question:
      "What do you think is the reason for this trend? Do you find it positive or negative?",
  },
  {
    id: 10,
    topic:
      "Some people are very keen on learning more about the history of their house.",
    question:
      "What do you think could be the reasons for this interest? How can people find out more about the history of the building where they live?",
  },
  {
    id: 11,
    topic:
      "When advertising their products, most businesses try to emphasize that thier products are somehow new and different from similar products produced by other companies.",
    question:
      "What is the reason for this? Do you think it is a positive or a negative approach?",
  },
  {
    id: 12,
    topic:
      "In the past, the majority of people typically had one job, but nowadays we can find many people who have more than one job at the same time.",
    question:
      "What are the reasons for this phenomenon? What do you think are the pros and cons of having more than one job?",
  },
  {
    id: 13,
    topic:
      "When starting to learn something new, some people prefer to have a personal tutor rather than taking part in classes with other students.",
    question:
      "What are the reasons for this? Do yo think it is a positive or a negative trend?",
  },
  {
    id: 14,
    topic:
      "Some people live almost all their lives near to the place where they were born.",
    question:
      "Why is this the case? What are the advantages and disadvantages?",
  },
  {
    id: 15,
    topic:
      "Some people resist change and want the conditions in their society and their own lives to remain unchanged.",
    question:
      "Why do some people dislike change? What reasons exist for considering change as a positive phenomenon?",
  },
  {
    id: 16,
    topic:
      "Some people believe that we are currently living in the best time throughout history.",
    question:
      "Do you think this is true? If you could live in another time in history, what would that be?",
  },
];

const discussionTopics = [
  {
    id: 1,
    topic:
      "Some college students like to study other topics besides their main subject. Other students think it is better to focus all their time and effort on getting a degree.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 2,
    topic:
      "There is a belief that letting children decide on daily issues like food, clothing, and entertainment may lead to a self-centered society. Others, however, insist that it is crucial for children to have a say in matters that directly impact them.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 3,
    topic:
      "Some people think it is a good idea to encourage children to be competitive. Others, however, think that when children learn to cooperate instead of competing, they grow up to be more helpful adults.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 4,
    topic:
      "Some believe that parents should encourage their kids to join group activities during their free time. Others think it is important for kids to learn how to entertain themselves independently.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 5,
    topic:
      "Today, celebrities are always being photographed by professional photographers wherever they go. Some think this is good because regular people like to know about their lives. Others believe photographers should not follow famous people around.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 6,
    topic:
      "Some say tourists should respect and follow the local ways and behavior when visiting other countries. Others do not agree and believe the host country should accept and appreciate cultural differences.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 7,
    topic:
      "Some think it is better to just accept and cope with a tough situation, like a job you are not satisfied with or lack of money. Others say it is better to try and make these situations better.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 8,
    topic:
      "Some believe that competition in our professional, academic, and everyday life is a good thing. Others think that instead of competing against each other, we'd better learn how to cooperate more.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 9,
    topic:
      "There is a view that punishments for each type of crime should be fixed. However, others believe that the special circumstances and motivations in every crime should be considered before determining suitable penalties.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 10,
    topic:
      "There are those who argue that socializing with co-workers outside of working hours, including evenings and weekends, is a good idea. On the other hand, others assert that keeping our social life separate from our professional life is crucial.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 11,
    topic:
      "Some people believe that college students should be free to pursue their desired subjects, regardless of their practicality. In contrast, others maintain that students should be encouraged to focus on disciplines that offer clear benefits for the future, with a particular emphasis on science and technology.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 12,
    topic:
      "One viewpoint argues that individuals in professions such as medicine and engineering should be obligated to practice in the country where they obtained their qualifications. On the other hand, there are those who believe that these professionals should have the freedom to seek employment in any country that they wish.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 13,
    topic:
      "Some argue that the most serious environmental issue today is the disappearance of certain plant and animal species. Others assert that there are more significant environmental concerns that deserve our attention.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 14,
    topic:
      "While some argue that learning a foreign language is primarily motivated by the desire to travel or work abroad, others maintain that there are additional factors beyond these that make foreign language acquisition worthwhile.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 15,
    topic:
      "Some believe advertising strongly influences our buying habits. Others think advertising has become so common that people do not pay attention to it any more.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 16,
    topic:
      "During job interviews, employers occasionally ask for personal details, like applicants' hobbies and marital status. While some argue that this information could be useful and relevant, others believe that it is not appropriate or necessary to ask such questions.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 17,
    topic:
      "While some consider History as one of the most crucial subjects in schools, others believe that in modern times, Science and Technology subjects should be considered more important than History.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 18,
    topic:
      "There are those who enjoy experiencing new things like exploring different locations or trying out various types of food. However, some individuals prefer to stick with what they already know and are comfortable with.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 19,
    topic:
      "It is argued by some that, with the accessibility of movies on mobile devices like phones and tablets, we do not need to go to a cinema to watch movies any more. Others assert that the full enjoyment of films can only be experienced in a cinema setting.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 20,
    topic:
      "A number of governments see economic development as their primary aim. In contrast, certain individuals believe that other forms of progress have equal significance for a country's advancement.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 21,
    topic:
      "Some feel that sharing as much information as possible in the fields of science, technology, business etc. is a good idea. Others think certain information is too important and valuable to be shared with others freely.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 22,
    topic:
      "Some prefer to have friends who always share the same opinions as them. Others believe that it is better to have friends who occasionally disagree with them.",
    question: "Discuss both these views and give your own opinion.",
  },
  {
    id: 23,
    topic:
      "In some countries, there is a view that it is crucial to invest significant amounts of money in building new high-speed rail lines between cities. However, some argue that it is better if these funds are spent on improving the conditions of the public transportation that already exist in the country.",
    question: "Discuss both these views and give your own opinion.",
  },
];

const advantagesAndDisadvantagesTopics = [
  {
    id: 1,
    topic:
      "Nowadays, long working hours often lead to limited free time for leisure activities.",
    question: "Does this situation have more advantages or disadvantages?",
  },
  {
    id: 2,
    topic: "Taking risks is essential, both professionally and personally.",
    question:
      "Do the potential advantages in risk-taking outweigh its disadvantages?",
  },
  {
    id: 3,
    topic:
      "While some prefer living in a house, others find apartments more advantageous.",
    question:
      "Does living in a house offer more advantages than disadvantages in comparison to living in an apartment?",
  },
  {
    id: 4,
    topic:
      "In numerous countries, people are experiencing longer lifespans. While some argue that an aging population creates challenges for governments, others believe that having more elderly citizens can be advantageous for society.",
    question:
      "Do you think the advantages of having an aging population outweigh its disadvantages?",
  },
  {
    id: 5,
    topic:
      "Being a celebrity, whether as a film star or sports icon, comes with both advantages and disadvantages.",
    question:
      "Is the life of a celebrity ultimately more beneficial or challenging?",
  },
  {
    id: 6,
    topic:
      "These days, an increasing number of individuals choose self-employment instead of working for companies or organizations.",
    question:
      "What reasons might lead people to make this choice? Furthermore, what potential downsides could come with being self-employed?",
  },
  {
    id: 7,
    topic:
      "Museums vary in their admission policies, with some requiring entrance fees and others offering free access.",
    question:
      "Does it have more advantages or disadvantages to charge people who want to visit a museum?",
  },
  {
    id: 8,
    topic: "Mobile payment apps are becoming more common in many countries.",
    question:
      "Does this trend offer more benefits or drawbacks to users and society?",
  },
  {
    id: 9,
    topic: "Soon, all vehicles on roads will be driverless.",
    question:
      "If all cars, buses, and trucks become self-driven, would the benefits outweigh the potential drawbacks?",
  },
  {
    id: 10,
    topic:
      "Currently, certain countries have a significantly high percentage of young adults in their population, compared to seniors.",
    question:
      "Do the potential advantages of this situation outweigh its drawbacks?",
  },
  {
    id: 11,
    topic:
      "In certain societies, children are frequently encouraged to believe that persistence and effort can lead to success in any field that they like.",
    question:
      "Discuss both the benefits and drawbacks of encouraging this belief in young minds.",
  },
  {
    id: 12,
    topic:
      "Recently, there has been a rise in tourists deciding to explore extreme environments like the Sahara desert and Antarctica.",
    question:
      "What are the pros and cons for travelers visiting these challenging locations?",
  },
];

export {
  opinionTopics,
  problemSolutionTopics,
  doubleQuestionTopics,
  discussionTopics,
  advantagesAndDisadvantagesTopics,
};
