import { useTitle } from "../components";
import HeroImage from "../assets/images/heroImage.svg";

export const HomePage = ({ title }) => {
  useTitle(title);

  return (
    <div
      className="flex flex-col md:flex-row md:justify-between lg:items-center md:my-10 lg:my-16 lg:overflow-hidden"
      data-aos="zoom-in"
      data-aos-duration="800"
    >
      <div>
        <div className="font-normal text-indigo-950 drop-shadow text-3xl sm:text-3xl lg:text-4xl xl:text-5xl text-start lg:leading-tight ml-10 my-10 md:ml-24 lg:ml-36 lg:my-0">
          <h1 className="font-bold mb-10">English Hub Online</h1>
          Your Gateway to
          <br />
          <span className="text-pink-700  font-bold">English Grammar</span>
          <br />
          Mastery and
          <br />
          <span className="text-pink-700  font-bold">IELTS</span> Success!
        </div>
      </div>
      <div className="self-end w-5/6 md:w-1/2 mb-10 md:mb-5 md:-mr-2">
        <img src={HeroImage} alt="A young body studying online" />
      </div>
    </div>
  );
};
