import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LongArrow from "../../../assets/images/arrowLongLight.svg";

export const PresentContinuousElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences using the correct form of the verbs in parentheses
      in the Present Continuous. Use contractions where possible.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>They ____ for the school play this week. (practice)</span>
      ),
      answer: (
        <span className="font-normal">
          They<span className="font-bold">'re practicing</span> for the school
          play this week.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>We ____ our summer holdiay right now. (plan)</span>,
      answer: (
        <span>
          We<span className="font-bold">'re planning</span> our summer holiday
          right now.
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          I ____ this week because I'm trying to save money. (eat out)
        </span>
      ),
      answer: (
        <span>
          I<span className="font-bold">'m not eating out</span> this week
          because I'm trying to save money.
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          He seems so busy. ____ he ____ for his exams these days? (study)
        </span>
      ),
      answer: (
        <span>
          He seems so busy. <span className="font-bold">Is</span> he{" "}
          <span className="font-bold">studying</span> for his exams these days?
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>
          They aren't at home at the moment. They ____ around the country.
          (travel)
        </span>
      ),
      answer: (
        <span>
          They aren't at home at the moment. They
          <span className="font-bold">'re traveling</span> around the country.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Present Continuous</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Present Continuous
          </h1>
          <div className="mb-16">
            <p className="mb-4">
              We use the Present Continuous tense to talk about:
            </p>
            <div className="flex mb-1">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">
                a verb which is happening right now, at the moment of speaking
              </span>
            </div>

            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-6">
              <p className="font-semibold pb-6 italic">Examples:</p>{" "}
              <p className="pb-2 italic">
                You<span className="font-bold">'re reading</span> this sentence
                right now.
              </p>
              <p className="pb-1 italic">
                Can you turn down the music? I
                <span className="font-bold">'m studying</span>.
              </p>
            </div>
            <div className="flex mb-4">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">
                a verb which is happening around the present time, not
                necessarily right now, on a temporary basis
              </span>
            </div>
            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
              <p className="font-semibold pb-6 italic">Examples:</p>{" "}
              <p className="pb-4 italic">
                I<span className="font-bold">'m reading</span> an interesting
                book about the Second World War these days.
              </p>
              <p className="pb-1 italic">
                He<span className="font-bold">'s working</span> on a new project
                this week, so he can't accept our job offer.
              </p>
            </div>
          </div>

          <p className="mb-9">
            Let's take a look at the different forms of the Present Continuous
            tense for the verb "work".
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Positive</h3>
            <p>
              I <span className="font-semibold">am</span> / I
              <span className="font-semibold">'m working</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span> / You
              <span className="font-semibold">'re working</span> ... .
            </p>
            <p>
              He <span className="font-semibold">is</span> / He
              <span className="font-semibold">'s working</span> ... .
            </p>
            <p>
              She <span className="font-semibold">is</span> / She
              <span className="font-semibold">'s working</span> ... .
            </p>
            <p>
              It <span className="font-semibold">is</span> / It
              <span className="font-semibold">'s working</span> ... .
            </p>
            <p>
              We <span className="font-semibold">are</span> / We
              <span className="font-semibold">'re working</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span> / You
              <span className="font-semibold">'re working</span> ... .
            </p>
            <p>
              They <span className="font-semibold">are</span> / They
              <span className="font-semibold">'re working</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Negative</h3>
            <p>
              I <span className="font-semibold">am</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / I<span className="font-semibold">'m </span>
              <span className="font-semibold text-red-700">not</span> working
              ... .
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / You
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              He <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / He
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              She <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / She
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              It <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / It
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              We <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / We
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / You
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              They <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / They
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Am</span> I working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they working ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Am</span> I{" "}
              <span className="font-semibold text-red-700">not</span> working
              ...?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you
              working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> he working
              ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> she
              working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> it working
              ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> we working
              ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you
              working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> they
              working ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">am</span>. / No, I
              <span className="font-semibold">'m </span>
              <span className="font-semibold text-red-700">not</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">is</span>. / No, he{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">is</span>. / No, she{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">is</span>. / No, it{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">are</span>. / No, we{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">are</span>. / No, they{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, we use the auxiliary verbs "am", "is" or "are" to
              make negative sentences and questions in the Present Continuous
              tense.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She's sleeping.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She isn't sleeping.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Is she sleeping?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Isn't she sleeping? / Is she not sleeping?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she is. / No, she isn't.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They're watching TV.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">They aren't watching TV.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Are they watching TV?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Aren't they watching TV? / Are they not watching TV?
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, they are. / No, they aren't.</span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              When a verb ends in an "<span className="font-bold">e</span>", we
              drop the final "<span className="font-bold">e</span>" before
              adding "-ing".
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>
                mak
                <span className="font-bold">e</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>making</span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                danc
                <span className="font-bold">e</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>dancing</span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              When a verb ends in "<span className="font-bold">ie</span>", we
              change the "<span className="font-bold">ie</span>" to "
              <span className="font-bold">y</span>" before adding "-ing".
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>
                d<span className="font-bold">ie</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                d<span className="font-bold">y</span>ing
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                l<span className="font-bold">ie</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                l<span className="font-bold">y</span>ing
              </span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              When a one-syllable verb ends in a consonant-vowel-consonant (CVC)
              pattern, we double the final consonant before adding "-ing",
              except when the verb ends in "x", "y" or "w".
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>run</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                run<span className="font-bold">n</span>ing
              </span>
            </p>
            <p className="pb-4 italic flex">
              <span>sit</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                sit<span className="font-bold">t</span>ing
              </span>
            </p>
            <p className="mb-4 font-bold">but</p>
            <p className="pb-1 italic flex">
              <span>fix</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>fixing</span>
            </p>
            <p className="pb-1 italic flex">
              <span>play</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>playing</span>
            </p>
            <p className="pb-1 italic flex">
              <span>row</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>rowing</span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Present Continuous
          </h1>
          <div className="mb-10">
            <p lang="fa" dir="rtl" className="mb-4">
              در شرایط زیر از این زمان استفاده می کنیم:
            </p>
            <div lang="fa" dir="rtl" className="flex mb-1">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">
                وقتی راجع به فعلی صحبت می کنیم که همین الان که داریم درباره اش
                حرف می زنیم در حال رخ دادن است:
              </span>
            </div>

            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-6">
              <p lang="fa" dir="rtl" className="font-semibold pb-6">
                مثال:
              </p>{" "}
              <p className="mb-1 italic">
                You<span className="font-bold">'re reading</span> this sentence
                right now.
              </p>
              <p lang="fa" dir="rtl" className="mb-4">
                تو همین الان در حال خواندن این جمله هستی /{" "}
                <span className="underline">داری</span> این جمله را می خوانی.
              </p>
              <p className="mb-1 italic">
                Can you turn down the music? I
                <span className="font-bold">'m studying</span>.
              </p>
              <p lang="fa" dir="rtl" className="mb-1">
                ممکن است صدای موسیقی را کم کنی؟{" "}
                <span className="underline">دارم</span> درس می خوانم.
              </p>
            </div>
            <div lang="fa" dir="rtl" className="flex mb-4">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">
                وقتی راجع به فعلی صحبت می کنیم که حول و حوش زمان حاضر، نه لزوما
                همین الان، به طور موقت در حال رخ دادن است:
              </span>
            </div>
            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md">
              <p lang="fa" dir="rtl" className="font-semibold pb-6">
                مثال:
              </p>{" "}
              <p className="mb-1 italic">
                I<span className="font-bold">'m reading</span> an interesting
                book about the Second World War these days.
              </p>
              <p lang="fa" dir="rtl" className="mb-4">
                این روز ها <span className="underline">دارم</span> یک کتاب جالب
                راجع به جنگ جهانی دوم می خوانم.
              </p>
              <p className="mb-1 italic">
                He<span className="font-bold">'s working</span> on a new project
                this week, so he can't help us.
              </p>
              <p lang="fa" dir="rtl" className="mb-1">
                این هفته <span className="underline">دارد</span> روی یک پروژه
                جدید کار می کند / درگیر یک پروژه جدید است، برای همین نمی تواند
                به ما کمک کند.
              </p>
            </div>
          </div>

          <p lang="fa" dir="rtl" className="mb-6">
            در ادامه شکل های مختلف این زمان را برای فعل work مشاهده می کنید.
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive</span>
              <span lang="fa" className="ml-8">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">am</span> / I
              <span className="font-semibold">'m working</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span> / You
              <span className="font-semibold">'re working</span> ... .
            </p>
            <p>
              He <span className="font-semibold">is</span> / He
              <span className="font-semibold">'s working</span> ... .
            </p>
            <p>
              She <span className="font-semibold">is</span> / She
              <span className="font-semibold">'s working</span> ... .
            </p>
            <p>
              It <span className="font-semibold">is</span> / It
              <span className="font-semibold">'s working</span> ... .
            </p>
            <p>
              We <span className="font-semibold">are</span> / We
              <span className="font-semibold">'re working</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span> / You
              <span className="font-semibold">'re working</span> ... .
            </p>
            <p>
              They <span className="font-semibold">are</span> / They
              <span className="font-semibold">'re working</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative</span>
              <span lang="fa" className="ml-8">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">am</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / I<span className="font-semibold">'m </span>
              <span className="font-semibold text-red-700">not</span> working
              ... .
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / You
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              He <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / He
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              She <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / She
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              It <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / It
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              We <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / We
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / You
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
            <p>
              They <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... . / They
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> working
              ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive Questions</span>
              <span lang="fa" className="ml-8">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Am</span> I working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they working ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative Questions</span>
              <span lang="fa" className="ml-8">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Am</span> I{" "}
              <span className="font-semibold text-red-700">not</span> working
              ...?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you
              working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> he working
              ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> she
              working ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> it working
              ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> we working
              ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you
              working ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they{" "}
              <span className="font-semibold text-red-700">not</span> working
              ... ? / <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> they
              working ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Short Answers</span>
              <span lang="fa" className="ml-8">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">am</span>. / No, I
              <span className="font-semibold">'m </span>
              <span className="font-semibold text-red-700">not</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">is</span>. / No, he{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">is</span>. / No, she{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">is</span>. / No, it{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">are</span>. / No, we{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">are</span>. / No, they{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              همانطور که مشاهده می کنید، برای منفی و سوالی کردن جملات در این
              زمان از فعل های کمکی is ،am یا are استفاده می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She's sleeping.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She isn't sleeping.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Is she sleeping?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Isn't she sleeping? / Is she not sleeping?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she is. / No, she isn't.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They're watching TV.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">They aren't watching TV.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Are they watching TV?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Aren't they watching TV? / Are they not watching TV?
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, they are. / No, they aren't.</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              در فعل هایی که به حرف e بعد از یک حرف صامت ختم می شوند، وقتی فعل
              ing می گیرد، حرف e آخر فعل حذف می شود.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>
                mak
                <span className="font-bold">e</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>making</span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                danc
                <span className="font-bold">e</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>dancing</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              در فعل هایی که به ie ختم می شوند، وقتی فعل ing می گیرد، ie آخر فعل
              به y تبدیل می شود.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>
                d<span className="font-bold">ie</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                d<span className="font-bold">y</span>ing
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                l<span className="font-bold">ie</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                l<span className="font-bold">y</span>ing
              </span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              در فعل های تک سیلابی ای که به یک حرف صامت + یک حرف مصوت + یک حرف
              صامت ختم می شوند، وقتی فعل ing می گیرد، حرف آخر فعل تکرار می شود،
              به استثنا فعل هایی که به x ،y و یا w ختم می شوند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>run</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                run<span className="font-bold">n</span>ing
              </span>
            </p>
            <p className="pb-4 italic flex">
              <span>sit</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                sit<span className="font-bold">t</span>ing
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-4 font-bold">
              اما
            </p>
            <p className="pb-1 italic flex">
              <span>fix</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>fixing</span>
            </p>
            <p className="pb-1 italic flex">
              <span>play</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>playing</span>
            </p>
            <p className="pb-1 italic flex">
              <span>row</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>rowing</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              در پایان، همانطور که در ترجمه فارسی اغلب مثال های بالا مشاهده می
              کنید، می شود گفت معادل این زمان در زبان فارسی <mark>معمولا</mark>{" "}
              صرف فعل «داشتن» در زمان حاضر (دارم، داری، دارد، داریم، دارید،
              دارند) کنار سایر افعال است.
            </span>
          </p>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
