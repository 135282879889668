import { useState } from "react";
import { Link } from "react-router-dom";
import { EssayTopicCard, Pagination, useTitle } from "../../../components";
import { discussionTopics } from "../../../data/EssayTopicsData";

export const DiscussionEssayTopics = ({ title }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 5;

  const paginate = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return discussionTopics.slice(startIndex, endIndex);
  };

  const slicedTopics = paginate();

  const handleChangePage = (p) => {
    setCurrentPage(p);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useTitle(title);

  return (
    <>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link to="/Ielts" className="duration-300 hover:text-indigo-950">
          <span>IELTS</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link
          to="/Ielts/writingTask2Topics"
          className="duration-300 hover:text-indigo-950"
        >
          <span>Essay Topics</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">Discussion</span>
      </nav>

      {/* ---------- Body ---------- */}
      <div
        className="my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12"
        data-aos="zoom-in"
        data-aos-duration="800"
      >
        <h1 className="flex flex-col drop-shadow text-center mt-10 mb-14">
          <span className="text-pink-700 mb-5 text-3xl sm:text-4xl">
            IELTS Writing Task 2 Topics
          </span>

          <span className="font-bold text-4xl sm:text-5xl">Discussion Essays</span>
        </h1>
        <p className="mb-14">
          Discussion essays involve exploring two viewpoints on a particular
          topic, requiring you to present a well-rounded analysis and your own
          perspective.
        </p>
        {slicedTopics.map((item) => (
          <EssayTopicCard key={item.id} item={item} />
        ))}
      </div>

      <Pagination
        totalCount={discussionTopics.length}
        pageSize={pageSize}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </>
  );
};
