export const SearchInput = ({ List, setSearchResults }) => {
  const handleSearch = (searchItem) => {
    const filteredArray = List.filter((item) =>
      item.keywords.some((keyword) =>
        keyword.toLowerCase().includes(searchItem.toLowerCase())
      )
    );

    filteredArray.length === List.length
      ? setSearchResults(null)
      : setSearchResults(filteredArray);
  };

  return (
    <div className="flex border border-gray-100 py-3 w-72 min-[376px]:w-80 min-[544px]:w-96 sm:w-3/5 md:w-[461px] mx-auto mt-10 bg-white shadow-md rounded-3xl">
      <input
        type="text"
        name="searchBar"
        className="grow rounded-3xl pl-3 bg-white text-lg text-indigo-950 outline-none"
        placeholder=" Search for a topic ..."
        autoComplete="off"
        onChange={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
};
