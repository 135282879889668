import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";

export const SubjectPronouns = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction =
    "Tom is speaking about himself and his family. Fill in the blank spaces with the right subject pronouns.";
  const questions = [
    {
      id: "",
      question: (
        <span className="-mt-5">
          My name is Tom. ____<sup>1</sup> am 15 years old, and ____<sup>2</sup>{" "}
          live in London. My father is an engineer. ____<sup>3</sup> works for a
          company. My mother is a nurse. ____<sup>4</sup> works at the local
          hospital. My parents work long hours because ____<sup>5</sup> want to
          earn enough money for our family. I have one sister. Her name is Rose.
          ____<sup>6</sup> is one year older than me. Jack is my best friend.
          Jack and I meet each other every day. ____<sup>7</sup> usually go to
          the sports club together. The sports club is very big and ____
          <sup>8</sup> is always very crowded.
        </span>
      ),
      answer: (
        <span className="font-normal">
          My name is Tom. <span className="font-bold">I</span>
          <sup>1</sup> am 15 years old, and <span className="font-bold">I</span>
          <sup>2</sup> live in London. My father is an engineer.{" "}
          <span className="font-bold">He</span>
          <sup>3</sup> works for a company. My mother is a nurse.{" "}
          <span className="font-bold">She</span>
          <sup>4</sup> works at the local hospital. My parents work long hours
          because <span className="font-bold">they</span>
          <sup>5</sup> want to earn enough money for our family. I have one
          sister. Her name is Rose. <span className="font-bold">She</span>
          <sup>6</sup> is one year older than me. Jack is my best friend. Jack
          and I meet each other every day. <span className="font-bold">We</span>
          <sup>7</sup> usually go to the sports club together. The sports club
          is very big and <span className="font-bold">it</span>
          <sup>8</sup> is always very crowded.
        </span>
      ),
    },
  ];

  useTitle(!farsi ? title : "ضمایر فاعلی");

  return (
    <>
      <div data-aos="fade" data-aos-duration="1500">
        {/* ---------- Breadcrumb Menu ---------- */}
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Subject Pronouns</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* ---------- Language Switch ---------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* ---------- Body in English ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Subject Pronouns
          </h1>
          <p className="mb-5">
            <mark>Most</mark> sentences in English start with a subject,
            followed by a verb. The subject of a sentence is either what the
            sentence is about or who or what does the action/verb.
          </p>
          <p className="mb-9">
            The subject in a sentence can be either a{" "}
            <span className="underline decoration-indigo-950">noun</span>,
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">
              <span className="underline decoration-indigo-950">Sarah</span>{" "}
              speaks English very well.
            </p>
          </div>
          <p className="mb-9">
            or a{" "}
            <span className="underline decoration-indigo-950">noun phrase</span>
            ,
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">
              <span className="underline decoration-indigo-950">
                His sister
              </span>{" "}
              speaks English very well.
            </p>
          </div>
          <p className="mb-9">
            or a{" "}
            <span className="underline decoration-indigo-950">
              subject pronoun
            </span>
            .
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">
              <span className="underline decoration-indigo-950">She</span>{" "}
              speaks English very well.
            </p>
          </div>
          <p className="mb-9">
            These are the <span className="font-bold">subject pronouns</span> in
            English:
          </p>

          <div className="grid grid-cols-2 border border-indigo-950 w-64 mx-auto text-center mb-9">
            <div className="col-span-2 uppercase py-1 text-pink-700 font-bold border-b border-indigo-950">
              Subject Pronouns
            </div>
            <div className="py-1 font-semibold border-b border-r border-indigo-950">
              Singular
            </div>
            <div className="py-1 font-semibold border-b border-indigo-950">
              Plural
            </div>
            <div className="py-1 border-b border-r border-indigo-950">I</div>
            <div className="py-1 border-b border-indigo-950">we</div>
            <div className="py-1 border-b border-r border-indigo-950">you</div>
            <div className="py-1 border-b border-indigo-950">you</div>
            <div className="py-1 border-r border-indigo-950">
              he
              <br />
              she
              <br />
              it
              <br />
            </div>
            <div className="py-1 flex items-center justify-center">they</div>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Subject Pronouns <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              ضمایر فاعلی
            </span>
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            <mark>بیشتر</mark> جملات انگلیسی از چپ به راست، به ترتیب، با فاعل و
            سپس فعل شروع می شوند. به طور خلاصه می شود گفت فاعل کسی یا چیزی است
            که جمله درباره آن است، یا کسی یا چیزی است که انجام دهنده عمل یا فعل
            جمله است.
          </p>
          <p lang="fa" dir="rtl" className="mb-6">
            فاعل معمولا یا یک <span className="underline">اسم</span> است،
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              <span className="underline">Sarah</span> speaks English very well.
            </p>
            <p lang="fa" dir="rtl">
              <span className="underline">سارا</span> خیلی خوب انگلیسی صحبت می
              کند.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="mb-6">
            یا یک <span className="underline">عبارت اسمی</span>،
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              <span className="underline">His sister</span> speaks English very
              well.
            </p>
            <p lang="fa" dir="rtl">
              <span className="underline">خواهرش</span> خیلی خوب انگلیسی صحبت می
              کند.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="mb-6">
            و یا یک <span className="underline">ضمیر فاعلی</span>.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              <span className="underline">She</span> speaks English very well.
            </p>
            <p lang="fa" dir="rtl">
              <span className="underline">او</span> خیلی خوب انگلیسی صحبت می
              کند.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="pb-9">
            در جدول زیر <span className="font-bold">ضمایر فاعلی</span> در زبان
            انگلیسی را مشاهده می کنید.
          </p>
          <div className="grid grid-cols-2 border border-indigo-950 w-64 mx-auto text-center mb-9">
            <div className="col-span-2 uppercase py-1 text-pink-700 font-bold border-b border-indigo-950">
              Subject Pronouns
              <br />
              <span lang="fa" dir="rtl">
                ضمایر فاعلی
              </span>
            </div>
            <div className="py-1 font-semibold border-b border-r border-indigo-950">
              Singular
              <br />
              <span lang="fa" dir="rtl">
                مفرد
              </span>
            </div>
            <div className="py-1 font-semibold border-b border-indigo-950">
              Plural
              <br />
              <span lang="fa" dir="rtl">
                جمع
              </span>
            </div>
            <div className="py-1 border-b border-r border-indigo-950">
              I
              <span lang="fa" dir="rtl" className="pl-3">
                من
              </span>
            </div>
            <div className="py-1 border-b border-indigo-950">
              we
              <span lang="fa" dir="rtl" className="pl-3">
                ما
              </span>
            </div>
            <div className="py-1 border-b border-r border-indigo-950">
              you
              <span lang="fa" dir="rtl" className="pl-3">
                تو
              </span>
            </div>
            <div className="py-1 border-b border-indigo-950">
              you
              <span lang="fa" dir="rtl" className="pl-3">
                شما
              </span>
            </div>
            <div className="py-1 border-r border-indigo-950">
              he
              <span lang="fa" dir="rtl" className="pl-3">
                او (مرد)
              </span>
              <br />
              she{" "}
              <span lang="fa" dir="rtl" className="pl-3">
                او (زن)
              </span>
              <br />
              it
              <span lang="fa" dir="rtl" className="pl-3">
                این/آن
              </span>
            </div>
            <div className="py-1 flex flex-col items-center justify-center">
              <span>they</span>

              <span lang="fa" dir="rtl">
                آنها/ایشان
              </span>
            </div>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
