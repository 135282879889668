import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";

export const PrepositionsElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>Complete the sentences with the right preposition.</span>
  );
  const questions = [
    {
      id: 1,
      question: <span>I usually go to bed ____ 11 p.m.</span>,
      answer: (
        <span>
          I usually go to bed <span className="font-bold">at</span> 11 p.m.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>Police officers sometimes have to work ____ night.</span>,
      answer: (
        <span>
          Police officers sometimes have to work{" "}
          <span className="font-bold">at</span> night.
        </span>
      ),
    },
    {
      id: 3,
      question: <span>She goes ____ the gym three times a week.</span>,
      answer: (
        <span>
          She goes <span className="font-bold">to</span> the gym three times a
          week.
        </span>
      ),
    },
    {
      id: 4,
      question: <span>They live ____ a big apartment downtown.</span>,
      answer: (
        <span>
          They live <span className="font-bold">in</span> a big apartment
          downtown.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>His birthday is ____ August.</span>,
      answer: (
        <span>
          His birthday is <span className="font-bold">in</span> August.
        </span>
      ),
    },
    {
      id: 6,
      question: <span>The meeting is ____ January 7th.</span>,
      answer: (
        <span>
          The meeting is <span className="font-bold">on</span> January 7th.
        </span>
      ),
    },
  ];

  useTitle(!farsi ? title : "حروف اضافه");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Prepositions</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Prepositions
          </h1>
          <p className="mb-9">
            Prepositions are words like in, at, on, from, with, of, after, than
            ... that are usually put before a noun, or an object pronoun to show
            direction, place, time etc.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-14">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              They usually have dinner <span className="font-bold">at</span>{" "}
              home.
            </p>
            <p className="pb-2">
              Your glasses are <span className="font-bold">on</span> the table.
            </p>{" "}
            <p className="pb-2">
              He goes <span className="font-bold">to</span> the gym three times
              a week.
            </p>
          </div>

          <h2 className="mb-9 text-2xl md:text-3xl font-bold">
            Prepositions of Time
          </h2>

          <p className="mb-5">
            We use the preposition "<span className="font-bold">in</span>"
            before:
          </p>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 italic">
              the morning / the afternoon / the evening
            </span>
          </div>
          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 ">months</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-2">in January</p>
          </div>
          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">seasons</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-2">in (the) winter</p>
          </div>
          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">years</span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-14">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-2">in 1990</p>
          </div>

          <p className="mb-5">
            We use the preposition "<span className="font-bold">at</span>"
            before:
          </p>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 italic">
              night / lunchtime / noon / midnight
            </span>
          </div>
          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 ">times</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-2">at 6:00 o'clock</p>
            <p className="pb-2">at 5:45</p>
          </div>

          <p className="mb-5">
            We use the preposition "<span className="font-bold">on</span>"
            before:
          </p>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 ">days</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-2">on Monday</p>
          </div>
          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 ">dates</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-2">on June 1, 1990</p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Prepositions <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              حروف اضافه
            </span>
          </h1>

          <p lang="fa" dir="rtl" className="mb-9">
            حروف اضافه (prepositions) در زبان انگلیسی کلماتی هستند مانند in ،at
            ،on ،of ،from ،with ،before ،than و ... که معمولا قبل از یک اسم یا
            یک ضمیر مفعولی می نشینند و بر زمان، مکان، جهت و ... دلالت می کنند.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-14">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              They usually have dinner <span className="font-bold">at</span>{" "}
              home.
            </p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              آنها معمولا در خانه شام می خورند.
            </p>
            <p className="pb-2">
              Your glasses are <span className="font-bold">on</span> the table.
            </p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              عینکت روی میزه.
            </p>
            <p className="pb-2">
              He goes <span className="font-bold">to</span> the gym three times
              a week.
            </p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              او هفته ای سه بار می رود باشگاه.
            </p>
          </div>

          <h2 className="mb-9 text-2xl md:text-3xl font-bold">
            Prepositions of Time
          </h2>

          <p lang="fa" dir="rtl" className="mb-5">
            معمولا حرف اضافه <span className="font-bold">in</span> را در شرایط
            زیر استفاده می کنیم:
          </p>

          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 italic">
              <span className="not-italic">قبل از</span> the morning / the
              afternoon / the evening
            </span>
          </div>
          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 ">قبل از اسم ماه ها</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">in January</p>
          </div>
          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">قبل از اسم فصل ها</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">in (the) winter</p>
          </div>
          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">قبل از سال ها</span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-14">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">in 1990</p>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            حرف اضافه <span className="font-bold">at</span> را در شرایط زیر
            استفاده می کنیم:
          </p>

          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 italic">
              <span className="not-italic">قبل از</span> night / lunchtime /
              noon / midnight
            </span>
          </div>
          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 ">قبل از زمانهایی که ساعت نشان می دهد</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">at 6:00 o'clock</p>
            <p className="pb-2">at 5:45</p>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            حرف اضافه <span className="font-bold">on</span> را در شرایط زیر
            استفاده می کنیم:
          </p>

          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 ">قبل از اسم روز های هفته</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">on Monday</p>
          </div>
          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 ">قبل از تاریخ یک روز خاص</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">on June 1, 1990</p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
