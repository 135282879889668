import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LinkImg from "../../../assets/images/link.svg";

export const UncountableElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Choose the correct expression printed in{" "}
      <span className="italic">italics</span>.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          There <span className="italic">is some milk / are some milks</span> on
          the table.
        </span>
      ),
      answer: (
        <span>
          There <span className="font-bold">is some milk</span> on the table.
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          I bought{" "}
          <span className="italic">two bottles of water / two waters</span> at
          the store.
        </span>
      ),
      answer: (
        <span>
          I bought <span className="font-bold">two bottles of water</span> at
          the store.
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          We need <span className="italic">some rice / some rices</span> to cook
          dinner.
        </span>
      ),
      answer: (
        <span>
          We need <span className="font-bold">some rice</span> to cook dinner.
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          There <span className="italic">are two dogs / is two dog</span> in the
          garden.
        </span>
      ),
      answer: (
        <span>
          There <span className="font-bold">are two dogs</span> in the garden.
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>
          We have{" "}
          <span className="italic">some furniture / some furnitures</span> in
          the living room.
        </span>
      ),
      answer: (
        <span>
          We have <span className="font-bold">some furniture</span> in the
          living room.
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>
          Don't you have{" "}
          <span className="italic">any homework / any homeworks</span> to do?
        </span>
      ),
      answer: (
        <span>
          Don't you have <span className="font-bold">any homework</span> to do?
        </span>
      ),
    },
  ];

  useTitle(!farsi ? title : "اسامی غیر قابل شمارش");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Uncountable Nouns</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Uncountable Nouns
          </h1>
          <p className="mb-5">There are two types of nouns in English:</p>

          <div className="flex ml-10">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span>
              countable nouns (<span className="font-bold">[C]</span>)
            </span>
          </div>
          <div className="flex ml-10 mb-5">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span>
              uncountable nouns (<span className="font-bold">[U]</span>)
            </span>
          </div>

          <h2 className="mb-5 text-2xl md:text-3xl font-bold">
            Countable Nouns
          </h2>

          <p className="mb-5">
            Countable nouns are nouns that we can count as one, two, three, etc.
            We can use "a/an" before singular countable nouns, and we can make
            them plural.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">an apple</p>
            <p className="pb-2">three books</p>
            <p className="pb-2">some cars</p>
          </div>

          <h2 className="mb-5 text-2xl md:text-3xl font-bold">
            Uncountable Nouns
          </h2>

          <p className="mb-5">
            Uncountable nouns are nouns that we cannot count as one, two, three,
            etc. They are usually things like liquids, substances, or ideas. We
            do not use "a" or "an" before uncountable nouns, and we do not make
            them plural.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">water</p>
            <p className="pb-2">sugar</p>
            <p className="pb-2">information</p>
          </div>

          <p className="mb-5">
            The table below shows the differences between countable and
            uncountable nouns to help you understand them better.
          </p>

          <div className="mx-auto w-max mb-9 text-base md:text-2xl">
            <table className="table-auto border-separate border border-slate-300 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-300 px-2">Countable</th>
                  <th className="border border-slate-300 px-2">Uncountable</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">an</span> apple
                  </td>
                  <td className="border border-slate-300 px-2">
                    <span className="line-through text-red-600">an</span>{" "}
                    information
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    apple<span className=" text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    information
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">three</span> apple
                    <span className=" text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    <span className="line-through text-red-600">three</span>{" "}
                    information
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">some</span> apple
                    <span className=" text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">some</span> information
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">two kilos of</span> apple
                    <span className=" text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">two kilos of</span> sugar
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">two bags of</span> apple
                    <span className="text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    two bottles of water
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Some nouns can be countable or uncountable depending on the
              context, but their meaning changes.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold mb-4">Examples:</p>{" "}
            <p className="mb-4">
              I ate some chicken for lunch.{" "}
              <span className="not-italic font-extralight">
                ("Chicken" is uncountable in this sentence. It refers to the
                meat that we eat.)
              </span>
            </p>
            <p className="pb-2">
              There are three chickens in the yard.{" "}
              <span className="not-italic font-extralight">
                ("Chicken" is countable in this sentence. It refers to the
                animal.)
              </span>
            </p>
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPICS</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/someAny">
                There Is / Are, Some / Any{" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Uncountable Nouns <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              اسم های غیر قابل شمارش
            </span>
          </h1>

          <p lang="fa" dir="rtl" className="mb-5">
            در زبان انگلیسی می شود اسم ها را به دو گروه زیر دسته بندی کرد:
          </p>

          <div lang="fa" dir="rtl" className="flex mr-10">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span>
              اسم های قابل شمارش (<span className="font-bold">[C]</span>)
            </span>
          </div>
          <div lang="fa" dir="rtl" className="flex mr-10 mb-5">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span>
              اسم های غیر قابل شمارش (<span className="font-bold">[U]</span>)
            </span>
          </div>

          <h2
            lang="fa"
            dir="rtl"
            className="mb-5 text-2xl md:text-3xl font-bold"
          >
            اسم های قابل شمارش
          </h2>

          <p lang="fa" dir="rtl" className="mb-5">
            اسم هایی که می توانیم با اعداد (three / two / one ...) بشماریم شان،
            قابل شمارش هستند. این اسم ها قابلیت این را دارند که جمع بسته شوند و
            در حالت مفرد قبل از آنها an/a می گذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">an apple</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک سیب
            </p>
            <p className="pb-1">three books</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              سه کتاب
            </p>
            <p className="pb-1">some cars</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              چند ماشین
            </p>
          </div>

          <h2
            lang="fa"
            dir="rtl"
            className="mb-5 text-2xl md:text-3xl font-bold"
          >
            اسم های غیر قابل شمارش
          </h2>

          <p lang="fa" dir="rtl" className="mb-5">
            اسم هایی که نمی توانیم با اعداد (three / two / one ...) بشماریم شان،
            مانند اسامی مایعات، مواد و مفاهیم انتزاعی، غیر قابل شمارش هستند. این
            اسم ها در زبان انگلیسی جمع بسته نمی شوند. همچنین نمی توانیم قبل از
            آنها an/a بگذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">water</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آب
            </p>
            <p className="pb-1">sugar</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              شکر
            </p>
            <p className="pb-1">information</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              اطلاعات
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            جدول زیر می تواند کمک کند تفاوت های اسامی قابل شمارش و غیر قابل
            شمارش را بهتر درک کنیم.
          </p>

          <div className="mx-auto w-max mb-9 text-base md:text-2xl">
            <table className="table-auto border-separate border border-slate-300 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-300 px-2">Countable</th>
                  <th className="border border-slate-300 px-2">Uncountable</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">an</span> apple
                  </td>
                  <td className="border border-slate-300 px-2">
                    <span className="line-through text-red-600">an</span>{" "}
                    information
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    apple<span className=" text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    information
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">three</span> apple
                    <span className=" text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    <span className="line-through text-red-600">three</span>{" "}
                    information
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">some</span> apple
                    <span className=" text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">some</span> information
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">two kilos of</span> apple
                    <span className=" text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">two kilos of</span> sugar
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">
                    <span className="text-green-600">two bags of</span> apple
                    <span className="text-green-600">s</span>
                  </td>
                  <td className="border border-slate-300 px-2">
                    two bottles of water
                    <span className="line-through text-red-600">s</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pr-3">
              نکته قابل توجه اینکه برخی از اسم ها بسته به اینکه در چه بستر
              معنایی ای استفاده شوند، می توانند قابل شمارش یا غیر قابل شمارش
              باشند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold mb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">I ate some chicken for lunch.</p>
            <p lang="fa" dir="rtl" className="not-italic mb-4">
              ناهار مرغ خوردم.{" "}
              <span className="font-extralight">
                (کلمه chicken اینجا به گوشت مرغ اشاره می کند که به عنوان غذا
                خورده شده است و در این بستر معنایی غیر قابل شمارش است.)
              </span>
            </p>
            <p className="pb-2">There are three chickens in the yard.</p>
            <p lang="fa" dir="rtl" className="not-italic">
              سه تا مرغ تو حیاط هستند.{" "}
              <span className="font-extralight">
                (کلمه chicken اینجا به یک حیوان زنده اشاره می کند و در این بستر
                معنایی قابل شمارش است.)
              </span>
            </p>
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPICS</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/someAny">
                There Is / Are, Some / Any{" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
