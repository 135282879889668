import { Routes, Route } from "react-router-dom";

import { PreIntermediateGrammar } from "../pages";

export const PreIntermediateGrammarRoutes = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<PreIntermediateGrammar title="A2 - B1 Grammar" />}
      />
    </Routes>
  );
};
