import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LinkImg from "../../../assets/images/link.svg";

export const ThereWasWere = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span className="mb-2">
      Complete the sentences with "there was / were" + "a / an", "some" or
      "any".
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>I didn't buy anything because ____ money in my wallet.</span>
      ),
      answer: (
        <span>
          I didn't buy anything because{" "}
          <span className="font-bold">there wasn't any</span> money in my
          wallet.
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>We couldn't find a seat because ____ chairs in the cafe.</span>
      ),
      answer: (
        <span>
          We couldn't find a seat because{" "}
          <span className="font-bold">there weren't any</span> chairs in the
          cafe.
        </span>
      ),
    },
    {
      id: 3,
      question: <span>____ interesting movie on TV last night?</span>,
      answer: (
        <span>
          <span className="font-bold">Was there an</span> interesting movie on
          TV last night?
        </span>
      ),
    },
    {
      id: 4,
      question: <span>We got lost because ____ map in the car.</span>,
      answer: (
        <span>
          We got lost because <span className="font-bold">there wasn't a</span>{" "}
          map in the car.
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>____ pens on the desk yesterday, but I can't find them now.</span>
      ),
      answer: (
        <span>
          <span className="font-bold">There were some</span> pens on the desk
          yesterday, but I can't find them now.
        </span>
      ),
    },
    {
      id: 6,
      question: <span>____ lifeguard at the pool when you went swimming?</span>,
      answer: (
        <span>
          <span className="font-bold">Was there a</span> lifeguard at the pool
          when you went swimming?
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">There Was / Were</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            There Was / Were
          </h1>
          <p className="mb-9">
            We use "there was" and "there were" to say that something or
            somebody existed in the <span className="underline">past</span>.
          </p>

          <p className="mb-4">
            We use "there was" for one thing or one person.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">There was a book on the table.</p>
            <p className="pb-1">There was someone at the door.</p>
          </div>

          <p className="mb-4">
            We use "there are" for more than one thing or person.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">There were three books on the shelf.</p>
            <p className="pb-1">There were some people in the yard.</p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              When we talk about a list of things or people, if the first item
              is singular, we use "there was". If the first itme is plural, we
              use "there were".
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">
              There <span className="font-bold">was</span>{" "}
              <span className="underline">a book</span>, two pens, and a
              notebook on the desk.
            </p>
            <p className="pb-1">
              There <span className="font-bold">were</span>{" "}
              <span className="underline">two pens</span>, a book, and a
              notebook on the desk.
            </p>
          </div>

          <p className="mb-2">
            We often use "there was / were" with "a / an", "some" or "any".
          </p>

          <div className="flex mb-3">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              We use "a" or "an" before{" "}
              <span className="font-bold">countable singular nouns</span> in{" "}
              <mark>positive</mark> and <mark>negative</mark> sentences, and{" "}
              <mark>questions</mark>.
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              <span className="font-bold mr-8">+</span>
              <span>There was a cat in the yard.</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">-</span>
              <span>There wasn't a cat in the yard.</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">?</span>
              <span>Was there a cat in the yard?</span>
            </p>
          </div>

          <div className="flex mb-3">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              We use "some" before{" "}
              <span className="font-bold">countable plural</span> or{" "}
              <span className="font-bold">uncountable nouns</span> in{" "}
              <mark>positive</mark> sentences:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              <span className="font-bold mr-8">+</span>
              <span>There were some apples in the basket.</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">+</span>
              <span>There was some water in the glass.</span>
            </p>
          </div>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              We use "any" before{" "}
              <span className="font-bold">countable plural</span> and{" "}
              <span className="font-bold">uncountable nouns</span> in{" "}
              <mark>negative</mark> sentences and <mark>questions</mark>:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              <span className="font-bold mr-8">-</span>
              <span>There weren't any apples in the basket.</span>
            </p>
            <p className="mb-10">
              <span className="font-bold mr-8">?</span>
              <span>Were there any apples in the basket?</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">-</span>
              <span>There wasn't any water in the glass.</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">?</span>
              <span>Was there any water in the glass?</span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              "There was" and "it was" are different. We use "there was" to say
              that something existed or to talk about the location of something,
              but we use "it was" to describe or identify something specific.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-20">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">There was a phone on the desk. It was black.</p>
            <p className="pb-1">
              There was a dog in the garden. It was barking.
            </p>
          </div>
          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPICS</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/someAny">
                There Is / Are, Some / Any{" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            There Was / Were
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            از ساختار های there was و there were استفاده می کنیم تا بگوییم کسی
            یا چیزی در زمان <span className="underline">گذشته</span> وجود داشته
            است.
          </p>

          <p lang="fa" dir="rtl" className="mb-4">
            وقتی راجع به یک نفر یا یک چیز صحبت می کنیم از there was استفاده می
            کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">There was a book on the table.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک کتاب روی میز بود.
            </p>
            <p className="pb-1">There was someone at the door.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک نفر / کسی دم در بود.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-4">
            وقتی راجع به دو شخص یا دو چیز، یا تعداد بیشتری از افراد یا چیز ها
            صحبت می کنیم، از ساختار there were استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">There were three books on the shelf.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              سه عدد کتاب روی طاقچه / قفسه بود.
            </p>
            <p className="pb-1">There were some people in the yard.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              چند نفر توی حیاط بودند.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              وقتی درباره لیستی از افراد یا چیز ها صحبت می کنیم، اگر اولین شخص
              یا چیز در آن لیست مفرد باشد از there was استفاده می کنیم. اما اگر
              اولین چیز یا اولین فرد در لیست جمع باشد از there were استفاده می
              کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              There <span className="font-bold">was</span>{" "}
              <span className="underline">a book</span>, two pens, and a
              notebook on the desk.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک کتاب، دو خودکار و یک دفترچه روی میز بود.
            </p>
            <p className="pb-1">
              There <span className="font-bold">were</span>{" "}
              <span className="underline">two pens</span>, a book, and a
              notebook on the desk.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              دو خودکار، یک کتاب و یک دفترچه روی میز بود.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-2">
            ساختار "there was / were" معمولا با "some" ،"a / an" یا "any"
            استفاده می شود.
          </p>

          <div lang="fa" dir="rtl" className="flex mb-1">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              قبل از{" "}
              <span className="text-pink-700">اسامی قابل شمارش مفرد</span> در
              جملات <mark>مثبت</mark>، <mark>منفی</mark> و <mark>سوالی</mark> از
              a / an استفاده می کنیم.
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              <span className="font-bold mr-8">+</span>
              <span>There was a cat in the yard.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک گربه تو حیاط بود.
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">-</span>
              <span>There wasn't a cat in the yard.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              گربه ای تو حیاط نبود.
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">?</span>
              <span>Was there a cat in the yard?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آیا یک گربه تو حیاط بود؟
            </p>
          </div>

          <div lang="fa" dir="rtl" className="flex mb-1">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              قبل از <span className="text-pink-700">اسامی قابل شمارش جمع</span>{" "}
              و <span className="text-pink-700">اسامی غیر قابل شمارش</span> در
              جملات <mark>مثبت</mark> از some استفاده می کنیم.
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              <span className="font-bold mr-8">+</span>
              <span>There were some apples in the basket.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              چند تا سیب تو سبد بود.
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">+</span>
              <span>There was some water in the glass.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک مقدار آب تو لیوان بود.
            </p>
          </div>

          <div lang="fa" dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              قبل از <span className="text-pink-700">اسامی قابل شمارش جمع</span>{" "}
              و <span className="text-pink-700">اسم های غیر قابل شمارش</span> در
              جملات <mark>منفی</mark> و <mark>سوالی</mark> از any استفاده می
              کنیم.
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">
              <span className="font-bold mr-8">-</span>
              <span>There weren't any apples in the basket.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              هیچ سیبی تو سبد نبود.
            </p>
            <p className="mb-1">
              <span className="font-bold mr-8">?</span>
              <span>Were there any apples in the basket?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-10 not-italic">
              آیا تو سبد سیب بود؟
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">-</span>
              <span>There wasn't any water in the glass.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              تو لیوان آب نبود.
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">?</span>
              <span>Was there any water in the glass?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آیا تو لیوان آب بود؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              حواسمان باشد there was و it was متفاوتند. از ساختار there was
              استفاده می کنیم تا بگوییم کسی یا چیزی وجود داشته است، یا مکان کسی
              یا چیزی کجا بوده است. اما از it was استفاده می کنیم تا چیزی را
              توصیف کنیم و بگوییم چه بوده است یا ماهیتش چگونه بوده است.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-20">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">There was a phone on the desk. It was black.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک تلفن روی میز بود. آن تلفن سیاه بود.
            </p>
            <p className="pb-1">
              There was a dog in the garden. It was barking.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک سگ در باغ بود. آن سگ داشت پارس می کرد.
            </p>
          </div>

          <div className="mb-8">
            <p lang="fa" dir="rtl" className="font-bold mb-1">
              موضوعات مرتبط
            </p>
            <p dir="rtl" className="text-fuchsia-900">
              <img className="w-5 h-5 inline ml-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/someAny">
                There Is / Are, Some / Any{" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
