import { useState } from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  const [expanded01, setExpanded01] = useState(false);
  const [expanded02, setExpanded02] = useState(false);

  return (
    <footer className="bg-pink-100 pt-5">
      <div className="mx-auto w-full max-w-screen-xl">
        <div>
          <h2 className="ml-5 text-indigo-950 text-2xl font-semibold tracking-widest mb-2">
            External Resources
          </h2>
          <div className="grid grid-cols-2 gap-8 mx-5 py-6 lg:py-8 md:grid-cols-3 max-md:hidden">
            <div>
              <h3 className="mb-6 text-lg font-medium text-indigo-950 tracking-wide uppercase">
                IELTS
              </h3>
              <ul className="text-gray-500 font-medium">
                <li className="mb-4">
                  <a
                    href="https://ielts.org/"
                    target="_blank"
                    rel="noreferrer"
                    className=" hover:underline"
                  >
                    ielts.org
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://www.cambridgeenglish.org/exams-and-tests/ielts/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    cambridgeenglish.org
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://ielts.idp.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    ielts.idp.com
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://takeielts.britishcouncil.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    takeielts.britishcouncil.org
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="mb-6 text-lg font-medium text-indigo-950 tracking-wide uppercase dark:text-white">
                Dictionaries
              </h3>
              <ul className="text-gray-500 font-medium">
                <li className="mb-4">
                  <a
                    href="https://www.oxfordlearnersdictionaries.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    Oxford Advanced Learner's Dictionary
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://www.ldoceonline.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    Longman Dictionary of Contemporary English
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://dictionary.cambridge.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    Cambridge Dictionary
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://www.merriam-webster.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    Merriam-Webster Dictionary
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* --------------- Small-screen Sitemap --------------- */}
          <div className="md:hidden mx-5">
            <div className="border-b border-gray-400">
              <div
                onClick={() => setExpanded01(!expanded01)}
                className="flex flex-row justify-between mt-8 mb-5 cursor-pointer"
              >
                <h2 className="text-xl font-medium text-indigo-950 tracking-wide uppercase">
                  IELTS
                </h2>
                <span
                  className={`transition-transform duration-700 text-indigo-950 font-medium self-center ${
                    expanded01 ? "rotate-90" : ""
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </span>
              </div>
              <ul
                className={`transition-all duration-700 ${
                  expanded01
                    ? "max-h-screen"
                    : "overflow-hidden max-h-0 opacity-0"
                }`}
              >
                <li className="mb-2">
                  <a
                    href="https://ielts.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 hover:underline"
                  >
                    ielts.org
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://www.cambridgeenglish.org/exams-and-tests/ielts/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 hover:underline"
                  >
                    cambridgeenglish.org
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://ielts.idp.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 hover:underline"
                  >
                    ielts.idp.com
                  </a>
                </li>
                <li className="mb-5">
                  <a
                    href="https://takeielts.britishcouncil.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 hover:underline"
                  >
                    takeielts.britishcouncil.org
                  </a>
                </li>
              </ul>
            </div>
            <div className="border-b border-gray-400">
              <div
                onClick={() => setExpanded02(!expanded02)}
                className="flex flex-row justify-between my-5 cursor-pointer"
              >
                <h2 className="text-xl font-medium text-indigo-950 tracking-wide uppercase">
                  Dictionaries
                </h2>
                <span
                  className={`transition-all duration-700 text-indigo-950 self-center ${
                    expanded02 ? "rotate-90" : ""
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </span>
              </div>
              <ul
                className={`transition-all duration-700 ${
                  expanded02
                    ? "max-h-screen"
                    : "overflow-hidden max-h-0 opacity-0"
                }`}
              >
                <li className="mb-2">
                  <a
                    href="https://www.oxfordlearnersdictionaries.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 hover:underline"
                  >
                    Oxford Advanced Learner's Dictionary
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://www.ldoceonline.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 hover:underline"
                  >
                    Longman Dictionary of Contemporary English
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://dictionary.cambridge.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 hover:underline"
                  >
                    Cambridge Dictionary
                  </a>
                </li>
                <li className="mb-5">
                  <a
                    href="https://www.merriam-webster.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 hover:underline"
                  >
                    Merriam-Webster Dictionary
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* --------------- Footer --------------- */}
        <div className="px-4 py-4 bg-fuchsia-950 md:flex md:items-center md:justify-between">
          <span className="text-sm text-white  sm:text-center">
            © 2024 <Link to="/">English Hub Online™</Link>. All Rights Reserved.
          </span>
          <div className="flex mt-4 justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
            <Link
              to="/about"
              className="text-sm text-white hover:text-pink-600"
            >
              About
            </Link>
            <Link
              to="/contact"
              className="text-sm text-white hover:text-pink-600"
            >
              Contact
            </Link>
            <a
              href="https://www.instagram.com/englishhubonline?igsh=MWR3Yjk0d3NqNXV3bw%3D%3D&utm_source=qr"
              className="text-white hover:text-pink-600"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Instagram account</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
