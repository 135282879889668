import { Link } from "react-router-dom";
import { useTitle } from "../../../components";

export const WorkVocabulary = ({ title }) => {
  useTitle(title);

  return (
    <>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link to="/Ielts" className="duration-300 hover:text-indigo-950">
          <span>IELTS</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link
          to="/ielts/topicalVocabulary"
          className="duration-300 hover:text-indigo-950"
        >
          <span>Topical Vocab</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">Work</span>
      </nav>

      {/* ---------- Body ---------- */}
      <div
        className="my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <h1 className="flex flex-col drop-shadow text-center mt-10 mb-14">
          <span className="text-pink-700 mb-5 text-3xl sm:text-4xl">
            IELTS Topical Vocabulary
          </span>

          <span className="font-bold text-4xl sm:text-5xl">Work</span>
        </h1>

        <p className="mb-10 leading-normal">
          The expressions printed in <span className="font-bold">bold</span>{" "}
          within the paragraphs below can enrich your vocabulary range when
          you're writing or speaking about topics related to work.
        </p>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            <span className="font-bold">Job satisfaction</span> is crucial for{" "}
            <span className="font-bold">
              maintaining a healthy work environment
            </span>
            . When employees are not satisfied with their work, it can lead to
            increased <span className="font-bold">sick leave</span> and
            decreased productivity. It is important for employers to provide
            opportunities for their employees to{" "}
            <span className="font-bold">take time off</span> and{" "}
            <span className="font-bold">recharge</span>, so they can{" "}
            <span className="font-bold">hit the ground running</span> when they
            return to work. <span className="font-bold">Working stiffs</span>{" "}
            who feel appreciated and supported by their employers are more
            likely to be productive, innovative, and
            <span className="font-bold"> committed to their jobs</span>.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">job satisfaction</span>:
          </div>
          <div>
            the degree of contentment or fulfillment that an employee
            experiences with their job
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">sick leave</span>:
          </div>
          <div>
            the time off from work that is allowed for illness or injury
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">take time off</span>:
          </div>
          <div>to take a break from work, often for rest or vacation</div>
        </div>

        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">recharge</span> (v):
          </div>
          <div>
            resting or taking time off from work to recover and re-energize
            physically and mentally
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">hit the ground running</span>:
          </div>
          <div>to start working quickly and efficiently from the beginning</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">working stiff</span>:
          </div>
          <div>
            a regular employee who is not in a managerial position and typically
            has a routine job
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">committed to your job</span>:
          </div>
          <div>showing dedication or loyalty to your job</div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            <span className="font-bold">
              Maintaining a healthy work-life balance
            </span>{" "}
            is key to preventing
            <span className="font-bold"> burnout</span>. Working{" "}
            <span className="font-bold">a nine-to-five job</span> can be{" "}
            <span className="font-bold">demanding</span>, and the pressure to{" "}
            <span className="font-bold">meet deadlines</span> may lead to long
            hours of <span className="font-bold">burning the midnight oil</span>
            . While it may be tempting to work without breaks, it is important
            to prioritize self-care and avoid{" "}
            <span className="font-bold">burning yourself out</span> in order to
            get a slightly more lucrative{" "}
            <span className="font-bold">pay slip</span> that is, well, probably
            not worth the stress and exhaustion.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">burnout</span> (n):
          </div>
          <div>
            Physical, mental, and emotional exhaustion due to prolonged stress
            or overwork
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">a nine-to-five job</span> (n):
          </div>
          <div>
            a traditional work schedule that typically includes five days of
            eight hours each, starting at 9 a.m. and ending at 5 p.m.
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">demanding</span> (adj):
          </div>
          <div>requiring a lot of time, energy, or effort</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">meet deadlines</span>:
          </div>
          <div>complete tasks or projects by the predetermined due date</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">burning the midnight oil</span>:
          </div>
          <div>working into the late hours of the night</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">burn yourself out</span> (v):
          </div>
          <div>
            overwork or exhaust yourself to the point of being unable to
            continue at the same level of performance
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">pay slip</span> (n):
          </div>
          <div>
            a document given to an employee that shows the earnings and
            deductions from a paycheck
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            When considering your <span className="font-bold">career path</span>
            , it's important to think about how you will{" "}
            <span className="font-bold">make a living</span>. You may find
            yourself reaching out to{" "}
            <span className="font-bold">head hunters</span> for job
            opportunities, or putting in the effort to{" "}
            <span className="font-bold">pull your weight</span> in your current
            position. If you're feeling unsatisfied or have better{" "}
            <span className="font-bold">job prospects</span> on the horizon, it
            may be time to <span className="font-bold">resign</span>. No matter
            what direction you choose, it's important to remain positive and
            focused on achieving your{" "}
            <span className="font-bold">career goals</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">career path</span>:
          </div>
          <div>
            the sequence of positions or jobs that a person progresses through
            in their work life
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">make a living</span>:
          </div>
          <div>to earn enough money to cover one's basic needs</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">head hunter</span>:
          </div>
          <div>
            a person who searches for skilled individuals to fill job vacancies
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">pull your weight</span>:
          </div>
          <div>to do your fair share of work or contribute fully to a team</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">job prospects</span>:
          </div>
          <div>
            the likelihood of being hired for a particular position or job
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            Whether you are{" "}
            <span className="font-bold">climbing the career ladder</span> or{" "}
            <span className="font-bold">out-of-work</span>, it is important to
            always <span className="font-bold">go above and beyond</span> in
            your efforts. Putting in a full day's work,{" "}
            <span className="font-bold">going the extra mile</span>, and even
            doing tasks that are{" "}
            <span className="font-bold">all in a day's work</span> can help you
            stand out from the competition. If you demonstrate a positive
            attitude and a strong
            <span className="font-bold"> work ethic</span>, you may even be
            rewarded with a <span className="font-bold">bonus</span> or a
            positive notice from your superiors.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">climb the career ladder</span>:
          </div>
          <div>
            move up in an organization by taking on increasingly higher-level
            positions
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">out-of-work</span> (adj):
          </div>
          <div>unemployed or not currently employed</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">(go) above and beyond (sth)</span>{" "}
            (idiom):
          </div>
          <div>to exceed expectations or to do beyond what is required</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">go the extra mile (for sb/sth)</span>{" "}
            (idiom):
          </div>
          <div>
            go/do beyond what is expected in order to achieve a desired outcome
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">all in a day's work</span> (idiom):
          </div>
          <div>
            something that is expected as part of the job, regardless of its
            difficulty
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">work ethic</span> (n):
          </div>
          <div>
            the principles that guide a person's approach to work, including
            their attitude, commitment, and determination
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">bonus</span> (n):
          </div>
          <div>
            an extra amount of money or reward given to an employee in addition
            to their regular salary
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            In today’s job market, many people are seeking{" "}
            <span className="font-bold">job security</span>, but some are
            willing to trade that for{" "}
            <span className="font-bold">flexible working hours</span> or even
            flexible employment. Others are exploring alternatives, like
            <span className="font-bold"> apprenticeships</span> or{" "}
            <span className="font-bold">doing voluntary work</span>, to{" "}
            <span className="font-bold">gain experience</span> or make a
            difference in their community. Some may find themselves{" "}
            <span className="font-bold">between jobs </span>
            or considering{" "}
            <span className="font-bold">taking early retirement</span>, in which
            case, they might consult with a{" "}
            <span className="font-bold">head hunter</span> to explore new
            opportunities.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">apprenticeship</span> (n):
          </div>
          <div>
            a system of training for a trade or profession that involves
            on-the-job training and instruction
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">do voluntary work</span> (v):
          </div>
          <div>
            to perform work or services for a cause or organization without
            expecting payment
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">between jobs</span> (idiom):
          </div>
          <div>a period of time between periods of employment</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">take early retirement</span> (v):
          </div>
          <div>
            to retire from a job earlier than the standard retirement age,
            usually at or before the age of 65
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            For many people, the path to success requires{" "}
            <span className="font-bold">commitment</span> and{" "}
            <span className="font-bold">hard work</span>, even if that means ‘
            <span className="font-bold">no pain, no gain</span>.’{" "}
            <span className="font-bold">Getting your foot in the door</span>{" "}
            with a company might mean starting with{" "}
            <span className="font-bold">entry-level positions</span>, but the
            key to advancement is to{" "}
            <span className="font-bold">put your best foot forward</span>,
            whether you are <span className="font-bold">clocking in</span> at an
            office or <span className="font-bold">working from home</span>.
            <span className="font-bold"> Pulling a sickie</span> when you are
            not really sick might sound appealing, but real growth and
            recognition come from hard work and dedication.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">commitment</span> (n):
          </div>
          <div>
            the willingness and dedication of an employee to work hard and do
            their best to contribute to the success of the company or
            organization
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">no pain, no gain</span> (idiom):
          </div>
          <div>
            the idea that achieving success or improvement often requires hard
            work, sacrifice, or effort
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">get/have a/your foot in the door</span>{" "}
            (idiom):
          </div>
          <div>
            to get a job, especially an entry-level one, that allows you to
            begin a career
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">put your best foot forward</span>{" "}
            (idiom):
          </div>
          <div>To try your hardest and give your best effort at something</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">clock in</span> (v):
          </div>
          <div>
            to start working, typically by recording the time at which you
            arrive at work
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">work from home</span> (v):
          </div>
          <div>
            to work remotely, using technology to perform one’s job tasks from
            home or another location outside of an office
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">pull a sickie</span> (v):
          </div>
          <div>to call in sick to work when you’re not actually sick</div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            Whether you are{" "}
            <span className="font-bold">learning the ropes</span> of a new
            position or <span className="font-bold">working overtime</span> to{" "}
            <span className="font-bold">meet a deadline</span>, it is important
            to find a healthy balance between work and life. Being{" "}
            <span className="font-bold">overworked</span> can lead to{" "}
            <span className="font-bold">burnout</span>, even if you are{" "}
            <span className="font-bold">working flexitime</span> to fit your
            schedule.{" "}
            <span className="font-bold">Working your fingers to the bone</span>{" "}
            might bring short-term rewards, but in the long run, it can be more
            detrimental than beneficial. When it is time to{" "}
            <span className="font-bold">clock out</span>, make sure you are
            leaving work behind and not bringing it home with you.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">learn the ropes</span> (idiom):
          </div>
          <div>
            to become familiar with a job, task, or skill through experience or
            instruction
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">do / work overtime</span> (v):
          </div>
          <div>
            to work beyond the typical work hours or to put in extra hours of
            work
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">work flexitime</span> (v):
          </div>
          <div>
            to work hours that are flexible or adaptable to individual needs and
            preferences
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">work your fingers to the bone</span>{" "}
            (idiom):
          </div>
          <div>to work extremely hard.</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">clock out</span> (v):
          </div>
          <div>
            to stop working, typically by recording the time at which you leave
            work
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            <span className="font-bold">Being made redundant</span> can be
            disheartening, especially if you have
            <span className="font-bold"> worked like a Trojan</span> for a
            company. However, it is important to keep a positive outlook and use
            it as an opportunity to reassess your
            <span className="font-bold"> career goals</span> and priorities.
            Consider the company’s{" "}
            <span className="font-bold">corporate culture</span> when looking
            for a new job; while some employers might value traditional work
            styles, others might encourage employees to{" "}
            <span className="font-bold">think out of the box</span> and{" "}
            <span className="font-bold">take on new challenges</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">be made redundant</span> (v):
          </div>
          <div>
            to lose one's job because the position has been eliminated or the
            company has reduced its workforce
          </div>
        </div>

        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">work like a Trojan</span> (idiom):
          </div>
          <div>to work very hard, almost to the point of exhaustion</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">corporate culture</span> (n):
          </div>
          <div>
            the values, attitudes, and behaviors of an organization, which
            influence how work is done and how people interact with each other
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">think out of the box</span> (idiom):
          </div>
          <div>
            to approach a problem in an unconventional way, often by considering
            ideas or solutions that are not immediately apparent or obvious
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            Are you <span className="font-bold">underpaid</span> in your current
            role? Consider applying for a new{" "}
            <span className="font-bold">vacancy</span> in a different company,
            but be sure to review the <span className="font-bold">perks</span>{" "}
            and benefits on offer. If you find that the salary is not what you
            expected, or the <span className="font-bold">workload</span> is too
            much, you might have to{" "}
            <span className="font-bold">go back to the drawing board</span>.
            However, with <span className="font-bold">maternity leave</span> or
            other benefits that come{" "}
            <span className="font-bold">on the job</span>, you could{" "}
            <span className="font-bold">bring home the bacon </span>
            and still enjoy a fulfilling{" "}
            <span className="font-bold">work-life balance</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">underpaid</span> (adj):
          </div>
          <div>
            receiving a salary that is lower than the average for a particular
            job or industry
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">vacancy</span> (n):
          </div>
          <div>
            an open position or job that is available for people to apply for
          </div>
        </div>

        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">perks</span> (n):
          </div>
          <div>
            additional benefits or privileges that are offered to employees,
            such as health insurance, vacation time, or gym membership
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">workload</span> (n):
          </div>
          <div>
            the amount of work that an individual or team is responsible for
            completing
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">(go) back to the drawing board</span>{" "}
            (idiom):
          </div>
          <div>
            to start over or reconsider a plan or idea after it has been
            rejected or shown to be ineffective
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">maternity leave</span> (n):
          </div>
          <div>
            a period of time when a woman can take a break from work to recover
            from childbirth and care for her baby without losing her job
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">on the job</span> (n):
          </div>
          <div>while working or performing work-related tasks</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">bring home the bacon</span> (idiom):
          </div>
          <div>
            to earn a good salary and provide financial support for one’s family
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            In the pursuit of a fulfilling career, you might encounter the
            challenge of <span className="font-bold">downsizing</span> or a{" "}
            <span className="font-bold">toxic workplace atmosphere</span>.
            Remember that your <span className="font-bold">calling</span> is
            more than just a <span className="font-bold">desk job</span>; it is
            a career that aligns with your values and passions. Even if you find
            yourself in a tough situation,{" "}
            <span className="font-bold">keep your nose to the grindstone</span>{" "}
            and do not let it deter you from{" "}
            <span className="font-bold">landing a job</span> that will help you
            thrive. Be wary of becoming a{" "}
            <span className="font-bold">workaholic</span>, but do not lose sight
            of your goals either.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">downsizing</span>:
          </div>
          <div>
            the process of reducing the number of employees or workforce in a
            company, usually by eliminating jobs or departments
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">toxic work atmoshphere</span>:
          </div>
          <div>
            an unhealthy or hostile work environment that can negatively impact
            employees’ mental and physical health, productivity, and morale
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">desk job</span> (n):
          </div>
          <div>
            a job that primarily involves sitting at a desk or workstation, such
            as an office job
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">keep your nose to the grindstone</span>{" "}
            (idiom):
          </div>
          <div>
            a phrase that means to work hard and continuously in order to
            achieve a goal
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">land a job</span> (v):
          </div>
          <div>
            to obtain or secure employment, often after a period of searching or
            applying for jobs
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">workaholic</span> (v):
          </div>
          <div>
            a person who is obsessed with work and works excessively, often to
            the detriment of their personal life or well-being
          </div>
        </div>
      </div>
    </>
  );
};
