import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";

export const ExpressionsOfFrequencyElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = <span>Put the words in the right order.</span>;

  const questions = [
    {
      id: 1,
      question: <span>friends / meet / week / a / they / once / their.</span>,
      answer: <span>They meet their friends once a week.</span>,
    },
    {
      id: 2,
      question: <span>a / three / does / she / times / week / yoga.</span>,
      answer: <span>She does yoga three times a week.</span>,
    },
    {
      id: 3,
      question: <span>night / English / we / every / study.</span>,
      answer: <span>We study English every night.</span>,
    },
    {
      id: 4,
      question: (
        <span>
          three / she / hairdresser's / every / months / goes / the / to.
        </span>
      ),
      answer: <span>She goes to the hairdresser's every three months.</span>,
    },
    {
      id: 5,
      question: (
        <span>
          my / visit / weekend / other / every / I / grandmother.
        </span>
      ),
      answer: <span>I visit my grandmother every other weekend.</span>,
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Frequency Expressions</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Expressions of Frequency
          </h1>
          <p className="mb-6">
            We use the following and other similar expressions of frequency to
            show how often we do something or how regularly a verb happens.
          </p>

          <div className="mb-12 ml-6 sm:ml-12 font-bold">
            <p>every day / week / month</p>
            <p>every other day / week / month</p>
            <p>once a day / week / month</p>
            <p>twice a day / week / month</p>
            <p>three times a day / week / month</p>
            <p>every three days / weeks / months</p>
            <p>...</p>
          </div>

          <p className="mb-6">
            The place of these adverbials is usually at the end of the sentence.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold mb-5">Examples:</p>
            <p className="mb-3">
              She goes to the gym <span className="underline">every day</span>.
            </p>
            <p className="mb-3">
              We usually go to the movies{" "}
              <span className="underline">three or four times a year</span>.
            </p>
            <p className="mb-3">
              You should take this medicine{" "}
              <span className="underline">every six hours</span>.
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Expressions of Frequency
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            برای نشان دادن اینکه یک فعل چند وقت به چند وقت، یا بر اساس چه ترتیبی
            اتفاق می افتد می توانیم از عبارت های قیدی زیر و ساختار های مشابه
            آنها استفاده کنیم.
          </p>
          <div className="mb-12 ml-6 font-bold">
            <p className="flex flex-col lg:flex-row justify-between">
              <span>every day / week / month</span>
              <span lang="fa" dir="rtl" className="sm:mr-7 max-sm:mb-3">
                هر روز / هفته / ماه
              </span>
            </p>
            <p className="flex flex-col lg:flex-row justify-between">
              <span>every other day / week / month</span>
              <span lang="fa" dir="rtl" className="sm:mr-7 max-sm:mb-3">
                یک روز / هفته / ماه در میان
              </span>
            </p>
            <p className="flex flex-col lg:flex-row justify-between">
              <span>once a day / week / month</span>
              <span lang="fa" dir="rtl" className="sm:mr-7 max-sm:mb-3">
                روزی / هفته ای / ماهی یک بار
              </span>
            </p>
            <p className="flex flex-col lg:flex-row justify-between">
              <span>twice a day / week / month</span>
              <span lang="fa" dir="rtl" className="sm:mr-7 max-sm:mb-3">
                روزی / هفته ای / ماهی دو بار
              </span>
            </p>

            <p className="flex flex-col lg:flex-row justify-between">
              <span>three times a day / week / month</span>
              <span lang="fa" dir="rtl" className="sm:mr-7 max-sm:mb-3">
                روزی / هفته ای / ماهی سه بار
              </span>
            </p>
            <p className="flex flex-col lg:flex-row justify-between">
              <span>every three days / weeks / months</span>
              <span lang="fa" dir="rtl" className="sm:mr-7 max-sm:mb-3">
                سه روز / هفته / ماه یک بار
              </span>
            </p>
            <p>...</p>
          </div>
          <p lang="fa" dir="rtl" className="mb-6">
            جای این عبارت های قیدی معمولا در انتهای جمله است.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold mb-5 not-italic">
              مثال:
            </p>
            <p className="mb-1">
              She goes to the gym <span className="underline">every day</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-3 not-italic">
              او <span className="underline">هر روز</span> می رود باشگاه.
            </p>
            <p className="mb-1">
              We usually go to the movies{" "}
              <span className="underline">three or four times a year</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-3 not-italic">
              ما معمولا <span className="underline">سالی سه چهار دفعه</span> می
              رویم سینما.
            </p>
            <p className="mb-1">
              You should take this medicine{" "}
              <span className="underline">every six hours</span>.
              <p lang="fa" dir="rtl" className="mb-3 not-italic">
                تو باید <span className="underline">هر شش ساعت</span> این دارو
                را مصرف کنی.
              </p>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
