import { Link } from "react-router-dom";
import { useTitle } from "../../../components";

export const EnvironmentVocabulary = ({ title }) => {
  useTitle(title);

  return (
    <>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link to="/Ielts" className="duration-300 hover:text-indigo-950">
          <span>IELTS</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link
          to="/ielts/topicalVocabulary"
          className="duration-300 hover:text-indigo-950"
        >
          <span>Topical Vocab</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">Environment</span>
      </nav>

      {/* ---------- Body ---------- */}
      <div
        className="my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <h1 className="flex flex-col drop-shadow text-center mt-10 mb-14">
          <span className="text-pink-700 mb-5 text-3xl sm:text-4xl">
            IELTS Topical Vocabulary
          </span>

          <span className="font-bold text-4xl sm:text-5xl">Environment</span>
        </h1>
        <p className="mb-10 leading-normal">
          The expressions printed in <span className="font-bold">bold</span>{" "}
          within the paragraphs below can enrich your vocabulary range when
          you're writing or speaking about topics related to environment.
        </p>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            The rapid <span className="font-bold">deterioration</span> of our
            <span className="font-bold"> planet</span>'s environment has led to
            the
            <span className="font-bold"> destruction</span> of numerous{" "}
            <span className="font-bold">habitats</span>,{" "}
            <span className="font-bold">endangering</span> countless{" "}
            <span className="font-bold">species</span> that struggle to survive
            amidst the chaos. As mankind continues to{" "}
            <span className="font-bold">cut down rainforests</span> and{" "}
            <span className="font-bold">deplete natural resources</span>, the
            consequences are felt across the globe, manifesting in the form of{" "}
            <span className="font-bold">desertification</span>, and{" "}
            <span className="font-bold">causing irreparable damage</span> to our{" "}
            <span className="font-bold">ecosystems</span>. The urgent need for{" "}
            <span className="font-bold">sustainable practices</span> and
            conservation efforts is crucial to
            <span className="font-bold"> mitigate</span> the risk of pushing
            more species towards <span className="font-bold">extinction</span>{" "}
            and to
            <span className="font-bold"> preserve</span> the delicate{" "}
            <span className="font-bold">balance of our environment</span>. Only
            through
            <span className="font-bold"> collective awareness</span> and action
            can we hope to <span className="font-bold">curb</span> this worrying
            trend and ensure a brighter future for our planet.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">deteriration</span> (n):
          </div>
          <div>
            the process of becoming worse or of lowering in quality, value, or
            condition
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">habitat</span> (n):
          </div>
          <div>the natural environment where an organism lives and thrives</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">endanger</span> (v):
          </div>
          <div>
            to put something or someone at risk of harm, loss, or extinction
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">species</span> (n):
          </div>
          <div>
            a group of living organisms that share similar characteristics and
            can interbreed
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">deplete</span> (v):
          </div>
          <div>to reduce or exhaust the supply of a resource</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">desertification</span> (n):
          </div>
          <div>
            the process by which fertile land becomes degraded, dry, and
            ultimately turns into desert
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">irreparable</span> (adj):
          </div>
          <div>impossible to repair or rectify</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">ecosystem</span> (n):
          </div>
          <div>
            a community of living organisms and non-living components that
            interact within a specific environment
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">sustainable</span> (adj):
          </div>
          <div>
            able to be maintained or continued at a certain rate or level
            without exhausting resources
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">mitigate</span> (v):
          </div>
          <div>To make less severe or painful</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">extinction</span> (n):
          </div>
          <div>the complete disappearance of a species</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">collective awareness</span> (n):
          </div>
          <div>
            a shared understanding or knowledge of a particular issue within a
            group or society
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">curb</span> (v):
          </div>
          <div>to restrain or keep something under control</div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            As <span className="font-bold">global warming</span> persists, it
            brings about significant changes to our planet's environment,
            notably evident in{" "}
            <span className="font-bold">rising sea levels</span> that threaten{" "}
            <span className="font-bold">coastal ecosystems</span> and
            communities. The rapid{" "}
            <span className="font-bold">melting of ice sheets</span> in{" "}
            <span className="font-bold">Antarctica</span> and{" "}
            <span className="font-bold">the Arctic Ocean </span>
            further contributes to this{" "}
            <span className="font-bold">alarming phenomenon</span>, causing{" "}
            <span className="font-bold">devastation</span> to the delicate
            <span className="font-bold"> habitats</span> of various{" "}
            <span className="font-bold">wildlife species</span>. In addition to
            these challenges, <span className="font-bold">oil spills</span>{" "}
            continue to <span className="font-bold">pollute</span> our oceans,
            exacerbating the <span className="font-bold">plight</span> of{" "}
            <span className="font-bold">marine life</span>. To{" "}
            <span className="font-bold">combat</span> these{" "}
            <span className="font-bold">pressing issues</span>, it is essential
            for individuals, industries, and governments to join forces and
            implement effective solutions, ensuring the{" "}
            <span className="font-bold">preservation </span>
            of our planet for future generations.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">global warming</span> (n):
          </div>
          <div>
            the gradual increase in the overall temperature of the Earth's
            atmosphere
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">coastal</span> (adj):
          </div>
          <div>
            relating to or situated on the coast, where land meets the sea
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">ice sheet</span> (n):
          </div>
          <div>
            a large, thick mass of glacial ice covering a significant area of
            land
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">Antarctica</span> (n):
          </div>
          <div>
            the fifth-largest continent and the southernmost continent, located
            around the South Pole
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">the Arctic Ocean</span> (n):
          </div>
          <div>
            the smallest and shallowest of the world's five major oceans,
            located around the North Pole
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">an alarming phenomenon</span> (n):
          </div>
          <div>an event or occurrence that is concerning or worrying</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">devastation</span> (n):
          </div>
          <div>severe and widespread destruction or damage</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">wildlife</span> (n):
          </div>
          <div>
            animals, birds, and other living things that exist in a natural
            environment
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">oil spill</span> (n):
          </div>
          <div>
            an accidental release of oil into the environment, usually in water
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">plight</span> (n):
          </div>
          <div>
            a difficult or dangerous situation, often one that is difficult to
            escape or improve
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">marine life</span> (n):
          </div>
          <div>
            plants, animals, and other organisms that live in or depend on the
            ocean
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">combat</span> (v):
          </div>
          <div>
            to take action to reduce or prevent something harmful or undesirable
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">a pressing issue</span> (n):
          </div>
          <div>a problem that needs immediate attention and resolution</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">preservation</span> (n):
          </div>
          <div>
            the act of maintaining or protecting something in its original or
            existing state
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            In recent decades, the impact of{" "}
            <span className="font-bold">climate change</span> has become
            increasingly evident, with the widespread effects of{" "}
            <span className="font-bold">deforestation</span>,
            <span className="font-bold"> melting glaciers</span>, and rising{" "}
            <span className="font-bold">carbon dioxide emissions</span>{" "}
            contributing to a myriad of{" "}
            <span className="font-bold">environmental challenges</span>. The
            loss of <span className="font-bold">forested areas </span>
            not only <span className="font-bold">
              diminishes
            </span> essential <span className="font-bold">habitats</span> for
            countless <span className="font-bold">species</span> but also{" "}
            <span className="font-bold">amplifies</span> the{" "}
            <span className="font-bold">negative consequences</span> of the{" "}
            <span className="font-bold">greenhouse effect</span>. Furthermore,
            as <span className="font-bold">glaciers</span> continue to melt{" "}
            <span className="font-bold">at an alarming rate</span>,
            <span className="font-bold"> rising sea levels pose a threat</span>{" "}
            to <span className="font-bold">coastal ecosystems</span> and
            communities. To <span className="font-bold">combat</span> these{" "}
            <span className="font-bold">pressing issues</span>, it is crucial to
            reduce our reliance on{" "}
            <span className="font-bold">fossil fuels</span> and invest in{" "}
            <span className="font-bold">renewable energy sources</span>, while
            also promoting <span className="font-bold">afforestation</span>{" "}
            efforts to{" "}
            <span className="font-bold">restore Earth's natural balance</span>{" "}
            and <span className="font-bold">mitigate</span> the impacts of{" "}
            <span className="font-bold">climate change</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">deforestation</span> (n):
          </div>
          <div>
            the act of cutting down or burning trees in a forested area,
            typically for agriculture or other purposes
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">melting glaciers</span> (n):
          </div>
          <div>
            the process of glaciers, large masses of ice, shrinking in size due
            to warming temperatures
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">emission</span> (n):
          </div>
          <div>
            the release of something, especially a gas or other substance, into
            the air or environment
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">diminish</span> (v):
          </div>
          <div>to become smaller, weaker, or less important</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">amplify</span> (v):
          </div>
          <div>to increase in size, strength, or significance</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">greenhouse effect</span> (n):
          </div>
          <div>
            the trapping of heat in the Earth's atmosphere due to gases such as
            carbon dioxide, causing global temperatures to rise
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">pose a threat</span> (v):
          </div>
          <div>to be a potential cause of harm, danger, or damage</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">fossil fuels</span> (n):
          </div>
          <div>
            non-renewable energy sources, such as coal, oil, and natural gas,
            formed from the remains of ancient plants and animals
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">renewable energy sources</span> (n):
          </div>
          <div>
            energy sources that can be replenished naturally and sustainably,
            such as solar, wind, or hydroelectric power
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">afforestation</span> (n):
          </div>
          <div className="flex flex-col">
            <span>
              the process of planting new trees in an area to create a forest or
              increase forested land
            </span>
            <span className="font-bold text-pink-700">
              afforest <span className="font-normal">(v)</span>
            </span>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">restore</span> (v):
          </div>
          <div>
            to bring something back to its original condition or improve it to a
            better state
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            The rapid <span className="font-bold">urbanization</span> and{" "}
            <span className="font-bold">overpopulation</span> in many parts of
            the world have led to numerous environmental challenges, including{" "}
            <span className="font-bold">urban sprawl</span>,{" "}
            <span className="font-bold">waste management</span> issues, and
            increased pollution. As cities expand, valuable natural habitats are
            lost, and resources become strained. The release of{" "}
            <span className="font-bold">untreated raw sewage</span> into
            waterways and the improper{" "}
            <span className="font-bold">disposal of waste</span> contribute to
            the pollution of our environment. Additionally, the growing number
            of vehicles on the roads produces harmful{" "}
            <span className="font-bold">exhaust fumes</span>, further{" "}
            <span className="font-bold">exacerbating</span> air quality concerns
            and contributing to the <span className="font-bold">depletion</span>{" "}
            of <span className="font-bold">the ozone layer</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">urbanization</span> (n):
          </div>
          <div>
            the process by which rural areas become more urban, typically
            involving the growth of cities and the development of infrastructure
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">overpopulation</span> (n):
          </div>
          <div>
            a situation in which the population of an area exceeds the available
            resources needed for survival
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">urban sprawl</span> (n):
          </div>
          <div>
            the unplanned and widespread growth of a city or urban area, often
            resulting in the loss of green spaces and increased reliance on cars
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">untreated raw sewage</span> (n):
          </div>
          <div>
            sewage that has not undergone any treatment to remove harmful
            pollutants or contaminants
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">waste disposal</span> (n):
          </div>
          <div>
            the process of managing and eliminating waste materials, including
            recycling, incineration, or landfills
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">exhaust fumes</span> (n):
          </div>
          <div>
            the mixture of gases and particles emitted from the exhaust system
            of vehicles or other machinery, often containing pollutants
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">exacerbate</span> (v):
          </div>
          <div>
            to make a problem, situation, or condition worse or more severe
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">the ozone layer</span> (n):
          </div>
          <div>
            a protective atmospheric layer that shields Earth's inhabitants from
            the sun's harmful ultraviolet radiation
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            The widespread use of chemical{" "}
            <span className="font-bold">pesticides</span> and{" "}
            <span className="font-bold">herbicides</span> in modern
            <span className="font-bold"> agriculture</span> has led to
            significant{" "}
            <span className="font-bold">environmental degradation</span>,{" "}
            <span className="font-bold">posing a threat</span> to the health of{" "}
            <span className="font-bold">ecosystems</span> and{" "}
            <span className="font-bold">biodiversity</span>. In contrast, those
            with a <span className="font-bold">green thumb</span> who embrace{" "}
            <span className="font-bold">organic farming practices </span>
            focus on <span className="font-bold">cultivating</span> a diverse
            array of <span className="font-bold">crops</span> without the use of
            <span className="font-bold"> synthetic chemicals</span>. This
            approach not only promotes healthier{" "}
            <span className="font-bold">soils </span>
            but also helps to reduce the need for{" "}
            <span className="font-bold">genetically modified crops</span>.
            However, as <span className="font-bold">rainfall</span> patterns
            become more unpredictable and{" "}
            <span className="font-bold">arid </span>
            regions face prolonged periods of{" "}
            <span className="font-bold">drought</span>, finding{" "}
            <span className="font-bold">sustainable </span>
            solutions to maintain <span className="font-bold">crop yields</span>
            , prevent <span className="font-bold">famines</span> and ensure food
            security remains a
            <span className="font-bold"> pressing challenge</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">pesticide</span> (n):
          </div>
          <div>
            a chemical substance used to kill or control pests, such as insects,
            rodents, or fungi
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">herbicide</span> (n):
          </div>
          <div>
            a chemical substance used to kill or inhibit the growth of unwanted
            plants or weeds
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">degradation</span> (n):
          </div>
          <div>
            the process of decline or deterioration, often referring to the
            environment or natural resources
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">biodiversity</span> (n):
          </div>
          <div>
            the variety of plant and animal life in a particular habitat or
            environment
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">green thumb / fingers</span> (n):
          </div>
          <div>a natural talent or skill for gardening or growing plants</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">cultivate</span> (v):
          </div>
          <div>to grow crops or plants</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">crop yields</span> (n):
          </div>
          <div>the amount of produce obtained from a crop in a given area</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">arid</span> (adj):
          </div>
          <div>
            describing a region or climate that is very dry, receiving little
            rainfall
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">drought</span> (n):
          </div>
          <div>
            a prolonged period of abnormally low rainfall, leading to a shortage
            of water
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">famine</span> (n):
          </div>
          <div>
            a severe shortage of food, often resulting in widespread hunger,
            malnutrition, and death within a region or population
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            In our <span className="font-bold">throwaway society</span>, the
            accumulation of <span className="font-bold">rubbish</span> in our
            environment has become a{" "}
            <span className="font-bold">pressing issue</span>, particularly when
            it ends up in <span className="font-bold">dumping grounds</span>{" "}
            that
            <span className="font-bold"> contaminate</span> the surrounding{" "}
            <span className="font-bold">ecosystems</span> with{" "}
            <span className="font-bold">toxic</span> substances. A critical
            aspect of <span className="font-bold">addressing</span> this problem
            is promoting <span className="font-bold">waste segregation</span>,
            where individuals separate{" "}
            <span className="font-bold">biodegradable</span> from
            <span className="font-bold"> non-biodegradable</span> materials. By
            doing so, we can help reduce the
            <span className="font-bold"> environmental hazards</span> posed by
            improper <span className="font-bold">waste disposal</span> and
            encourage
            <span className="font-bold"> recycling</span> efforts. Furthermore,
            governments and organizations should
            <span className="font-bold"> incentivize</span> the use of{" "}
            <span className="font-bold">biodegradable</span> products and
            educate people about the importance of reducing{" "}
            <span className="font-bold">litter</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">throwaway society</span> (n):
          </div>
          <div>
            a culture or way of living where products are designed to be used
            once or for a short time, then discarded and replaced
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">dumping ground</span> (n):
          </div>
          <div>
            a site where waste or unwanted materials are left or discarded
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">contaminate</span> (v):
          </div>
          <div>
            to make something impure or harmful by adding substances that are
            poisonous, polluting, or otherwise damaging
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">toxic</span> (adj):
          </div>
          <div>
            poisonous or very harmful to humans, animals, or the environment
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">address a problem</span> (v):
          </div>
          <div>
            to find solutions or ways to deal with a difficulty, issue, or
            concern
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">waste segregation</span> (n):
          </div>
          <div>
            the process of separating different types of waste, such as
            biodegradable and non-biodegradable materials, to facilitate
            recycling and proper disposal
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">biodegradable</span> (adj):
          </div>
          <div>
            describing substances that can be broken down naturally by living
            organisms, such as bacteria or fungi
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">enviromental hazards</span> (n):
          </div>
          <div>
            risks or dangers to the natural environment and the creatures that
            live within it
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">waste disposal</span> (n):
          </div>
          <div>
            the process of managing and eliminating waste materials, such as
            recycling, incineration, or landfills
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">incentivize</span> (v):
          </div>
          <div>
            to provide rewards, benefits, or motivation for someone to do
            something
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">litter</span> (n):
          </div>
          <div>
            trash or waste materials left in an inappropriate place, such as a
            public area or natural environment
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            The pursuit of <span className="font-bold">green politics</span> has
            become crucial in{" "}
            <span className="font-bold">
              addressing the environmental challenges
            </span>{" "}
            posed by our modern society, with an emphasis on reducing our{" "}
            <span className="font-bold">carbon footprint</span> through{" "}
            <span className="font-bold">sustainable practices</span> and{" "}
            <span className="font-bold">renewable energy sources</span> like{" "}
            <span className="font-bold">solar power</span>.
            <span className="font-bold"> Environmentalists</span> advocate for
            the adoption of <span className="font-bold">energy-efficient </span>
            technologies and promote{" "}
            <span className="font-bold">ecotourism</span> as a means of
            supporting
            <span className="font-bold"> conservation</span> efforts while{" "}
            <span className="font-bold">minimizing</span> human impact on the
            environment. <span className="font-bold">Pressure groups</span> also
            play an essential role in{" "}
            <span className="font-bold">raising awareness of</span>{" "}
            <span className="font-bold">environmental issues</span> such as the
            harmful effects of
            <span className="font-bold"> microplastics</span>,{" "}
            <span className="font-bold">smog</span> resulting from{" "}
            <span className="font-bold">heavy industry</span>, and the
            consequences of{" "}
            <span className="font-bold">waste accumulation</span> in{" "}
            <span className="font-bold">landfills</span> and{" "}
            <span className="font-bold">incineration </span>
            facilities.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">green politics</span> (n):
          </div>
          <div>
            a political approach that prioritizes environmental protection,
            sustainability, and social justice
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">carbon footprint</span> (n):
          </div>
          <div>
            the amount of greenhouse gases, primarily carbon dioxide, emitted
            due to an individual, or an organization's activities
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">solar</span> (adj):
          </div>
          <div>
            referring to energy obtained from sunlight, which can be converted
            into electricity or heat using solar panels or other devices
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">environmentalist</span> (n):
          </div>
          <div>
            a person who is concerned with protecting the environment and
            advocates for sustainable practices
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">energy-efficient</span> (adj):
          </div>
          <div>
            using less energy to perform a task or function while providing the
            same level of performance or output
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">ecotourism</span> (n):
          </div>
          <div>
            tourism that involves visiting natural areas with a focus on
            minimizing negative environmental impacts and supporting
            conservation efforts
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">conservation</span> (n):
          </div>
          <div>
            the protection and preservation of natural resources, habitats, and
            ecosystems
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">minimize</span> (v):
          </div>
          <div>to reduce or lessen something as much as possible</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">pressure group</span> (n):
          </div>
          <div>
            an organized group of people who work together to influence
            government policies or public opinion on specific issues
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">raise awareness of</span> (v):
          </div>
          <div>
            to bring attention to a topic, problem, or cause so that more people
            know about it
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">microplastic</span> (n):
          </div>
          <div>
            tiny pieces of plastic, often invisible to the naked eye, that are
            present in various products and can accumulate in the environment,
            posing potential risks to aquatic life, wildlife, and human health
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">smog</span> (n):
          </div>
          <div>
            A type of air pollution that creates a haze or fog in the
            atmosphere, often caused by emissions from vehicles or industry
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">heavy industry</span> (n):
          </div>
          <div>
            industries that involve the production of large or heavy products,
            such as steel, chemicals, or machinery
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">waste accumulation</span> (n):
          </div>
          <div>
            the build-up of waste materials over time, often due to improper
            disposal or lack of recycling
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">landfill</span> (n):
          </div>
          <div>
            a designated site for disposing of waste materials, where they are
            buried and compacted under layers of soil
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">incineration</span> (n):
          </div>
          <div>
            the process of burning waste materials at high temperatures, often
            used for waste disposal or to generate electricity
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            The rapid <span className="font-bold">population growth</span> has
            led to increased pressure on our environment,{" "}
            <span className="font-bold">raising concerns about</span>{" "}
            <span className="font-bold">the worst-case scenario</span> where
            resources become <span className="font-bold">scarce</span> and the
            Earth struggles to support all
            <span className="font-bold"> living beings</span>. As{" "}
            <span className="font-bold">natural disasters</span> such as{" "}
            <span className="font-bold">flash floods</span> and
            <span className="font-bold"> earthquakes</span> become more
            frequent, we are constantly reminded of the need to protect our
            planet and find <span className="font-bold">sustainable</span>{" "}
            solutions to ensure our <span className="font-bold">survival</span>.
            Furthermore, issues like{" "}
            <span className="font-bold">acid rain</span> and{" "}
            <span className="font-bold">malnutrition </span>
            highlight the interconnectedness of our environment and human
            well-being, emphasizing the importance of achieving balance in our
            <span className="font-bold"> ecosystems</span>.{" "}
            <span className="font-bold">Getting back to nature</span> and{" "}
            <span className="font-bold">adopting practices</span> that
            <span className="font-bold"> foster harmony between</span> humans
            and the environment is crucial in
            <span className="font-bold"> safeguarding</span> our future.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">the worst-case scenario</span> (n):
          </div>
          <div>the most negative or severe possible outcome of a situation</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">scarce</span> (adj):
          </div>
          <div>
            limited or in short supply, often referring to resources or goods
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">living beings / things</span> (n):
          </div>
          <div>
            refers to all creatures that are alive, including humans, animals,
            and plants
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">natural disaster</span> (n):
          </div>
          <div>
            a catastrophic event resulting from natural causes, such as floods,
            earthquakes, hurricanes, or volcanic eruptions
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">flash flood</span> (n):
          </div>
          <div>
            a sudden and rapid flooding event, often caused by heavy rainfall or
            other abrupt factors
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">acid rain</span> (n):
          </div>
          <div>
            rainfall that contains a high concentration of pollutants,
            particularly sulfur dioxide and nitrogen oxides, causing harm to the
            environment and ecosystems
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">malnutrition</span> (n):
          </div>
          <div>
            a condition resulting from inadequate or unbalanced intake of
            nutrients, often leading to poor health and development
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">get back to nature</span> (idiom):
          </div>
          <div>
            to re-establish a connection with the natural world by spending time
            outdoors, engaging in activities such as hiking, camping, or
            gardening, and appreciating the beauty and value of nature
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">foster</span> (v):
          </div>
          <div>
            to promote, encourage, or support the development or growth of
            something
          </div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            The introduction of{" "}
            <span className="font-bold">invasive species</span> into new{" "}
            <span className="font-bold">ecosystems</span> often disrupts the
            natural balance and{" "}
            <span className="font-bold">poses threats to</span> native and
            <span className="font-bold"> endangered species</span>.
            Simultaneously, human activities such as
            <span className="font-bold"> overfishing deplete fish stocks</span>,
            further impacting <span className="font-bold">biodiversity</span>.
            To
            <span className="font-bold"> counteract</span> these issues,
            advocates for <span className="font-bold">animal rights</span> push
            for legislation to protect{" "}
            <span className="font-bold">endangered species</span> and their{" "}
            <span className="font-bold">habitats</span> from
            <span className="font-bold"> illegal logging</span> and{" "}
            <span className="font-bold">poaching</span>. Without proper
            intervention, many species risk{" "}
            <span className="font-bold">dying out</span> or{" "}
            <span className="font-bold">becoming extinct</span>, leading to
            irreversible consequences for the environment and our planet as a
            whole.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">invasive species</span> (n):
          </div>
          <div>
            non-native organisms that cause harm to a new ecosystem after being
            introduced
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">endangered species</span> (n):
          </div>
          <div>
            species at risk of extinction due to various factors, such as
            habitat loss, climate change, or human activities
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">fish stocks</span> (n):
          </div>
          <div>
            the populations of fish in a particular area, often referring to
            those that are commercially valuable or important for the ecosystem
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">illegal logging</span> (n):
          </div>
          <div>
            the unauthorized harvesting of timber in violation of laws or
            regulations, often resulting in deforestation and harm to ecosystems
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">poaching</span> (n):
          </div>
          <div>
            the illegal hunting, capturing, or killing of animals, often for
            their valuable parts or to sell as pets
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">die out</span> (v):
          </div>
          <div>
            to become extinct or cease to exist, often referring to a species or
            population
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">extinct</span> (adj):
          </div>
          <div>(of a species) no longer existing; permanently disappeared</div>
        </div>

        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            <span className="font-bold">Preserving</span> the diverse{" "}
            <span className="font-bold">flora and fauna</span> found in our
            planet's
            <span className="font-bold"> pristine</span> and{" "}
            <span className="font-bold">unspoiled</span> environments is a
            crucial responsibility shared by{" "}
            <span className="font-bold">conservationists</span> worldwide.{" "}
            <span className="font-bold">Tropical</span> regions, with their
            <span className="font-bold"> lush vegetation</span> and unique{" "}
            <span className="font-bold">ecosystems</span>, are home to countless
            species and serve as a reminder of the beauty that nature has to
            offer. Promoting{" "}
            <span className="font-bold">environmentally friendly</span>{" "}
            practices in these areas helps protect the delicate balance of life,
            ensuring that future generations can continue to appreciate and
            learn from the intricate relationships found in these vibrant{" "}
            <span className="font-bold">landscapes</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">flora and fauna</span> (n):
          </div>
          <div>
            the plants (flora) and animals (fauna) found in a particular region
            or environment
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">pristine</span> (adj):
          </div>
          <div>
            in its original, pure, and unspoiled condition; untouched by human
            activities
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">unspoiled</span> (adj):
          </div>
          <div>
            not damaged or harmed by human activities; retaining its natural
            beauty and purity
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">tropical</span> (adj):
          </div>
          <div>
            relating to the warm, humid regions near the Equator, typically with
            abundant rainfall and diverse ecosystems
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">lush</span> (adj):
          </div>
          <div>
            rich in vegetation, with abundant plant growth; green and luxurious
            in appearance
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">vegetation</span> (n):
          </div>
          <div>
            plants or plant growth found in an area, often referring to the
            species composition and distribution
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">environmentally friendly</span> (adj):
          </div>
          <div>
            refers to practices, products, or activities that have a minimal
            negative impact on the environment or contribute to its preservation
            and sustainability
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-10">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">landscape</span> (n):
          </div>
          <div>
            the natural or man-made features of an area, including its
            landforms, ecosystems, and structures, forming a distinct visual and
            ecological unit
          </div>
        </div>
      </div>
    </>
  );
};
