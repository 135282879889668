import { Link } from "react-router-dom";
import { useTitle } from "../components";
import MyPicture from "../assets/images/MyPicture.jpg";

export const About = ({ title }) => {
  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>

          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">About</span>
        </nav>
      </div>

      {/* -------------------- Body in English -------------------- */}
      <div
        data-aos="zoom-in"
        data-aos-duration="800"
        className="mt-7 mb-12 mx-9 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12"
      >
        <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
          About Me
        </h1>
        <div className="flex flex-col bg-pink-100 rounded-lg pb-7 sm:pb-12">
          <img
            className="w-min mx-auto my-8 sm:my-12 rounded-full"
            src={MyPicture}
            alt="Aptia Rahgozar"
          />
          <p className="px-7 sm:px-12 leading-normal">
            Hi there! My name is Aptia Rahgozar, and I've been a dedicated
            English teacher since 2007. I'm excited to share my years of
            experience and expertise in teaching English and preparing students
            for the IELTS exam. On this website, I hope to provide valuable
            insights and resources for those looking to improve their English
            language skills. If you'd like to learn more about my professional
            background, feel free to visit{" "}
            <a
              href="https://www.linkedin.com/in/aptia-rahgozar"
              target="_blank"
              rel="noreferrer"
              className="underline hover:text-pink-700"
            >
              my LinkedIn profile
            </a>
            . Let's embark on this language-learning journey together!
          </p>
        </div>
      </div>
    </>
  );
};
