import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const ToBePastElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the mini-dialogues with the correct form of the verb{" "}
      <span className="underline">be</span>. Use contractions if you can.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span>"____ you and your brother at the gym last night?"</span>
          <span className="ml-8">"Yes, we ____."</span>
        </span>
      ),
      answer: (
        <span className="font-normal">
          <span>
            "<span className="font-bold">Were</span> you and your brother at the
            gym last night?"
          </span>
          <span className="ml-8">
            "Yes, we <span className="font-bold">were</span>."
          </span>
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          <span>"You went to the cinema last night. ____ the movie good?"</span>
          <span className="pl-8">"No, it ____. The actors ____ terrible."</span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "You went to the cinema last night.{" "}
            <span className="font-bold">Was</span> the movie good?"
          </span>
          <span className="pl-8">
            "No, it <span className="font-bold">wasn't</span>. The actors{" "}
            <span className="font-bold">were</span> terrible."
          </span>
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          <span>"Where ____ your dad's parents born?"</span>
          <span className="ml-8">"They ____ born in Japan in the 1930s."</span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "Where <span className="font-bold">were</span> your dad's parents
            born?"
          </span>
          <span className="ml-8">
            "They <span className="font-bold">were</span> born in Japan in the
            1930s."
          </span>
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          <span>"____ you at home at the weekend?"</span>
          <span className="ml-8">
            "No, I ____, but my wife ____. She had to take care of the kids."
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "<span className="font-bold">Were</span> you at home at the
            weekend?"
          </span>
          <span className="ml-8">
            "No, I <span className="font-bold">wasn't</span>, but my wife{" "}
            <span className="font-bold">was</span>. She had to take care of the
            kids."
          </span>
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">To Be (Past)</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            To Be (Past)
          </h1>
          <p className="mb-9">
            The verb "to be" is a special verb in English that can show
            information about who, what, where, or how someone or something is.
            When we use "to be," we change its form depending on the subject,
            which is called conjugation. For example, in the <mark>past</mark>{" "}
            tense, we say "I was," "you were," etc. "To be" is an important verb
            to learn because it helps us describe people, things, and
            situations.
          </p>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Positive</h3>
            <p>
              I <span className="font-semibold">was</span> ... .
            </p>
            <p>
              You <span className="font-semibold">were</span> ... .
            </p>
            <p>
              He <span className="font-semibold">was</span> ... .
            </p>
            <p>
              She <span className="font-semibold">was</span> ... .
            </p>
            <p>
              It <span className="font-semibold">was</span> ... .
            </p>
            <p>
              We <span className="font-semibold">were</span> ... .
            </p>
            <p>
              You <span className="font-semibold">were</span> ... .
            </p>
            <p>
              They <span className="font-semibold">were</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Negative</h3>
            <p>
              I <span className="font-semibold">was</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / I
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              You <span className="font-semibold">were</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              You
              <span className="font-semibold"> were</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              He <span className="font-semibold">was</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / He
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              She <span className="font-semibold">was</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              She
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              It <span className="font-semibold">was</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / It
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              We <span className="font-semibold">were</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / We
              <span className="font-semibold"> were</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              You <span className="font-semibold">were</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              You
              <span className="font-semibold"> were</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              They <span className="font-semibold">were</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              They
              <span className="font-semibold"> were</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Was</span> I ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> he ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> she ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> it ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> we ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> they ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Was</span> I{" "}
              <span className="font-semibold text-red-700">not</span> ...? /{" "}
              <span className="font-semibold">Was</span>
              <span className="font-semibold text-red-700">n't</span> I ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> you{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Were</span>
              <span className="font-semibold text-red-700">n't</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> he{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Was</span>
              <span className="font-semibold text-red-700">n't</span> he ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> she{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Was</span>
              <span className="font-semibold text-red-700">n't</span> she ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> it{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Was</span>
              <span className="font-semibold text-red-700">n't</span> it ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> we{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Were</span>
              <span className="font-semibold text-red-700">n't</span> we ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> you{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Were</span>
              <span className="font-semibold text-red-700">n't</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> they{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Were</span>
              <span className="font-semibold text-red-700">n't</span> they ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">was</span>. / No, I
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">were</span>. / No, you{" "}
              <span className="font-semibold">were</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">was</span>. / No, he{" "}
              <span className="font-semibold">was</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">was</span>. / No, she{" "}
              <span className="font-semibold">was</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">was</span>. / No, it{" "}
              <span className="font-semibold">was</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">were</span>. / No, we{" "}
              <span className="font-semibold">were</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">were</span>. / No, you{" "}
              <span className="font-semibold">were</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">were</span>. / No, they{" "}
              <span className="font-semibold">were</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, 'was' and 'were' can act as both main verbs and
              auxiliary verbs. This means we use them to create negative
              sentences and questions.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She was at home last night.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She wasn't at home last night.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Was she at home last night?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Wasn't she at home last night? / Was she not at home last night?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she was. / No, she wasn't.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They were friendly.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">They weren't friendly.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Were they friendly?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Weren't they friendly? / Were they not friendly?
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold"></span>
              <span className="italic">
                Yes, they were. / No, they weren't.
              </span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            To Be (Past)
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            فعل be به معنی «بودن / هستن» یکی از رایج ترین فعل ها در زبان انگلیسی
            است. نکته متفاوت در مورد این فعل این است که بر خلاف اغلب فعل ها در
            زبان انگلیسی هم در زمان حال و هم در زمان گذشته برای فاعل های متفاوت
            صرف می شود.
          </p>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Positive</span>
              <span lang="fa" dir="rtl" className="ml-8">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">was</span> ... .
            </p>
            <p>
              You <span className="font-semibold">were</span> ... .
            </p>
            <p>
              He <span className="font-semibold">was</span> ... .
            </p>
            <p>
              She <span className="font-semibold">was</span> ... .
            </p>
            <p>
              It <span className="font-semibold">was</span> ... .
            </p>
            <p>
              We <span className="font-semibold">were</span> ... .
            </p>
            <p>
              You <span className="font-semibold">were</span> ... .
            </p>
            <p>
              They <span className="font-semibold">were</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Negative</span>
              <span lang="fa" dir="rtl" className="ml-8">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">was</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / I
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              You <span className="font-semibold">were</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              You
              <span className="font-semibold"> were</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              He <span className="font-semibold">was</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / He
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              She <span className="font-semibold">was</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              She
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              It <span className="font-semibold">was</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / It
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              We <span className="font-semibold">were</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / We
              <span className="font-semibold"> were</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              You <span className="font-semibold">were</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              You
              <span className="font-semibold"> were</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              They <span className="font-semibold">were</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              They
              <span className="font-semibold"> were</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Positive Questions</span>
              <span lang="fa" dir="rtl" className="ml-8">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Was</span> I ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> he ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> she ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> it ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> we ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> they ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Negative Questions</span>
              <span lang="fa" dir="rtl" className="ml-8">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Was</span> I{" "}
              <span className="font-semibold text-red-700">not</span> ...? /{" "}
              <span className="font-semibold">Was</span>
              <span className="font-semibold text-red-700">n't</span> I ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> you{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Were</span>
              <span className="font-semibold text-red-700">n't</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> he{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Was</span>
              <span className="font-semibold text-red-700">n't</span> he ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> she{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Was</span>
              <span className="font-semibold text-red-700">n't</span> she ... ?
            </p>
            <p>
              <span className="font-semibold">Was</span> it{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Was</span>
              <span className="font-semibold text-red-700">n't</span> it ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> we{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Were</span>
              <span className="font-semibold text-red-700">n't</span> we ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> you{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Were</span>
              <span className="font-semibold text-red-700">n't</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Were</span> they{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Were</span>
              <span className="font-semibold text-red-700">n't</span> they ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Short Answers</span>
              <span lang="fa" dir="rtl" className="ml-8">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">was</span>. / No, I
              <span className="font-semibold"> was</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">were</span>. / No, you{" "}
              <span className="font-semibold">were</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">was</span>. / No, he{" "}
              <span className="font-semibold">was</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">was</span>. / No, she{" "}
              <span className="font-semibold">was</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">was</span>. / No, it{" "}
              <span className="font-semibold">was</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">were</span>. / No, we{" "}
              <span className="font-semibold">were</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">were</span>. / No, you{" "}
              <span className="font-semibold">were</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">were</span>. / No, they{" "}
              <span className="font-semibold">were</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom ml-3"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              همانطور که در ساختار های بالا می بینیم was و were خودشان هم نقش
              فعل اصلی و هم نقش فعل کمکی را برای منفی و سوالی کردن جملات بازی می
              کنند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She was at home last night.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-3">
              او دیشب خانه بود.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She wasn't at home last night.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-3">
              او دیشب خانه نبود.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Was she at home last night?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-3">
              آیا او دیشب خانه بود؟
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Wasn't she at home last night? / Was she not at home last night?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-3">
              آیا او دیشب خانه نبود؟
            </p>
            <p className="flex mb-14">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she was. / No, she wasn't.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They were friendly.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-3">
              آنها مهربان بودند.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">They weren't friendly.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-3">
              آنها مهربان نبودند / دوستانه رفتار نمی کردند.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Were they friendly?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-3">
              آیا رفتارشان دوستانه بود؟
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Weren't they friendly? / Were they not friendly?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-3">
              آیا رفتارشان دوستانه نبود؟
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold"></span>
              <span className="italic">
                Yes, they were. / No, they weren't.
              </span>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
