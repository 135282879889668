import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LongArrow from "../../../assets/images/arrowLongLight.svg";

export const PastSimpleIrregularElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences using the Simple Past form of the verbs in
      parentheses. Use contractions wherever possible.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span>"____ you ____ out last night?" (go)</span>
          <span className="ml-8">
            "Yes, we ____ to a new restaurant, and we ____ pasta." (go - have)
          </span>
        </span>
      ),
      answer: (
        <span className="font-normal">
          <span>
            "<span className="font-bold">Did</span> you{" "}
            <span className="font-bold">go</span> out last night?"
          </span>
          <span className="ml-8">
            "Yes, we <span className="font-bold">went</span> to a new
            restaurant, and we <span className="font-bold">had</span> pasta."
          </span>
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          <span>"____ you ____ Jenny last night?" (see)</span>
          <span className="pl-8">
            "No, I ____, but I ____ her brother. He ____ Jenny didn't feel
            well." (see - say)
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "<span className="font-bold">Did</span> you{" "}
            <span className="font-bold">see</span> Jenny last night?"
          </span>
          <span className="pl-8">
            "No, I <span className="font-bold">didn't</span>, but I{" "}
            <span className="font-bold">saw</span> her brother. He{" "}
            <span className="font-bold">said</span> Jenny didn't feel well."
          </span>
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          <span>"____ you ____ your homework yesterday?" (do)</span>
          <span className="ml-8">
            "Yes, I ____, but I ____ some questions difficult." (find)
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "<wrap className="font-bold">Did</wrap> you{" "}
            <span className="font-bold">do</span> your homework yesterday?")
          </span>
          <span className="ml-8">
            "Yes, I <span className="font-bold">did</span>, but I{" "}
            <span className="font-bold">found</span> some questions difficult."
          </span>
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          <span>"____ Sarah ____ to the park in the morning?" (go)</span>
          <span className="ml-8">
            "No, she ____. She ____ to the library to study." (go)
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "<span className="font-bold">Did</span> Sarah{" "}
            <span className="font-bold">go</span> to the park in the morning?"
          </span>
          <span className="ml-8">
            "No, she <span className="font-bold">didn't</span>. She{" "}
            <span className="font-bold">went</span> to the library to study."
          </span>
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Past Simple (Irregular Verbs)</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Past Simple (Irregular Verbs)
          </h1>

          <p className="mb-4">
            An irregular verb is a verb that does not follow the usual Simple
            Past pattern (-ed), and its form changes in the Simple Past tense.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>eat (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>ate (p)</span>
            </p>
            <p className="pb-1 italic flex">
              <span>go (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>went (p)</span>
            </p>
            <p className="pb-1 italic flex">
              <span>have (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>had (p)</span>
            </p>
          </div>

          <p className="mb-4">
            Let's take a look at the different forms of the Past Simple tense
            for the irregular verb "go".
          </p>
          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Positive</h3>
            <p>
              I <span className="font-semibold">went</span> ... .
            </p>
            <p>
              You <span className="font-semibold">went</span> ... .
            </p>
            <p>
              He <span className="font-semibold">went</span> ... .
            </p>
            <p>
              She <span className="font-semibold">went</span> ... .
            </p>
            <p>
              It <span className="font-semibold">went</span> ... .
            </p>
            <p>
              We <span className="font-semibold">went</span> ... .
            </p>
            <p>
              You <span className="font-semibold">went</span> ... .
            </p>
            <p>
              They <span className="font-semibold">went</span> ... .
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Negative</h3>
            <p>
              I <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              I<span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              You <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              You
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              He <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              He
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              She <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              She
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              It <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              It
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              We <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              We
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              You <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              You
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              They <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              They
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Did</span> I go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> he go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> she go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> it go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> we go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you go... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> they go ... ?
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Did</span> I{" "}
              <span className="font-semibold text-red-700">not</span> go ...? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> I go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> you go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> he{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> he go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> she{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> she go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> it{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> it go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> we{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> we go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> you go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> they{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> they go
              ... ?
            </p>
          </div>

          <div className="pb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">did</span>. / No, I
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">did</span>. / No, you{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">did</span>. / No, he{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">did</span>. / No, she{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">did</span>. / No, it{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">did</span>. / No, we{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">did</span>. / No, you{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">did</span>. / No, they{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              In the Past Simple tense, we use the auxiliary verb 'did' to form
              negative sentences and questions. When 'did' is used, the main
              verb returns to its base form.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She had a great time.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She didn't have a great time.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Did she have a great time?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Didn't she have a great time? / Did she not have a great time?
              </span>
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she did. / No, she didn't.</span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              We need to memorize the past forms of irregular verbs. A good
              dictionary is the best place to check them. Below is a list of the
              most common irregular verbs in English.
            </span>
          </p>

          <div className="mx-auto w-max">
            <table className="table-auto border-separate border border-slate-300 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-300 px-2">Base Form</th>
                  <th className="border border-slate-300 px-2">Past</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-300 px-2">become</td>
                  <td className="border border-slate-300 px-2">became</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">begin</td>
                  <td className="border border-slate-300 px-2">began</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">break</td>
                  <td className="border border-slate-300 px-2">broke</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">bring</td>
                  <td className="border border-slate-300 px-2">brought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">build</td>
                  <td className="border border-slate-300 px-2">built</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">buy</td>
                  <td className="border border-slate-300 px-2">bought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">catch</td>
                  <td className="border border-slate-300 px-2">caught</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">come</td>
                  <td className="border border-slate-300 px-2">came</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">cost</td>
                  <td className="border border-slate-300 px-2">cost</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">do</td>
                  <td className="border border-slate-300 px-2">did</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">drink</td>
                  <td className="border border-slate-300 px-2">drank</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">drive</td>
                  <td className="border border-slate-300 px-2">drove</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">eat</td>
                  <td className="border border-slate-300 px-2">ate</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">fall</td>
                  <td className="border border-slate-300 px-2">fell</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">feel</td>
                  <td className="border border-slate-300 px-2">felt</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">find</td>
                  <td className="border border-slate-300 px-2">found</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">fly</td>
                  <td className="border border-slate-300 px-2">flew</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">forget</td>
                  <td className="border border-slate-300 px-2">forgot</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">get</td>
                  <td className="border border-slate-300 px-2">got</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">give</td>
                  <td className="border border-slate-300 px-2">gave</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">go</td>
                  <td className="border border-slate-300 px-2">went</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">have</td>
                  <td className="border border-slate-300 px-2">had</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">hear</td>
                  <td className="border border-slate-300 px-2">heard</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">know</td>
                  <td className="border border-slate-300 px-2">knew</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">leave</td>
                  <td className="border border-slate-300 px-2">left</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">lose</td>
                  <td className="border border-slate-300 px-2">lost</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">make</td>
                  <td className="border border-slate-300 px-2">made</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">meet</td>
                  <td className="border border-slate-300 px-2">met</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">pay</td>
                  <td className="border border-slate-300 px-2">paid</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">put</td>
                  <td className="border border-slate-300 px-2">put</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">read</td>
                  <td className="border border-slate-300 px-2">read</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">run</td>
                  <td className="border border-slate-300 px-2">ran</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">say</td>
                  <td className="border border-slate-300 px-2">said</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">see</td>
                  <td className="border border-slate-300 px-2">saw</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">send</td>
                  <td className="border border-slate-300 px-2">sent</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sing</td>
                  <td className="border border-slate-300 px-2">sang</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sit</td>
                  <td className="border border-slate-300 px-2">sat</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sleep</td>
                  <td className="border border-slate-300 px-2">slept</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">speak</td>
                  <td className="border border-slate-300 px-2">spoke</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">spend</td>
                  <td className="border border-slate-300 px-2">spent</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">stand</td>
                  <td className="border border-slate-300 px-2">stood</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">swim</td>
                  <td className="border border-slate-300 px-2">swam</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">teach</td>
                  <td className="border border-slate-300 px-2">taught</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">take</td>
                  <td className="border border-slate-300 px-2">took</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">tell</td>
                  <td className="border border-slate-300 px-2">told</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">think</td>
                  <td className="border border-slate-300 px-2">thought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">understand</td>
                  <td className="border border-slate-300 px-2">understood</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">wake</td>
                  <td className="border border-slate-300 px-2">woke</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">wear</td>
                  <td className="border border-slate-300 px-2">wore</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">win</td>
                  <td className="border border-slate-300 px-2">won</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">write</td>
                  <td className="border border-slate-300 px-2">wrote</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Simple Past <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              (افعال بی قاعده)
            </span>
          </h1>

          <p lang="fa" dir="rtl" className="mb-4">
            افعال بی قاعده، فعل هایی هستند که در زمان Simple Past شکل گذشته آنها
            "ed-" نمی گیرد بلکه تغییر شکل می دهد.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>eat (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>ate (p)</span>
            </p>
            <p className="pb-1 italic flex">
              <span>go (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>went (p)</span>
            </p>
            <p className="pb-1 italic flex">
              <span>have (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>had (p)</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-4">
            در ادامه ساختار های مختلف جمله در این زمان را برای فعل بی قاعده go
            به عنوان مثال مشاهده می کنید.
          </p>
          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive</span>
              <span lang="fa" dir="rtl" className="ml-8">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">went</span> ... .
            </p>
            <p>
              You <span className="font-semibold">went</span> ... .
            </p>
            <p>
              He <span className="font-semibold">went</span> ... .
            </p>
            <p>
              She <span className="font-semibold">went</span> ... .
            </p>
            <p>
              It <span className="font-semibold">went</span> ... .
            </p>
            <p>
              We <span className="font-semibold">went</span> ... .
            </p>
            <p>
              You <span className="font-semibold">went</span> ... .
            </p>
            <p>
              They <span className="font-semibold">went</span> ... .
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative</span>
              <span lang="fa" dir="rtl" className="ml-8">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              I<span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              You <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              You
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              He <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              He
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              She <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              She
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              It <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              It
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              We <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              We
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              You <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              You
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
            <p>
              They <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> go ... . /
              They
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> go ... .
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive Questions</span>
              <span lang="fa" dir="rtl" className="ml-8">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Did</span> I go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> he go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> she go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> it go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> we go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you go... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> they go ... ?
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative Questions</span>
              <span lang="fa" dir="rtl" className="ml-8">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Did</span> I{" "}
              <span className="font-semibold text-red-700">not</span> go ...? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> I go ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> you go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> he{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> he go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> she{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> she go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> it{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> it go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> we{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> we go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> you go ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> they{" "}
              <span className="font-semibold text-red-700">not</span> go ... ? /{" "}
              <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> they go
              ... ?
            </p>
          </div>

          <div className="pb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Short Answers</span>
              <span lang="fa" dir="rtl" className="ml-8">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">did</span>. / No, I
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">did</span>. / No, you{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">did</span>. / No, he{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">did</span>. / No, she{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">did</span>. / No, it{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">did</span>. / No, we{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">did</span>. / No, you{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">did</span>. / No, they{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              برای منفی و سوالی کردن جمله ها در این زمان از فعل کمکی did استفاده
              می کنیم. همانطور که مشاهده می کنید، وقتی did در جمله استفاده می
              شود، فعل اصلی جمله به صورت مصدر ساده است.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p lang="fa" dir="rtl" className="font-semibold pb-4">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She had a great time.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              او اوقات خوشی داشت. / او بهش خوش گذشت.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She didn't have a great time.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              او بهش خوش نگذشت.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Did she have a great time?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              آیا بهش خوش گذشت؟
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Didn't she have a great time? / Did she not have a great time?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              آیا بهش خوش نگذشت؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she did. / No, she didn't.</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              شکل گذشته افعال بی قاعده را باید حفظ کنیم و بهترین منبع برای چک
              کردن آنها یک واژه نامه معتبر است. در پایین، لیستی از رایج ترین
              افعال بی قاعده در زبان انگلیسی را مشاهده می کنید.
            </span>
          </p>

          <div className="mx-auto w-max">
            <table className="table-auto border-separate border border-slate-300 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-300 px-2">Base Form</th>
                  <th className="border border-slate-300 px-2">Past</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-300 px-2">become</td>
                  <td className="border border-slate-300 px-2">became</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">begin</td>
                  <td className="border border-slate-300 px-2">began</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">break</td>
                  <td className="border border-slate-300 px-2">broke</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">bring</td>
                  <td className="border border-slate-300 px-2">brought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">build</td>
                  <td className="border border-slate-300 px-2">built</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">buy</td>
                  <td className="border border-slate-300 px-2">bought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">catch</td>
                  <td className="border border-slate-300 px-2">caught</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">come</td>
                  <td className="border border-slate-300 px-2">came</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">cost</td>
                  <td className="border border-slate-300 px-2">cost</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">do</td>
                  <td className="border border-slate-300 px-2">did</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">drink</td>
                  <td className="border border-slate-300 px-2">drank</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">drive</td>
                  <td className="border border-slate-300 px-2">drove</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">eat</td>
                  <td className="border border-slate-300 px-2">ate</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">fall</td>
                  <td className="border border-slate-300 px-2">fell</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">feel</td>
                  <td className="border border-slate-300 px-2">felt</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">find</td>
                  <td className="border border-slate-300 px-2">found</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">fly</td>
                  <td className="border border-slate-300 px-2">flew</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">forget</td>
                  <td className="border border-slate-300 px-2">forgot</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">get</td>
                  <td className="border border-slate-300 px-2">got</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">give</td>
                  <td className="border border-slate-300 px-2">gave</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">go</td>
                  <td className="border border-slate-300 px-2">went</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">have</td>
                  <td className="border border-slate-300 px-2">had</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">hear</td>
                  <td className="border border-slate-300 px-2">heard</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">know</td>
                  <td className="border border-slate-300 px-2">knew</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">leave</td>
                  <td className="border border-slate-300 px-2">left</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">lose</td>
                  <td className="border border-slate-300 px-2">lost</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">make</td>
                  <td className="border border-slate-300 px-2">made</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">meet</td>
                  <td className="border border-slate-300 px-2">met</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">pay</td>
                  <td className="border border-slate-300 px-2">paid</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">put</td>
                  <td className="border border-slate-300 px-2">put</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">read</td>
                  <td className="border border-slate-300 px-2">read</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">run</td>
                  <td className="border border-slate-300 px-2">ran</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">say</td>
                  <td className="border border-slate-300 px-2">said</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">see</td>
                  <td className="border border-slate-300 px-2">saw</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">send</td>
                  <td className="border border-slate-300 px-2">sent</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sing</td>
                  <td className="border border-slate-300 px-2">sang</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sit</td>
                  <td className="border border-slate-300 px-2">sat</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sleep</td>
                  <td className="border border-slate-300 px-2">slept</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">speak</td>
                  <td className="border border-slate-300 px-2">spoke</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">spend</td>
                  <td className="border border-slate-300 px-2">spent</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">stand</td>
                  <td className="border border-slate-300 px-2">stood</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">swim</td>
                  <td className="border border-slate-300 px-2">swam</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">teach</td>
                  <td className="border border-slate-300 px-2">taught</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">take</td>
                  <td className="border border-slate-300 px-2">took</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">tell</td>
                  <td className="border border-slate-300 px-2">told</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">think</td>
                  <td className="border border-slate-300 px-2">thought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">understand</td>
                  <td className="border border-slate-300 px-2">understood</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">wake</td>
                  <td className="border border-slate-300 px-2">woke</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">wear</td>
                  <td className="border border-slate-300 px-2">wore</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">win</td>
                  <td className="border border-slate-300 px-2">won</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">write</td>
                  <td className="border border-slate-300 px-2">wrote</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
