import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { TitleCard, useTitle } from "../components";

export const Ielts = ({ title }) => {
  const IeltsCards = [
    {
      heading: "Writing Task 2 (Essay) Topics",
      subheading: "",
      url: "/Ielts/writingTask2Topics",
      id: "i01",
    },
    {
      heading: "Topical Vocabulary",

      url: "/Ielts/topicalVocabulary",
      id: "i02",
    },
  ];

  useTitle(title);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.englishhubonline.com/ielts" />
      </Helmet>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">IELTS</span>
      </nav>

      {/* ---------- Body ---------- */}
      <div data-aos="zoom-in" data-aos-duration="800">
        <h1 className="text-pink-700 text-4xl sm:text-5xl font-bold drop-shadow text-center mt-10">
          IELTS
        </h1>
        <div className="flex flex-wrap md:max-w-3xl mx-auto pt-14 mb-12 justify-center">
          {IeltsCards.map((item) => (
            <TitleCard key={item.id} card={item} />
          ))}
        </div>
      </div>
    </>
  );
};
