import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTitle } from "../../../components";

export const ComputerInternetVocabulary = ({ title }) => {
  useTitle(title);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.englishhubonline.com/ielts/topicalVocabulary/computerAndInternet"
        />
      </Helmet>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link to="/Ielts" className="duration-300 hover:text-indigo-950">
          <span>IELTS</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link
          to="/ielts/topicalVocabulary"
          className="duration-300 hover:text-indigo-950"
        >
          <span>Topical Vocab</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">Computer & Internet</span>
      </nav>

      {/* ---------- Body ---------- */}
      <div
        className="my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <h1 className="flex flex-col drop-shadow text-center mt-10 mb-14">
          <span className="text-pink-700 mb-5 text-3xl sm:text-4xl">
            IELTS Topical Vocabulary
          </span>

          <span className="font-bold text-4xl sm:text-5xl">
            Computer & Internet
          </span>
        </h1>

        <p className="mb-10 leading-normal">
          The expressions printed in <span className="font-bold">bold</span>{" "}
          within the paragraphs below can enrich your vocabulary range when
          you're writing or speaking about topics related to computers or the
          Internet.
        </p>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-10 leading-normal">
          <p>
            In the fast-paced world of technology, we often encounter ambitious
            promises, products hyped as revolutionary but ultimately remaining
            <span className="font-bold"> vaporware</span>. However, when it
            comes to <span className="font-bold">interactive applications</span>
            , <span className="font-bold">big data</span> takes center stage.
            <span className="font-bold"> Developers</span> harness vast{" "}
            <span className="font-bold">data sets </span>
            to optimize <span className="font-bold">user experience</span>,
            personalize <span className="font-bold">content</span>, and predict
            trends. Striking a delicate balance, they aim to avoid{" "}
            <span className="font-bold">offensive material</span> while ensuring
            their <span className="font-bold">content</span> goes{" "}
            <span className="font-bold">viral</span>. The challenge lies in
            creating engaging, data-driven experiences without compromising
            ethical boundaries.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">vaporware</span> (n):
          </div>
          <div>
            software (or hardware) which has been advertised but is not
            available to buy and may never be
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">interactive</span> (adj):
          </div>
          <div>
            an interactive computer program lets the user affect what happens on
            the screen
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">big data</span> (n):
          </div>
          <div>
            very large sets of information about people who use the Internet,
            their habits, interests etc. that can be gathered and analyzed for
            different purposes
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">developer</span> (n):
          </div>
          <div>
            a person or a company whose job is creating computer software and
            websites
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">user experience / UX</span> (n):
          </div>
          <div>
            how easy or enjoyable it is for someone to use a product or a
            website
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">content</span> (n):
          </div>
          <div>information, images, video clips etc. on a website</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">offensive material</span> (n):
          </div>
          <div>
            online material that can be upsetting or insulting in some way
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">viral</span> (adj):
          </div>
          <div>
            describing a message, video clip, image etc. that is spread or
            shared rapidly on the Internet by a large number of people
          </div>
        </div>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md my-10 leading-normal">
          <p>
            As a dedicated <span className="font-bold">computer buff</span>, I’m
            often <span className="font-bold">glued to my screen or phone</span>
            , exploring the latest advancements in{" "}
            <span className="font-bold">hardware</span> and{" "}
            <span className="font-bold">peripherals</span>. Whether it is a
            lightning-fast SSD <span className="font-bold">upload</span> or the
            immersive experience of{" "}
            <span className="font-bold">virtual reality</span>, I am fascinated
            by new <span className="font-bold">gizmos</span> and how technology
            shapes our lives. From sleek laptops to{" "}
            <span className="font-bold">cutting-edge VR headsets</span>, the
            world of <span className="font-bold">peripherals</span> keeps
            evolving, and I am here for every{" "}
            <span className="font-bold">upgrade</span>!
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">computer buff</span> (n):
          </div>
          <div>
            somebody who is very keen on computers, the Internet and different
            applications and knows a lot about them
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">be glued to sth</span> (idiom):
          </div>
          <div>giving your full attention to something</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">peripheral</span> (n):
          </div>
          <div>
            a device that is connected to a computer like a printer, a monitor
            etc.
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">virtual reality / VR</span> (n):
          </div>
          <div>
            an environment created by a computer that seems very similar to
            reality to the user
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">gizmo</span> (n):
          </div>
          <div>a small new gadget</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">cutting-edge</span> (adj):
          </div>
          <div>the newest and the most advanced</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">headset</span> (adj):
          </div>
          <div>
            a piece of equipment worn on the head that lets the user hear sound
            and see images produced by a computer in front of their eyes
          </div>
        </div>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md my-10 leading-normal">
          <p>
            In the <span className="font-bold">digital age</span>,{" "}
            <span className="font-bold">cybersecurity</span> is paramount. With
            the rise of
            <span className="font-bold"> cloud storage</span> and the increasing
            use of <span className="font-bold">cryptocurrencies</span>,
            protecting sensitive data has become more complex.{" "}
            <span className="font-bold">Hackers</span> constantly seek
            vulnerabilities to exploit, leading to{" "}
            <span className="font-bold">cybercrime</span> incidents. To
            safeguard information, organizations employ{" "}
            <span className="font-bold">encryption</span> techniques. Whether it
            is securing financial transactions or safeguarding personal files,{" "}
            <span className="font-bold">encryption</span> plays a crucial role
            in maintaining privacy and thwarting{" "}
            <span className="font-bold">cyber</span> threats.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">cyber</span> (adj):
          </div>
          <div>involving the use of computers and the Internet</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">cybersecurity</span> (n):
          </div>
          <div>
            things that are done to protect against electronic crime like
            hacking etc.
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">cloud storage</span> (n):
          </div>
          <div>
            storing data and information online on the Internet rather than on
            your own computer
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">cryptocurrency</span> (n):
          </div>
          <div>
            digital or electronic money used for buying and selling things
            online
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">hacker</span> (n):
          </div>
          <div>
            someone who secretly breaks into another person's computer system to
            do something illegal
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">cybercrime</span> (n):
          </div>
          <div>crime that is committed using the Internet</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">encryption</span> (n):
          </div>
          <div>
            protecting sensitive information by putting it in a special code, so
            that others cannot read it
          </div>
        </div>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md my-10 leading-normal">
          <p>
            In today’s <span className="font-bold">tech-savvy</span> world,
            being <span className="font-bold">computer-literate</span> is
            essential. Whether you are a seasoned{" "}
            <span className="font-bold">silver surfer</span> or a curious
            beginner, understanding how to work with different{" "}
            <span className="font-bold">gadgets</span> and{" "}
            <span className="font-bold">gizmos</span>, and bridging the{" "}
            <span className="font-bold">digital divide</span> matters.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">tech-savvy</span> (adj):
          </div>
          <div>knowing a lot about technology, especially computers</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">computer-literate</span> (adj):
          </div>
          <div>able to use computers well</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">silver surfer</span> (n):
          </div>
          <div>an old person who uses the Internet a lot</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">digital divide</span> (n):
          </div>
          <div>
            the differences of opportunity between people who have access to the
            Internet, and those who don't
          </div>
        </div>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md my-10 leading-normal">
          <p>
            In today’s interconnected world,{" "}
            <span className="font-bold">Internet access</span> has become a
            fundamental necessity. People rely on it for various purposes,
            including <span className="font-bold">online shopping</span>. The
            rise of <span className="font-bold">e-commerce platforms</span> has
            revolutionized the way we buy and sell goods. However, with
            convenience comes challenges. Users often encounter{" "}
            <span className="font-bold">spam emails </span>
            promoting dubious products or services. On a brighter note,{" "}
            <span className="font-bold">digital formats</span> have made reading
            more accessible with the popularity of
            <span className="font-bold"> e-books</span>. As technology evolves,
            we continually seek ways to{" "}
            <span className="font-bold">upgrade </span>
            our <span className="font-bold">online</span> experiences.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">e-commerce</span> (n):
          </div>
          <div>buying and selling things online</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">platform</span> (n):
          </div>
          <div>a type of computer software</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">spam</span> (n):
          </div>
          <div>unwanted email sent to advertise something</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">upgrade</span> (v):
          </div>
          <div>
            to improve a computer system by using newer software or hardware
          </div>
        </div>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md my-10 leading-normal">
          <p>
            When you <span className="font-bold">go online</span>, you can{" "}
            <span className="font-bold">browse the web</span> using a{" "}
            <span className="font-bold">search engine</span> to find relevant
            information. Whether you are{" "}
            <span className="font-bold">downloading</span> files,{" "}
            <span className="font-bold">syncing </span>
            data, <span className="font-bold">streaming</span> content, enjoying{" "}
            <span className="font-bold">video playback</span>, participating in
            <span className="font-bold"> video conferencing</span>, or simply{" "}
            <span className="font-bold">exploring websites</span>, it is
            essential to
            <span className="font-bold"> navigate websites</span> efficiently.
            Don’t forget to <span className="font-bold">bookmark</span> your
            favorite pages for easy access. Also, while using a{" "}
            <span className="font-bold">search engine</span>, it is essential to
            review your <span className="font-bold">privacy settings</span> to
            avoid encountering any{" "}
            <span className="font-bold">offensive material online</span>.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">search engine</span> (n):
          </div>
          <div>
            a website that helps users to search for something on the Internet
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">sync</span> (v):
          </div>
          <div>
            to link two or more devices like a cell phone and a laptop so that
            the information on all of them is the same
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">stream</span> (v):
          </div>
          <div>
            to play video, music etc. online without downloading and saving them
            on your computer first
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">playback</span> (n):
          </div>
          <div>
            the act of playing video, music etc. on your electronic device
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">navigate</span> (v):
          </div>
          <div>to find your way around on a website or on the Internet</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">bookmark</span> (v):
          </div>
          <div>
            to save the address of a website so that you can easily find it
            later
          </div>
        </div>
        <div className="w-11/12 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md my-10 leading-normal">
          <p>
            <span className="font-bold">Word processing</span> is a fundamental
            task in today’s <span className="font-bold">digital age</span>.
            Whether you are <span className="font-bold">drafting an email</span>
            , creating a report, or composing a
            <span className="font-bold"> blog post</span> on{" "}
            <span className="font-bold">social media</span>, having reliable{" "}
            <span className="font-bold">software</span> for{" "}
            <span className="font-bold">word processing</span> is essential.
            However, unexpected events can occur—like a sudden{" "}
            <span className="font-bold">crash </span>
            of your application. To prevent{" "}
            <span className="font-bold">data loss</span>, it is crucial to
            perform regular <span className="font-bold">backups</span>. When you{" "}
            <span className="font-bold">install</span> new software or{" "}
            <span className="font-bold">update</span> your operating system,
            ensure compatibility with your existing tools. So, whether you are a
            professional writer or a casual user, understanding these aspects
            enhances your <span className="font-bold">digital experience</span>.
            Additionally, leveraging
            <span className="font-bold">
              {" "}
              artificial intelligence (AI)
            </span>{" "}
            tools and exploring available{" "}
            <span className="font-bold">freeware </span>
            options can further enhance your productivity.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">word processing</span> (n):
          </div>
          <div>using a computer to type, edit and save a document</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">draft an email</span> (v):
          </div>
          <div>
            to write the first rough version of an email before deciding to send
            it
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">blog</span> (n):
          </div>
          <div>a web page where somebody regularly writes about a topic</div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">crash</span> (n):
          </div>
          <div>
            an occasion when an application or the operating system on your
            computer suddenly stops working
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="mr-6 min-w-fit text-pink-700">
            <span className="font-bold">freeware</span> (n):
          </div>
          <div>
            computer software that is available on the Internet for everyone
            free of charge
          </div>
        </div>
      </div>
    </>
  );
};
