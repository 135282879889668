import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import ArrowLongDown from "../../../assets/images/arrowLongDown.svg";

export const AdverbsOfFrequencyElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = <span>Put the words in the right order.</span>;
  const questions = [
    {
      id: 1,
      question: <span>sleep / seven / I / hours / for / always.</span>,
      answer: <span>I always sleep for seven hours.</span>,
    },
    {
      id: 2,
      question: (
        <span>to / the weekend / the movies / on / sometimes / we / go.</span>
      ),
      answer: <span>We sometimes go to the movies on the weekend.</span>,
    },
    {
      id: 3,
      question: <span>forget / we / our / should / never / memories.</span>,
      answer: <span>We should never forget our memories.</span>,
    },
    {
      id: 4,
      question: (
        <span>cold / hometown / my / it / hardly ever / is / very / in.</span>
      ),
      answer: <span>It is hardly ever very cold in my hometown.</span>,
    },
    {
      id: 5,
      question: <span>usually / bed / he / goes / at / to / 9 p.m.</span>,
      answer: <span>He usually goes to bed at 9 p.m.</span>,
    },
    {
      id: 6,
      question: <span>make / often / dinner / doesn't / she.</span>,
      answer: <span>She doesn't often make dinner.</span>,
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Adverbs of Frequency</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Adverbs of Frequency
          </h1>
          <p className="mb-6">
            We use the following adverbs, called adverbs of frequency to say how
            often we do something:
          </p>

          <div className="mb-12">
            <p className="ml-12">
              <span className="font-bold">always</span>
              <span className="ml-14">(100% of the time)</span>
            </p>

            <div className="flex">
              <div>
                <p className="ml-12 font-bold">usually</p>
                <p className="ml-12 font-bold">often</p>
                <p className="ml-12 font-bold">sometimes</p>
                <p className="ml-12 font-bold">hardly ever</p>
              </div>
              <div>
                <img
                  className="w-20 mt-4 md:mt-6 ml-16"
                  src={ArrowLongDown}
                  alt=""
                />
              </div>
            </div>

            <p className="ml-12">
              <span className="font-bold">never</span>
              <span className="ml-20">(0% of the time)</span>
            </p>
          </div>

          <p className="mb-6">
            The place of these adverbs in a sentence is either before the{" "}
            <mark>main verb</mark>, or after "am", "is", "are", "was" or "were".
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold mb-5">Examples:</p>
            <p className="mb-1">She sometimes has honey for breakfast.</p>
            <p className="mb-1">It doesn't often rain in my town.</p>
            <p className="mb-1">
              I must always finish my homework before wathing TV.
            </p>
            <p className="mb-1">They are usually at home on the weekend.</p>
          </div>

          <p className="flex mb-6">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Remember that with "never" and "hardly ever" we use a positive
              verb.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold mb-5">Examples:</p>
            <p className="mb-1">We hardly ever go to the movies.</p>
            <p className="mb-1">He's never on time.</p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Adverbs of Frequency
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            در زبان انگلیسی برای بیان اینکه چند وقت به چند وقت یک فعل اتفاق می
            افتد، می توانیم از قید های زیر استفاده کنیم.
          </p>

          <div className="mb-12">
            <p className="flex justify-between ml-12">
              <span className="font-bold">always</span>
              <span
                lang="fa"
                dir="rtl"
                className="mr-10 sm:mr-60 md:mr-72 lg:mr-96"
              >
                همیشه
              </span>
            </p>

            <p className="flex justify-between ml-12 ">
              <span className="font-bold">usually</span>
              <span
                lang="fa"
                dir="rtl"
                className="mr-10 sm:mr-60 md:mr-72 lg:mr-96"
              >
                معمولا
              </span>
            </p>
            <p className="flex justify-between ml-12">
              <span className="font-bold">often</span>
              <span
                lang="fa"
                dir="rtl"
                className="mr-10 sm:mr-60 md:mr-72 lg:mr-96"
              >
                اغلب
              </span>
            </p>
            <p className="flex justify-between ml-12">
              <span className="font-bold">sometimes</span>
              <span
                lang="fa"
                dir="rtl"
                className="mr-10 sm:mr-60 md:mr-72 lg:mr-96"
              >
                گاهی
              </span>
            </p>
            <p className="flex justify-between ml-12">
              <span className="font-bold">hardly ever</span>
              <span
                lang="fa"
                dir="rtl"
                className="mr-10 sm:mr-60 md:mr-72 lg:mr-96"
              >
                به ندرت
              </span>
            </p>

            <p className="flex justify-between ml-12">
              <span className="font-bold">never</span>
              <span
                lang="fa"
                dir="rtl"
                className="mr-10 sm:mr-60 md:mr-72 lg:mr-96"
              >
                هرگز، هیچ وقت
              </span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-6">
            جای این قید ها در جمله یا قبل از <mark>فعل اصلی</mark>، یا بعد از
            was ،are ،is ،am یا were است.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold mb-5 not-italic">
              مثال:
            </p>
            <p className="mb-1">She sometimes has honey for breakfast.</p>
            <p lang="fa" dir="rtl" className="not-italic mb-3">
              او گاهی وقت ها صبحانه عسل می خورد.
            </p>
            <p className="mb-1">It doesn't often rain in my town.</p>
            <p lang="fa" dir="rtl" className="not-italic mb-3">
              در شهر من اغلب باران نمی بارد.
            </p>
            <p className="mb-1">
              I must always finish my homework before wathing TV.
            </p>
            <p lang="fa" dir="rtl" className="not-italic mb-3">
              من همیشه باید قبل از اینکه تلویزیون نگاه کنم، تکالیفم را تمام کرده
              باشم.
            </p>
            <p className="mb-1">They are usually at home on the weekend.</p>
            <p lang="fa" dir="rtl" className="not-italic mb-3">
              آنها معمولا آخر هفته ها خانه هستند.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-6">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              یادمان باشد وقتی از قید های hardly ever و never استفاده می کنیم،
              فعل جمله باید مثبت باشد.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold mb-5 not-italic">
              مثال:
            </p>
            <p className="mb-1">We hardly ever go to the movies.</p>
            <p lang="fa" dir="rtl" className="not-italic mb-3">
              ما به ندرت سینما می رویم.
            </p>
            <p className="mb-1">He's never on time.</p>
            <p lang="fa" dir="rtl" className="not-italic mb-3">
              او هیچ وقت به موقع نمی رسد.
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
