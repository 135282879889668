import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import MinusCircle from "../../../assets/images/minusCircle.svg";
import QuestionMarkCircle from "../../../assets/images/questionMarkCircle.svg";
import PlusCircle from "../../../assets/images/plusCircle.svg";

export const SomeAnyElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span className="mb-2">
      Write positive or negative sentences, or questions with "there is / are" +
      "a / an", "some" or "any".
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <img
            className="w-7 h-7 inline mr-3"
            src={PlusCircle}
            alt="negative"
          />
          <span>books / the shelf</span>.
        </span>
      ),
      answer: <span>There are some books on the shelf.</span>,
    },
    {
      id: 2,
      question: (
        <span>
          <img
            className="w-7 h-7 inline mr-3"
            src={QuestionMarkCircle}
            alt="negative"
          />
          <span>chairs / the dining room</span>.
        </span>
      ),
      answer: <span>Are there any chairs in the dining room?</span>,
    },
    {
      id: 3,
      question: (
        <span>
          <img
            className="w-7 h-7 inline mr-3"
            src={PlusCircle}
            alt="negative"
          />
          <span>lamp / the bedroom</span>.
        </span>
      ),
      answer: <span>There's a lamp in the bedroom.</span>,
    },
    {
      id: 4,
      question: (
        <span>
          <img
            className="w-7 h-7 inline mr-3"
            src={QuestionMarkCircle}
            alt="negative"
          />
          <span>mirror / the bathroom</span>.
        </span>
      ),
      answer: <span>Is there a mirror in the bathroom?</span>,
    },
    {
      id: 5,
      question: (
        <span>
          <img
            className="w-7 h-7 inline mr-3"
            src={MinusCircle}
            alt="negative"
          />
          <span>apples / the fridge</span>.
        </span>
      ),
      answer: <span>There aren't any apples in the fridge.</span>,
    },
    {
      id: 6,
      question: (
        <span>
          <img
            className="w-7 h-7 inline mr-3"
            src={QuestionMarkCircle}
            alt="negative"
          />
          <span>glasses / the cupboard</span>.
        </span>
      ),
      answer: <span>Are there any glasses in the cupboard?</span>,
    },
    {
      id: 7,
      question: (
        <span>
          <img
            className="w-7 h-7 inline mr-3"
            src={MinusCircle}
            alt="negative"
          />
          <span>carpet / the hall</span>.
        </span>
      ),
      answer: <span>There isn't a carpet in the hall.</span>,
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">There Is / Are, Some / Any</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            There Is / Are,
            <br className="sm:hidden" /> Some / Any
          </h1>
          <p className="mb-9">
            We use "there is" and "there are" to say that something or somebody
            exists.
          </p>

          <p className="mb-4">
            We use "there is" for one thing or one person. "There is" is often
            cotracted to "there's".
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">There is // There's a book on the table.</p>
            <p className="pb-1">There is // There's someone at the door.</p>
          </div>

          <p className="mb-4">
            We use "there are" for more than one thing or person. "There are" is
            not usually contracted.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">There are three books on the shelf.</p>
            <p className="pb-1">There are some people in the yard.</p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              When we talk about a list of things or people, if the first item
              is singular, we use "there is". If the first itme is plural, we
              use "there are".
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">
              There <span className="font-bold">is</span>{" "}
              <span className="underline">a book</span>, two pens, and a
              notebook on the desk.
            </p>
            <p className="pb-1">
              There <span className="font-bold">are</span>{" "}
              <span className="underline">two pens</span>, a book, and a
              notebook on the desk.
            </p>
          </div>

          <p className="mb-2">
            We often use "there is / are" with "a / an", "some" or "any".
          </p>

          <div className="flex mb-1">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              We use "a" or "an" before{" "}
              <span className="font-bold">countable singular nouns</span> in{" "}
              <mark>positive</mark> and <mark>negative</mark> sentences, and{" "}
              <mark>questions</mark>.
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              <span className="font-bold mr-8">+</span>
              <span>There's a cat in the yard.</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">-</span>
              <span>There isn't a cat in the yard.</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">?</span>
              <span>Is there a cat in the yard?</span>
            </p>
          </div>

          <div className="flex mb-3">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              We use "some" before{" "}
              <span className="font-bold">countable plural</span> or{" "}
              <span className="font-bold">uncountable nouns</span> in{" "}
              <mark>positive</mark> sentences:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              <span className="font-bold mr-8">+</span>
              <span>There are some apples in the basket.</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">+</span>
              <span>There is some water in the glass.</span>
            </p>
          </div>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              We use "any" before{" "}
              <span className="font-bold">countable plural</span> and{" "}
              <span className="font-bold">uncountable nouns</span> in{" "}
              <mark>negative</mark> sentences and <mark>questions</mark>:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              <span className="font-bold mr-8">-</span>
              <span>There aren't any apples in the basket.</span>
            </p>
            <p className="mb-10">
              <span className="font-bold mr-8">?</span>
              <span>Are there any apples in the basket?</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">-</span>
              <span>There isn't any water in the glass.</span>
            </p>
            <p className="pb-2">
              <span className="font-bold mr-8">?</span>
              <span>Is there any water in the glass?</span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              "There is" and "it is" are different. We use "there is" to say
              that something exists or to talk about the location of something,
              but we use "it is" to describe or identify something specific.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">There is a phone on the desk. It is black.</p>
            <p className="pb-1">There is a dog in the garden. It is barking.</p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            There Is / Are,
            <br className="sm:hidden" /> Some / Any
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            از ساختار های there is و there are استفاده می کنیم تا بگوییم کسی یا
            چیزی وجود دارد.
          </p>

          <p lang="fa" dir="rtl" className="mb-4">
            وقتی راجع به یک نفر یا یک چیز صحبت می کنیم از there is استفاده می
            کنیم. معمولا هم از شکل خلاصه شده آن یعنی there's استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">There is // There's a book on the table.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک کتاب روی میز است.
            </p>
            <p className="pb-1">There is // There's someone at the door.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک نفر / کسی دم در است.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-4">
            وقتی راجع به دو شخص یا دو چیز، یا تعداد بیشتری از افراد یا چیز ها
            صحبت می کنیم، از ساختار there are استفاده می کنیم. این ساختار معمولا
            خلاصه نمی شود.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">There are three books on the shelf.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              سه کتاب روی طاقچه / قفسه است.
            </p>
            <p className="pb-1">There are some people in the yard.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              چند نفر توی حیاط هستند.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              وقتی درباره لیستی از افراد یا چیز ها صحبت می کنیم، اگر اولین شخص
              یا چیز در آن لیست مفرد باشد از there is استفاده می کنیم. اما اگر
              اولین چیز یا اولین فرد در لیست جمع باشد از there are استفاده می
              کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              There <span className="font-bold">is</span>{" "}
              <span className="underline">a book</span>, two pens, and a
              notebook on the desk.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک کتاب، دو خودکار و یک دفترچه روی میز است.
            </p>
            <p className="pb-1">
              There <span className="font-bold">are</span>{" "}
              <span className="underline">two pens</span>, a book, and a
              notebook on the desk.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              دو خودکار، یک کتاب و یک دفترچه روی میز است.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-2">
            ساختار "there is / are" معمولا با "some" ،"a / an" یا "any" استفاده
            می شود.
          </p>

          <div lang="fa" dir="rtl" className="flex mb-1">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              قبل از اسامی قابل شمارش مفرد در جملات <mark>مثبت</mark>،{" "}
              <mark>منفی</mark> و <mark>سوالی</mark> از a / an استفاده می کنیم.
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              <span className="font-bold mr-8">+</span>
              <span>There's a cat in the yard.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک گربه تو حیاط است.
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">-</span>
              <span>There isn't a cat in the yard.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              گربه ای تو حیاط نیست.
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">?</span>
              <span>Is there a cat in the yard?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آیا یک گربه تو حیاط است؟
            </p>
          </div>

          <div lang="fa" dir="rtl" className="flex mb-1">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              قبل از اسامی قابل شمارش جمع و اسامی غیر قابل شمارش در جملات{" "}
              <mark>مثبت</mark> از some استفاده می کنیم.
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              <span className="font-bold mr-8">+</span>
              <span>There are some apples in the basket.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              چند تا سیب تو سبد هست.
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">+</span>
              <span>There is some water in the glass.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک مقدار آب تو لیوان هست.
            </p>
          </div>

          <div lang="fa" dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              قبل از اسامی قابل شمارش جمع و اسم های غیر قابل شمارش در جملات{" "}
              <mark>منفی</mark> و <mark>سوالی</mark> از any استفاده می کنیم.
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">
              <span className="font-bold mr-8">-</span>
              <span>There aren't any apples in the basket.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              هیچ سیبی تو سبد نیست.
            </p>
            <p className="mb-1">
              <span className="font-bold mr-8">?</span>
              <span>Are there any apples in the basket?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-10 not-italic">
              آیا تو سبد سیب هست؟
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">-</span>
              <span>There isn't any water in the glass.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              تو لیوان آب نیست.
            </p>
            <p className="pb-1">
              <span className="font-bold mr-8">?</span>
              <span>Is there any water in the glass?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آیا تو لیوان آب هست؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              حواسمان باشد there is و it is متفاوتند. از ساختار there is استفاده
              می کنیم تا بگوییم کسی یا چیزی وجود دارد، یا مکان کسی یا چیزی
              کجاست. اما از it is استفاده می کنیم تا چیزی را توصیف کنیم و بگوییم
              چه است یا ماهیتش چگونه است.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">There is a phone on the desk. It is black.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک تلفن روی میز است. آن تلفن سیاه است.
            </p>
            <p className="pb-1">There is a dog in the garden. It is barking.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک سگ در باغ است. آن سگ دارد پارس می کند.
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
