import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";

export const PartsOfSpeech = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      What part of speech is the <span className="underline">underlined</span>{" "}
      word?
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          We <span className="underline">went</span> to the beach in the
          morning.
        </span>
      ),
      answer: "Verb",
    },
    {
      id: 2,
      question: (
        <span>
          His father drives very <span className="underline">slowly</span>.
        </span>
      ),
      answer: "Adverb",
    },
    {
      id: 3,
      question: (
        <span>
          They never speak with <span className="underline">us</span>.
        </span>
      ),
      answer: "Pronoun",
    },
    {
      id: 4,
      question: (
        <span>
          We usually buy bread at the local{" "}
          <span className="underline">bakery</span>.
        </span>
      ),
      answer: "Noun",
    },
    {
      id: 5,
      question: (
        <span>
          The weather was <span className="underline">fantastic</span>.
        </span>
      ),
      answer: "Adjective",
    },
    {
      id: 6,
      question: (
        <span>
          She posted a photo <span className="underline">of</span> her daughter
          on Instagram.
        </span>
      ),
      answer: "Preposition",
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Parts of Speech</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Parts of Speech
          </h1>
          <p className="mb-9">
            "Parts of speech" refer to the different types of words in the
            English language, based on their function and role within a
            sentence.
          </p>
          <p className="mb-9">Here are the main parts of speech in English:</p>

          <h3 className="mb-2 font-bold">Nouns</h3>
          <p className="mb-9">
            Nouns are words that usually represent people, places, things, or
            ideas.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">book, teacher, happiness, school, cat ...</p>
          </div>

          <h3 className="mb-2 font-bold">Pronouns</h3>
          <p className="mb-9">
            Pronouns take the place of nouns in a sentence to prevent
            repetition.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">I, she, you, it, they ...</p>
          </div>

          <h3 className="mb-2 font-bold">Verbs</h3>
          <p className="mb-9">Verbs express actions or states of being.</p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">live, go, eat, prefer, be ...</p>
          </div>

          <h3 className="mb-2 font-bold">Adjectives</h3>
          <p className="mb-9">
            Adjectives modify or describe nouns or pronouns.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">good, cold, happy, beautiful, expensive ...</p>
          </div>

          <h3 className="mb-2 font-bold">Adverbs</h3>
          <p className="mb-9">
            Adverbs modify verbs, adjectives, or other adverbs, providing
            additional information about time, place, manner, or degree.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">extremely, carefully, always, very, here ...</p>
          </div>

          <h3 className="mb-2 font-bold">Prepositions</h3>
          <p className="mb-9">
            Prepositions link nouns, pronouns, or phrases to other parts of a
            sentence, typically expressing relations in terms of space or time.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">in, under, after, with, from ...</p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Parts of Speech
          </h1>

          <p lang="fa" dir="rtl" className="mb-9">
            در دستور زبان می توان کلمات را بر اساس کارکردشان و نقشی که در جمله
            بازی می کنند به چند گروه کلی تقسیم کرد:
          </p>

          <h3 lang="fa" dir="rtl" className="mb-2 font-bold">
            اسم ها (Nouns)
          </h3>
          <p lang="fa" dir="rtl" className="mb-9">
            اسم کلمه ایست که برای نامیدن یک انسان، حیوان، چیز، مکان، مفهوم و ...
            به کار می رود.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">book, teacher, happiness, school, cat ...</p>
            <p lang="fa" dir="rtl" className="flex flex-col">
              کتاب، معلم، شادی، مدرسه، گربه ...
            </p>
          </div>

          <h3 lang="fa" dir="rtl" className="mb-2 font-bold">
            ضمیر ها (Pronouns)
          </h3>
          <p lang="fa" dir="rtl" className="mb-9">
            ضمیر کلمه ایست که می تواند جهت اجتناب از تکرار، به جای یک اسم
            بنشیند.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">I, she, you, it, they ...</p>
            <p lang="fa" dir="rtl" className="flex flex-col">
              من، او، تو، آن، آنها ...
            </p>
          </div>

          <h3 lang="fa" dir="rtl" className="mb-2 font-bold">
            فعل ها (Verbs)
          </h3>
          <p lang="fa" dir="rtl" className="mb-9">
            فعل کلمه ایست که بر وقوع کاری یا حالتی در زمانی معین دلالت می کند.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">live, go, eat, prefer, be ...</p>
            <p lang="fa" dir="rtl" className="flex flex-col">
              زندگی کردن، رفتن، خوردن، ترجیح دادن، بودن ...
            </p>
          </div>

          <h3 lang="fa" dir="rtl" className="mb-2 font-bold">
            صفت ها (Verbs)
          </h3>
          <p lang="fa" dir="rtl" className="mb-9">
            صفت کلمه ایست که یک اسم یا یک ضمیر را توصیف می کند.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              good, cold, happy, beautiful, expensive ...
            </p>
            <p lang="fa" dir="rtl" className="flex flex-col">
              خوب، سرد، شاد، زیبا، گران ...
            </p>
          </div>

          <h3 lang="fa" dir="rtl" className="mb-2 font-bold">
            قید ها (Adverbs)
          </h3>
          <p lang="fa" dir="rtl" className="mb-9">
            قید کلمه ایست که یک فعل، یک صفت، یا یک قید دیگر را توصیف می کند. قید
            ها همچنین می توانند اطلاعاتی درباره زمان و مکان وقوع افعال به ما
            بدهند.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              extremely, carefully, always, very, here ...
            </p>
            <p lang="fa" dir="rtl" className="flex flex-col">
              بی نهایت، با دقت، همیشه، خیلی، اینجا ...
            </p>
          </div>

          <h3 lang="fa" dir="rtl" className="mb-2 font-bold">
            حروف اضافه (Prepositions)
          </h3>
          <p lang="fa" dir="rtl" className="mb-9">
            حرف اضافه کلمه ایست که نسبت میان دو کلمه را (از لحاظ مکان، زمان و
            ...) بیان می کند.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">in, under, after, with, from ...</p>
            <p lang="fa" dir="rtl" className="flex flex-col">
              تو، زیر، بعد از، با، از ...
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
