import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import ArrowLongRight from "../../../assets/images/arrowLongLight.svg";

export const QuestionsElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = <p className="mb-2">Complete the questions.</p>;
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span className="mr-10">"What kind of music ____?"</span>
          <span>"She likes jazz and blues."</span>
        </span>
      ),
      answer: (
        <span>
          <span className="mr-10">
            "What kind of music <span className="font-bold">does she like</span>
            ?"
          </span>
          <span>"She likes jazz and blues."</span>
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          <span className="mr-10">"____ in the countryside?"</span>
          <span>"Yes, they do. There's a river next to their house."</span>
        </span>
      ),
      answer: (
        <span>
          <span className="mr-10">
            "<span className="font-bold">Do they live</span> in the
            countryside?"
          </span>
          <span>"Yes, they do. There's a river next to their house."</span>
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          <span className="mr-10">"____ your name, please?"</span>
          <span>"Yes, I can. M - i - c - h - e - l - l - e."</span>
        </span>
      ),
      answer: (
        <span>
          <span className="mr-10">
            "<span className="font-bold">Can you spell</span> your name,
            please?"
          </span>
          <span>"Yes, I can. M - i - c - h - e - l - l - e."</span>
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          <span className="mr-10">"Where ____?"</span>
          <span>"He works in the local supermarket."</span>
        </span>
      ),
      answer: (
        <span>
          <span className="mr-10">
            "Where <span className="font-bold">does he work</span>?"
          </span>
          <span>"He works in the local supermarket."</span>
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>
          <span className="mr-10">"Where ____?"</span>
          <span>"We're from Thailand."</span>
        </span>
      ),
      answer: (
        <span>
          <span className="mr-10">
            "Where <span className="font-bold">are you from</span>?"
          </span>
          <span>"We're from Thailand."</span>
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>
          <span className="mr-10">"How many brothers and sisters ____?"</span>
          <span>"Three. She has one brother and two sisters."</span>
        </span>
      ),
      answer: (
        <span>
          <span className="mr-10">
            "How many brothers and sisters{" "}
            <span className="font-bold">does she have</span>?"
          </span>
          <span>"Three. She has one brother and two sisters."</span>
        </span>
      ),
    },
  ];

  useTitle(!farsi ? title : "جملات سوالی");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Questions</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Questions
          </h1>
          <p className="mb-9">
            In order to make yes/no questions in English, we simply put the{" "}
            <mark>auxiliary verb</mark> in a sentence before the{" "}
            <span className="underline">subject</span>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">She goes to the gym every day.</p>
            <p className="flex pb-4">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>Does</mark> <span className="underline">she</span> go to
                the gym every day?
              </span>
            </p>
            <p className="pb-1">He's sleeping.</p>
            <p className="flex pb-4">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>Is</mark> <span className="underline">he</span> sleeping?
              </span>
            </p>
            <p className="pb-1">They came back home late last night.</p>
            <p className="flex pb-2">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>Did</mark> <span className="underline">they</span> come
                back home late last night?
              </span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As for negative questions, if the <mark>auxiliary verb</mark> is
              contracted with "<span className="font-bold">not</span>", we put
              the entire contraction before the{" "}
              <span className="underline">subject</span>. If the{" "}
              <mark>auxiliary verb</mark> is not contracted with "
              <span className="font-bold">not</span>," we put only the{" "}
              <mark>auxiliary verb</mark> before the{" "}
              <span className="underline">subject</span>, and "
              <span className="font-bold">not</span>" stays before the main
              verb. Both forms are correct and have the same meaning.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">She cannot speak English well.</p>
            <p className="flex pb-4">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>Can</mark> <span className="underline">she</span>{" "}
                <span className="font-bold">not</span> speak English well?
              </span>
            </p>
            <p className="pb-4">or</p>
            <p className="pb-1">She can't speak English well.</p>
            <p className="flex pb-4">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>
                  Can<span className="font-bold">'t</span>
                </mark>{" "}
                <span className="underline">she</span> speak English well?
              </span>
            </p>
          </div>

          <p className="mb-9">
            In order to make WH questions in English, we simply put the{" "}
            <mark>auxiliary verb</mark> in a sentence before the{" "}
            <span className="underline">subject</span>, and put the{" "}
            <span className="font-bold">WH Question word</span> (what, where,
            when ...) at the beginning of the question before the{" "}
            <mark>auxiliary verb</mark>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">
              I woke up <span className="font-bold">at 7 o'clock</span>.
            </p>
            <p className="flex pb-4">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">When</span> <mark>did</mark>{" "}
                <span className="underline">you</span> wake up?
              </span>
            </p>
            <p className="pb-1">
              They usually have lunch{" "}
              <span className="font-bold">at the office</span>.
            </p>
            <p className="flex pb-4">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">Where</span> <mark>do</mark>{" "}
                <span className="underline">they</span> usually have lunch?
              </span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Questions <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              جملات سوالی
            </span>
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            برای ساختن جملات سوالی که پاسخ شان بله یا خیر است، کافی است{" "}
            <mark>فعل کمکی</mark> جمله را قبل از{" "}
            <span className="underline">فاعل</span> جمله بگذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">She goes to the gym every day.</p>
            <p className="flex pb-1">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>Does</mark> <span className="underline">she</span> go to
                the gym every day?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-4 not-italic">
              آیا او هر روز می رود باشگاه؟
            </p>
            <p className="pb-1">He's sleeping.</p>
            <p className="flex pb-1">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>Is</mark> <span className="underline">he</span> sleeping?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-4 not-italic">
              آیا او خوابیده است؟
            </p>
            <p className="pb-1">They came back home late last night.</p>
            <p className="flex pb-1">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>Did</mark> <span className="underline">they</span> come
                back home late last night?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              آیا دیشب آنها دیر برگشتند خانه؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              برای ساختن جملات سوالی منفی، اگر <mark>فعل کمکی</mark> با کلمه{" "}
              <span className="font-bold">not</span> خلاصه شده باشد، شکل خلاصه
              شده <mark>فعل کمکی</mark> و کلمه{" "}
              <span className="font-bold">not</span> را در ابتدای جمله، قبل از{" "}
              <span className="underline">فاعل</span> می گذاریم. اما اگر{" "}
              <mark>فعل کمکی</mark> با کلمه{" "}
              <span className="font-bold">not</span> خلاصه نشده باشد، فقط{" "}
              <mark>فعل کمکی</mark> را به ابتدای جمله، قبل از{" "}
              <span className="underline">فاعل</span> انتقال می دهیم و کلمه{" "}
              <span className="font-bold">not </span>
              سر جایش، قبل از فعل اصلی باقی می ماند. هر دو شکل درست و هم معنی
              هستند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">She cannot speak English well.</p>
            <p className="flex pb-4">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>Can</mark> <span className="underline">she</span>{" "}
                <span className="font-bold">not</span> speak English well?
              </span>
            </p>
            <p className="pb-4">or</p>
            <p className="pb-1">She can't speak English well.</p>
            <p className="flex pb-2">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <mark>
                  Can<span className="font-bold">'t</span>
                </mark>{" "}
                <span className="underline">she</span> speak English well?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic pb-2">
              آیا او نمی تواند انگلیسی خوب صحبت کند؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            هنگامی که می خواهیم با کلمات سوالی مانند{" "}
            <span className="font-bold">what ،when ،how</span> و ... سوال
            بسازیم، ابتدا مانند قبل <mark>فعل کمکی</mark> را به ابتدای جمله، قبل
            از <span className="underline">فاعل</span> منتقل می کنیم و سپس کلمه
            سوالی را قبل از <mark>فعل کمکی</mark> در ابتدای جمله می گذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-1">
              I woke up <span className="font-bold">at 7 o'clock</span>.
            </p>
            <p lang="fa" dir="rtl" className="not-italic pb-1">
              ساعت هفت از خواب بیدار شدم.
            </p>
            <p className="flex pb-4">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">When</span> <mark>did</mark>{" "}
                <span className="underline">you</span> wake up?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic pb-5">
              کِی / چه ساعتی از خواب بیدار شدی؟
            </p>
            <p className="pb-1">
              They usually have lunch{" "}
              <span className="font-bold">at the office</span>.
            </p>
            <p lang="fa" dir="rtl" className="not-italic pb-1">
              آنها معمولا ناهارشان را در اداره می خورند.
            </p>
            <p className="flex pb-1">
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">Where</span> <mark>do</mark>{" "}
                <span className="underline">they</span> usually have lunch?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic pb-2">
              معمولا کجا ناهارشان را می خورند؟
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
