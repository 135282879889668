import { useNavigate } from "react-router-dom";
import { useTitle } from "../components";
import PageNotFoundImage from "../assets/images/pageNotFoundImage.svg";

export const PageNotFound = ({ title }) => {
  const navigate = useNavigate();

  useTitle(title);

  return (
    <div
      className="flex flex-col-reverse items-center my-12 md:mt-0 md:flex-row md:justify-evenly"
      data-aos="zoom-in"
      data-aos-duration="800"
    >
      <div className="w-4/5 md:w-5/12">
        <img src={PageNotFoundImage} alt="Page Not Found" />
      </div>
      <div className="text-center  mb-5 md:mb-0 md:pt-12">
        <h2 className="text-pink-700 font-bold text-3xl sm:text-4xl lg:text-5xl xl:text-6xl">
          Page Not Found!
        </h2>
        <button
          onClick={() => navigate("/")}
          className="bg-fuchsia-950 duration-100 hover:bg-fuchsia-900 text-white tracking-wider rounded-3xl pt-1 pb-2 px-4 mt-5 md:mt-6 md:text-lg lg:mt-8 lg:text-xl xl:mt-10 "
        >
          Back to Homepage
        </button>
      </div>
    </div>
  );
};
