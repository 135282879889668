import { useState } from "react";
import { Link } from "react-router-dom";
import { SearchInput, useTitle } from "../../components";
import { PreIntermediateGrammarList } from "../../data/GrammarData";

export const PreIntermediateGrammar = ({ title }) => {
  const [searchResults, setSearchResults] = useState();

  useTitle(title);

  return (
    <div>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <Link to="/grammar" className="duration-300 hover:text-indigo-950">
          <span>Grammar</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">A2 - B1</span>
      </nav>

      {/* ---------- Body ---------- */}

      <div className="py-10" data-aos="zoom-in" data-aos-duration="800">
        <h1 className="flex flex-col drop-shadow text-center">
          <span className="text-2xl md:text-3xl font-bold text-pink-700">
            A2 - B1
          </span>
          <span className="text-3xl md:text-4xl font text-indigo-950 md:pb-5">
            Pre-Intermediate
          </span>
        </h1>
        <SearchInput
          List={PreIntermediateGrammarList}
          setSearchResults={setSearchResults}
        />

        <div className="w-4/5 md:w-11/12 grid grid-cols-1 md:grid-cols-2 1050:grid-cols-3 mt-10 md:mt-16 mx-auto justify-around md:pl-12">
          {!searchResults ? (
            PreIntermediateGrammarList.map((point) => (
              <Link
                to={point.url}
                key={point.id}
                className="flex items-center text-xl font-medium dropshadow text-fuchsia-950 duration-300 hover:text-pink-700 hover:scale-110 md:hover:translate-x-2 max-md:pb-2 text-center justify-center md:text-start md:justify-start"
              >
                <span className="-mr-3 -mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="#BE185D"
                    className="bi bi-dot max-md:hidden"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </span>
                <span className="pt-1">{point.topic}</span>
              </Link>
            ))
          ) : searchResults.length === 0 ? (
            <p className="text-2xl text-fuchsia-950 drop-shadow">
              No results were found!
            </p>
          ) : (
            searchResults.map((point) => (
              <Link
                to={point.url}
                key={point.id}
                className="flex items-center text-xl font-medium dropshadow text-fuchsia-950 duration-300 hover:text-pink-700 hover:scale-110 md:hover:translate-x-2 max-md:pb-2 text-center justify-center md:text-start md:justify-start"
              >
                <span className="-mr-3 -mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="#BE185D"
                    className="bi bi-dot max-md:hidden"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </span>
                <span className="pt-1">{point.topic}</span>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
