import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const PossessiveSElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <p className="mb-2">
      Choose the correct form printed in <span className="italic">italics</span>
      .
    </p>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          It's my <span className="italic ml-3">birthday's brother</span> /{" "}
          <span className="italic">brother's birthday</span>.
        </span>
      ),
      answer: (
        <span>
          It's my <span className="font-bold">brother's birthday</span>.
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          That's <span className="italic ml-3">Jack's camera</span> /{" "}
          <span className="italic">camera's Jack</span>.
        </span>
      ),
      answer: (
        <span>
          That's <span className="font-bold">Jack's camera</span>.
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          My <span className="italic ml-3">wife's car</span> /{" "}
          <span className="italic mr-3">car's wife</span> is on the street.
        </span>
      ),
      answer: (
        <span>
          My <span className="font-bold">wife's car</span> is on the street.
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          <span className="italic">Whose</span> /{" "}
          <span className="italic mr-3">Who's</span> laptop is this?
        </span>
      ),
      answer: (
        <span>
          <span className="font-bold">Whose</span> laptop is this?
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>
          His
          <span className="italic ml-3">parents's</span> /{" "}
          <span className="italic mr-3">parents'</span> house is very big.
        </span>
      ),
      answer: (
        <span>
          His <span className="font-bold">parents'</span> house is very big.
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>
          I like watching a TV show at
          <span className="italic ml-3">the day's end</span> /{" "}
          <span className="italic">the end of the day</span>.
        </span>
      ),
      answer: (
        <span>
          I like watching a TV show at{" "}
          <span className="font-bold">the end of the day</span>.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Possessive 's</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Possessive 's
          </h1>
          <p className="mb-9">
            We usually use <span className="font-bold">'s</span> to say that
            something belongs to someone.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="mb-1">
              <span className="mr-8">My brother's car</span>
              <span className="not-italic">
                (The car belongs to my brother.)
              </span>
            </p>
            <p className="mb-1">
              <span className="mr-8">Sally's bicycle</span>
              <span className="not-italic">
                (The bicycle belongs to Sally.)
              </span>
            </p>
          </div>

          <p className="mb-9">
            With a plural noun that ends in a plural{" "}
            <span className="font-bold">s</span>, we only put an apostrophe (
            <span className="font-bold">'</span>) after the plural{" "}
            <span className="font-bold">s </span>
            to show that something belongs to those people.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="mb-1">
              <span className="mr-8">the teacher's bag</span>
              <span className="not-italic">
                (We're talking about one teacher.)
              </span>
            </p>
            <p className="mb-1">
              <span className="mr-8">
                the teacher<mark className="font-bold">s'</mark> room
              </span>
              <span className="not-italic">
                (We're talking about a group of teachers.)
              </span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              With an irregular plural noun, similar to singular nouns, we
              simply put an <span className="font-bold">'s</span> at the end of
              the irregular noun.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="mb-1">our children's books</p>
            <p className="mb-1">women's clothes</p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As we can see from the examples above, we usually use{" "}
              <span className="font-bold">'s</span> when we're talking about
              people. When we're talking about things, we usually use the
              preposition "<span className="font-bold">of</span>".
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="mb-1">
              the end <span className="font-bold">of</span> the book
            </p>
            <p className="mb-1">
              the color <span className="font-bold">of</span> the wall
            </p>
          </div>

          <p className="mb-9">
            We use the question word "<span className="font-bold">whose</span>"
            to ask about possessions
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="flex flex-col mb-4">
              <span>"Whose bicycle is that?" // "Whose is that bicycle?"</span>
              <span>"It's Sally's bicycle."</span>
            </p>
            <p className="flex flex-col mb-1">
              <span>"Whose car is this?" // "Whose is this car?"</span>
              <span>"It's my brother's car."</span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Possessive 's
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            معمولا برای اینکه نشان دهیم کسی مالک چیزی است، بعد از آن شخص در زبان
            انگلیسی <span className="font-bold">s'</span> می گذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">
              <span className="mr-8">My brother's car</span>
              <span className="not-italic">
                (The car belongs to my brother.)
              </span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic mb-4">
              ماشینِ برادرم
            </p>
            <p className="mb-1">
              <span className="mr-8">Sally's bicycle</span>
              <span className="not-italic">
                (The bicycle belongs to Sally.)
              </span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic">
              دوچرخهِ سالی
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            برای نشان دادن مالکیت اسم هایی که به{" "}
            <span className="font-bold">s</span> جمع ختم می شوند، کافی است
            بلافاصله بعد از <span className="font-bold">s</span> جمع یک آپاستروف
            (<span className="font-bold">'</span>) بگذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">
              <span className="mr-8">the teacher's bag</span>
              <span className="not-italic">
                (We're talking about one teacher.)
              </span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic mb-4">
              کیفِ یک معلم
            </p>
            <p className="mb-1">
              <span className="mr-8">
                the teacher<mark className="font-bold">s'</mark> room
              </span>
              <span className="not-italic">
                (We're talking about a group of teachers.)
              </span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic">
              اتاقِ معلم ها
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              برای نشان دادن مالکیت اسم های جمع بی قاعده، مانند اسم های مفرد،
              بعد از آنها <span className="font-bold">s'</span> می گذاریم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">our children's books</p>
            <p lang="fa" dir="rtl" className="not-italic mb-4">
              کتاب های فرزندانمان
            </p>
            <p className="mb-1">men's clothes</p>
            <p lang="fa" dir="rtl" className="not-italic mb-1">
              لباس های آقایان
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              همانطور که در مثال های بالا می بینیم، معمولا{" "}
              <span className="font-bold">s'</span> را برای نسبت دادن مالکیت به
              انسان ها استفاده می کنیم. در مورد چیزها، مفاهیم انتزاعی و ...،
              معمولا از کلمه <span className="font-bold">of</span> برای ساختن یک
              ترکیب اضافی استفاده می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">
              the end <span className="font-bold">of</span> the book
            </p>
            <p lang="fa" dir="rtl" className="mb-4 not-italic">
              پایانِ کتاب
            </p>
            <p className="mb-1">
              the color <span className="font-bold">of</span> the wall
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              رنگِ دیوار
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            وقتی می خواهیم سوال بپرسیم که چه چیزی مالِ چه کسی است، از کلمه سوالی
            <span className="font-bold"> whose</span> استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="flex flex-col mb-8">
              <span>"Whose bicycle is that?" // "Whose is that bicycle?"</span>
              <span lang="fa" dir="rtl" className="not-italic">
                آن دوچرخه مال چه کسیه؟
              </span>
              <span>"It's Sally's bicycle."</span>
            </p>
            <p className="flex flex-col mb-1">
              <span>"Whose car is this?" // "Whose is this car?"</span>
              <span lang="fa" dir="rtl" className="not-italic">
                این ماشین کیه؟
              </span>
              <span>"It's my brother's car."</span>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
