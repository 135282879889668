import { useState } from "react";
import { Questions } from "./Questions";

export const ExerciseCard = ({ questions, instruction }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="border border-fuchsia-800 text-indigo-950 rounded-lg mx-5 mt-5 my-11 px-4 sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12">
      <div
        onClick={() => setExpanded(!expanded)}
        className="flex flex-row justify-between my-5 cursor-pointer"
      >
        <h3 className="text-2xl font-bold text-pink-700 drop-shadow pb-1">
          Exercises
        </h3>
        <span
          className={`transition-transform duration-500 text-pink-700 drop-shadow self-center ${
            expanded ? "rotate-90" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </span>
      </div>
      <div
        className={`text-xl transition-all duration-700 ${
          expanded ? "max-h-[380vh]" : "overflow-hidden max-h-0 opacity-0"
        }`}
      >
        <p className="font-semibold mb-5">{instruction}</p>
        <Questions questions={questions} />
      </div>
    </div>
  );
};
