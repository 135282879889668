import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const ObjectPronouns = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = "Complete the sentences with an object pronoun.";
  const questions = [
    {
      id: 1,
      question: "Nicole likes John, but he doesn't like ____.",
      answer: (
        <span className="font-normal">
          Nicole likes John, but he doesn't like{" "}
          <span className="font-bold">her</span>.
        </span>
      ),
    },
    {
      id: 2,
      question:
        "My children love watching films. I usually take ____ to the movies every week.",
      answer: (
        <span className="font-normal">
          My children love watching films. I usually take{" "}
          <span className="font-bold">them</span> to the movies every week.
        </span>
      ),
    },
    {
      id: 3,
      question: "Robert De Niro is a great actor. I really like ____.",
      answer: (
        <span className="font-normal">
          Robert De Niro is a great actor. I really like{" "}
          <span className="font-bold">him</span>.
        </span>
      ),
    },
    {
      id: 4,
      question: "I can't find my wallet. I think I left ____ in the taxi.",
      answer: (
        <span className="font-normal">
          I can't find my wallet. I think I left{" "}
          <span className="font-bold">it</span> in the taxi.
        </span>
      ),
    },
    {
      id: 5,
      question:
        "I feel very thirsty. Can you give ____ a glass of water, please?",
      answer: (
        <span className="font-normal">
          I feel very thirsty. Can you give{" "}
          <span className="font-bold">me</span> a glass of water, please?
        </span>
      ),
    },
    {
      id: 6,
      question:
        "Rachel is a very strange girl. I can't sometimes understand ____.",
      answer: (
        <span className="font-normal">
          Rachel is a very strange girl. I can't sometimes understand{" "}
          <span className="font-bold">her</span>.
        </span>
      ),
    },
    {
      id: 7,
      question: "Excuse me. My friend and I are lost. Can you help ____?",
      answer: (
        <span className="font-normal">
          Excuse me. My friend and I are lost. Can you help{" "}
          <span className="font-bold">us</span>?
        </span>
      ),
    },
  ];

  useTitle(!farsi ? title : "ضمایر مفعولی");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Object Pronouns</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Object Pronouns
          </h1>
          <p className="mb-9">
            The object in a sentence is usually something, someone, or an idea
            that is affected by the action of the verb. The object comes after
            the verb or the verb phrase in English sentences. The object is{" "}
            <span className="underline decoration-indigo-950">underlined</span>{" "}
            in the following example sentences.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              John ate{" "}
              <span className="underline decoration-indigo-950">the apple</span>
              .
            </p>
            <p className="pb-2">
              She helps{" "}
              <span className="underline decoration-indigo-950">her mom</span>{" "}
              every day.
            </p>
            <p className="pb-1">
              The student's success shows{" "}
              <span className="underline decoration-indigo-950">
                the value of hard work
              </span>
              .
            </p>
          </div>
          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Remember that not all sentences have an object.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">We walked in the park for an hour.</p>
          </div>

          <p className="mb-9">
            The object in a sentence is either a{" "}
            <span className="underline decoration-indigo-950">noun</span>,
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">
              John met{" "}
              <span className="underline decoration-indigo-950">Nicole</span> in
              the park yesterday.
            </p>
          </div>

          <p className="mb-9">
            a{" "}
            <span className="underline decoration-indigo-950">noun phrase</span>
            ,
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">
              The student's success shows{" "}
              <span className="underline decoration-indigo-950">
                the value of hard work
              </span>
              .
            </p>
          </div>

          <p className="mb-9">
            or a{" "}
            <span className="underline decoration-indigo-950">pronoun</span>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">
              She put{" "}
              <span className="underline decoration-indigo-950">it</span> on the
              table.
            </p>
          </div>

          <p className="mb-9">
            These are the <span className="font-bold">object pronouns</span> in
            English:
          </p>

          <div className="grid grid-cols-2 border border-indigo-950 w-64 mx-auto text-center mb-9">
            <div className="col-span-2 uppercase py-1 text-pink-700 font-bold border-b border-indigo-950">
              Object Pronouns
            </div>
            <div className="py-1 font-semibold border-b border-r border-indigo-950">
              Singular
            </div>
            <div className="py-1 font-semibold border-b border-indigo-950">
              Plural
            </div>
            <div className="py-1 border-b border-r border-indigo-950">me</div>
            <div className="py-1 border-b border-indigo-950">us</div>
            <div className="py-1 border-b border-r border-indigo-950">you</div>
            <div className="py-1 border-b border-indigo-950">you</div>
            <div className="py-1 border-r border-indigo-950">
              him
              <br />
              her
              <br />
              it
              <br />
            </div>
            <div className="py-1 flex items-center justify-center">them</div>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Object Pronouns <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              ضمایر مفعولی
            </span>
          </h1>

          <p lang="fa" dir="rtl" className="mb-9">
            معمولا به کسی، چیزی یا مفهومی که طرف انجام فعل است مفعول می گوییم.
            به عبارت دیگر فعل جمله نسبت به مفعول انجام می شود یا روی مفعول تاثیر
            می گذارد. در جملات انگلیسی مفعول بعد از فعل یا عبارت فعلی می آید.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              John ate{" "}
              <span className="underline decoration-indigo-950">the apple</span>
              .
            </p>
            <p lang="fa" dir="rtl" className="pb-3">
              جان <span className="underline decoration-indigo-950">سیب</span>{" "}
              را خورد.
            </p>
            <p className="italic">
              She helps{" "}
              <span className="underline decoration-indigo-950">her mom</span>{" "}
              every day.
            </p>
            <p lang="fa" dir="rtl" className="pb-3">
              او هر روز به{" "}
              <span className="underline decoration-indigo-950">مادرش</span> کمک
              می کند.
            </p>
            <p className="italic">
              The student's success shows{" "}
              <span className="underline decoration-indigo-950">
                the value of hard work
              </span>
              .
            </p>
            <p lang="fa" dir="rtl">
              موفقیت آن دانش آموز{" "}
              <span className="underline decoration-indigo-950">
                ارزش سخت کوشی
              </span>{" "}
              را نشان می دهد.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pr-3">
              در زبان فارسی علامت مفعول در جمله <mark>معمولا</mark> کلمه «را»
              است. برای پیدا کردن مفعول می توانیم سوال «چه کسی را ...؟» یا «چه
              چیزی را ...؟» را بپرسیم. اگر این سوال جوابی داشته باشد، آن پاسخ
              مفعول جمله است.
            </span>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              John ate{" "}
              <span className="underline decoration-indigo-950">the apple</span>
              .
            </p>
            <p lang="fa" dir="rtl" className="flex flex-col">
              <span>سوال: جان چه چیزی را خورد؟</span>
              <span>جواب: سیب را. </span>
            </p>
          </div>
          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pr-3">
              نکته دیگر این که همه جمله ها لزوما مفعول ندارند. مثلا در جمله زیر
              مفعول نداریم.
            </span>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">We walked in the park for an hour.</p>
            <p lang="fa" dir="rtl">
              یک ساعت تو پارک قدم زدیم.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="pb-9">
            مفعول معمولا یا یک{" "}
            <span className="underline decoration-indigo-950">اسم</span> است،
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              John met{" "}
              <span className="underline decoration-indigo-950">Nicole</span> in
              the park yesterday.
            </p>
            <p lang="fa" dir="rtl">
              جان دیروز{" "}
              <span className="underline decoration-indigo-950">نیکول</span> را
              در پارک دید.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="pb-9">
            یا یک{" "}
            <span className="underline decoration-indigo-950">عبارت اسمی</span>،
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              The student's success shows{" "}
              <span className="underline decoration-indigo-950">
                the value of hard work
              </span>
              .
            </p>
            <p lang="fa" dir="rtl">
              موفقیت آن دانش آموز{" "}
              <span className="underline decoration-indigo-950">
                ارزش سخت کوشی
              </span>{" "}
              را نشان می دهد.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="pb-9">
            و یا یک{" "}
            <span className="underline decoration-indigo-950">ضمیر مفعولی</span>
            .
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              His sister always helps{" "}
              <span className="underline decoration-indigo-950">him</span>.
            </p>
            <p lang="fa" dir="rtl">
              خواهرش همیشه به{" "}
              <span className="underline decoration-indigo-950">او</span> کمک می
              کند.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="pb-9">
            در جدول زیر <span className="font-bold">ضمایر مفعولی</span> در زبان
            انگلیسی را مشاهده می کنید.
          </p>
          <div className="grid grid-cols-2 border border-indigo-950 w-64 mx-auto text-center mb-9">
            <div className="col-span-2 uppercase py-1 text-pink-700 font-bold border-b border-indigo-950">
              Object Pronouns
              <br />
              <span lang="fa" dir="rtl">
                ضمایر مفعولی
              </span>
            </div>
            <div className="py-1 font-semibold border-b border-r border-indigo-950">
              Singular
              <br />
              <span lang="fa" dir="rtl">
                مفرد
              </span>
            </div>
            <div className="py-1 font-semibold border-b border-indigo-950">
              Plural
              <br />
              <span lang="fa" dir="rtl">
                جمع
              </span>
            </div>
            <div className="py-1 border-b border-r border-indigo-950">
              me
              <span lang="fa" dir="rtl" className="pl-3">
                من
              </span>
            </div>
            <div className="py-1 border-b border-indigo-950">
              us
              <span lang="fa" dir="rtl" className="pl-3">
                ما
              </span>
            </div>
            <div className="py-1 border-b border-r border-indigo-950">
              you
              <span lang="fa" dir="rtl" className="pl-3">
                تو
              </span>
            </div>
            <div className="py-1 border-b border-indigo-950">
              you
              <span lang="fa" dir="rtl" className="pl-3">
                شما
              </span>
            </div>
            <div className="py-1 border-r border-indigo-950">
              him
              <span lang="fa" dir="rtl" className="pl-3">
                او (مرد)
              </span>
              <br />
              her{" "}
              <span lang="fa" dir="rtl" className="pl-3">
                او (زن)
              </span>
              <br />
              it
              <span lang="fa" dir="rtl" className="pl-3">
                این/آن
              </span>
            </div>
            <div className="py-1 flex flex-col items-center justify-center">
              <span>them</span>

              <span lang="fa" dir="rtl">
                آنها/ایشان
              </span>
            </div>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
