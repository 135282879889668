export const Pagination = ({
  totalCount,
  pageSize,
  currentPage,
  handleChangePage,
}) => {
  const pages = Math.ceil(totalCount / pageSize);
  const pagesArray = Array.from({ length: pages }, (_, i) => i + 1);

  return (
    <>
      {/* My Pagination */}
      <div className="flex w-fit my-14 border border-pink-200 mx-auto rounded-lg text-gray-500 shadow-md text-center">
        <span
          onClick={() =>
            handleChangePage(currentPage > 1 ? currentPage - 1 : 1)
          }
          className="p-2 border-r border-pink-200 hover:bg-pink-50 hover:text-indigo-950 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
            />
          </svg>
        </span>
        {pagesArray.map((p) => (
          <span
            key={p}
            onClick={() => handleChangePage(p)}
            className={`py-2 px-4 border-r border-pink-200 hover:bg-pink-50 hover:text-indigo-950 cursor-pointer ${
              p === currentPage ? "bg-pink-100" : ""
            }`}
          >
            {p}
          </span>
        ))}
        <span
          onClick={() =>
            handleChangePage(
              currentPage < pagesArray.length
                ? currentPage + 1
                : pagesArray.length
            )
          }
          className="p-2 cursor-pointer hover:bg-pink-50 hover:text-indigo-950 rounded-r-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
      </div>
    </>
  );
};
