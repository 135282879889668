import { Routes, Route } from "react-router-dom";

import {
  EssayTopics,
  OpinionEssayTopics,
  ProblemSolutionEssayTopics,
  DoubleQuestionEssayTopics,
  DiscussionEssayTopics,
  AdvantagesDisadvantagesEssayTopics,
  IeltsTopicalVocabulary,
  ComputerInternetVocabulary,
  FameCelebrityVocabulary,
  WorkVocabulary,
  AdvertisingVocabulary,
  EnvironmentVocabulary,
  PageNotFound,
} from "../pages";

export const IeltsRoutes = () => {
  return (
    <Routes>
      <Route
        path="writingTask2Topics"
        element={<EssayTopics title="IELTS Writing Task 2 Topics" />}
      />
      <Route
        path="writingTask2Topics/opinionEssayTopics"
        element={<OpinionEssayTopics title="Opinion Essay Topics" />}
      />
      <Route
        path="writingTask2Topics/problemSolutionEssayTopics"
        element={
          <ProblemSolutionEssayTopics title="Problem Solution Essay Topics" />
        }
      />
      <Route
        path="writingTask2Topics/doubleQuestionEssayTopics"
        element={
          <DoubleQuestionEssayTopics title="Double Question Essay Topics" />
        }
      />
      <Route
        path="writingTask2Topics/discussionEssayTopics"
        element={<DiscussionEssayTopics title="Discussion Essay Topics" />}
      />
      <Route
        path="writingTask2Topics/advantagesAndDisadvantagesEssayTopics"
        element={
          <AdvantagesDisadvantagesEssayTopics title="Advantages & Disadvantages Essay Topics" />
        }
      />
      <Route
        path="topicalVocabulary"
        element={<IeltsTopicalVocabulary title="IELTS Topical Vocabulary" />}
      />
      <Route
        path="topicalVocabulary/computerAndInternet"
        element={
          <ComputerInternetVocabulary title="IELTS Vocabulary | Computer & Internet" />
        }
      />
      <Route
        path="topicalVocabulary/fameAndCelebrity"
        element={
          <FameCelebrityVocabulary title="IELTS Vocabulary | Fame & Celebrity" />
        }
      />
      <Route
        path="topicalVocabulary/work"
        element={<WorkVocabulary title="IELTS Vocabulary | Work" />}
      />
      <Route
        path="topicalVocabulary/advertising"
        element={
          <AdvertisingVocabulary title="IELTS Vocabulary | Advertising" />
        }
      />
      <Route
        path="topicalVocabulary/environment"
        element={
          <EnvironmentVocabulary title="IELTS Vocabulary | Environment" />
        }
      />

      <Route path="*" element={<PageNotFound title="Page Not Found" />} />
    </Routes>
  );
};
